.links {
  display: inline-flex;
  flex-direction: column;
  position: relative;
}

.links .link {
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.links .line-2 {
  align-self: stretch;
  position: relative;
  width: 100%;
}

.links.state-1-pressed {
  opacity: 0.6;
}

.links.state-1-hover {
  opacity: 0.6;
}

.links.desktop-m {
  gap: 2px;
}

.links.mobile-l {
  gap: 1px;
}

.links.desktop-l {
  gap: 2px;
}

.links.mobile-s {
  align-items: flex-start;
  gap: 1px;
}

.links.semi {
  align-items: flex-start;
}

.links.mobile-m {
  gap: 1px;
}

.links.desktop-m.bold {
  align-items: flex-start;
}

.links.desktop-m.med {
  align-items: flex-end;
}

.links.desktop-m.reg {
  align-items: flex-start;
}

.links .color-1-dark-green {
  color: var(--swampy);
}

.links .color-1-gray {
  color: var(--gray);
}

.links .color-1-white {
  color: var(--white);
}

.links .color-1-blue {
  color: var(--blue);
}

.links .size-mobile-l {
  font-family: var(--XS-body-BL-semibold-font-family);
  font-size: var(--XS-body-BL-semibold-font-size);
  font-style: var(--XS-body-BL-semibold-font-style);
  font-weight: var(--XS-body-BL-semibold-font-weight);
  letter-spacing: var(--XS-body-BL-semibold-letter-spacing);
  line-height: var(--XS-body-BL-semibold-line-height);
}

.links .size-desktop-l {
  font-family: var(--XL-body-BS-semibold-font-family);
  font-size: var(--XL-body-BS-semibold-font-size);
  font-style: var(--XL-body-BS-semibold-font-style);
  font-weight: var(--XL-body-BS-semibold-font-weight);
  letter-spacing: var(--XL-body-BS-semibold-letter-spacing);
  line-height: var(--XL-body-BS-semibold-line-height);
}

.links .color-1-black {
  color: var(--black);
}

.links .color-1-violet {
  color: var(--violet);
}

.links .color-1-dark-gray {
  color: var(--dark-gray);
}

.links .color-1-red {
  color: var(--red);
}

.links .size-mobile-m {
  font-family: var(--XS-body-BM-semibold-font-family);
  font-size: var(--XS-body-BM-semibold-font-size);
  font-style: var(--XS-body-BM-semibold-font-style);
  font-weight: var(--XS-body-BM-semibold-font-weight);
  letter-spacing: var(--XS-body-BM-semibold-letter-spacing);
  line-height: var(--XS-body-BM-semibold-line-height);
}

.links .size-mobile-s.weight-reg {
  font-family: var(--XS-small-text-ST-l-reg-font-family);
  font-size: var(--XS-small-text-ST-l-reg-font-size);
  font-style: var(--XS-small-text-ST-l-reg-font-style);
  font-weight: var(--XS-small-text-ST-l-reg-font-weight);
  letter-spacing: var(--XS-small-text-ST-l-reg-letter-spacing);
  line-height: var(--XS-small-text-ST-l-reg-line-height);
}

.links .size-desktop-m.weight-med {
  font-family: var(--XL-small-text-ST-l-med-font-family);
  font-size: var(--XL-small-text-ST-l-med-font-size);
  font-style: var(--XL-small-text-ST-l-med-font-style);
  font-weight: var(--XL-small-text-ST-l-med-font-weight);
  letter-spacing: var(--XL-small-text-ST-l-med-letter-spacing);
  line-height: var(--XL-small-text-ST-l-med-line-height);
}

.links .weight-med.size-mobile-s {
  font-family: var(--XS-small-text-ST-l-medium-font-family);
  font-size: var(--XS-small-text-ST-l-medium-font-size);
  font-style: var(--XS-small-text-ST-l-medium-font-style);
  font-weight: var(--XS-small-text-ST-l-medium-font-weight);
  letter-spacing: var(--XS-small-text-ST-l-medium-letter-spacing);
  line-height: var(--XS-small-text-ST-l-medium-line-height);
}

.links .size-desktop-m.weight-reg {
  font-family: var(--XL-small-text-ST-l-reg-font-family);
  font-size: var(--XL-small-text-ST-l-reg-font-size);
  font-style: var(--XL-small-text-ST-l-reg-font-style);
  font-weight: var(--XL-small-text-ST-l-reg-font-weight);
  letter-spacing: var(--XL-small-text-ST-l-reg-letter-spacing);
  line-height: var(--XL-small-text-ST-l-reg-line-height);
}

.links .size-desktop-m.weight-bold {
  font-family: var(--XL-small-text-ST-l-bold-font-family);
  font-size: var(--XL-small-text-ST-l-bold-font-size);
  font-style: var(--XL-small-text-ST-l-bold-font-style);
  font-weight: var(--XL-small-text-ST-l-bold-font-weight);
  letter-spacing: var(--XL-small-text-ST-l-bold-letter-spacing);
  line-height: var(--XL-small-text-ST-l-bold-line-height);
}

.links .size-mobile-s.weight-bold {
  font-family: var(--XS-small-text-ST-l-bold-font-family);
  font-size: var(--XS-small-text-ST-l-bold-font-size);
  font-style: var(--XS-small-text-ST-l-bold-font-style);
  font-weight: var(--XS-small-text-ST-l-bold-font-weight);
  letter-spacing: var(--XS-small-text-ST-l-bold-letter-spacing);
  line-height: var(--XS-small-text-ST-l-bold-line-height);
}

.links.mobile-l .line-2 {
  height: 1px;
  object-fit: cover;
}

.links.med .line-2 {
  height: 1px;
  object-fit: cover;
}

.links.reg .line-2 {
  height: 1px;
  object-fit: cover;
}

.links.mobile-m .line-2 {
  height: 1px;
  object-fit: cover;
}

.links.desktop-l.bold .line-2 {
  height: 2px;
}

.links.desktop-l.semi .line-2 {
  height: 2px;
}

.links.desktop-m.bold .line-2 {
  height: 2px;
}

.links.mobile-s.bold .line-2 {
  height: 1px;
  object-fit: cover;
}
