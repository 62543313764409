.button {
  all: unset;
  box-sizing: border-box;
  position: relative;
}

.button .div-4 {
  position: relative;
  text-align: center;
}

.button .text-wrapper-3 {
  all: unset;
  box-sizing: border-box;
  font-family: var(--button-m-font-family);
  font-size: var(--button-m-font-size);
  font-style: var(--button-m-font-style);
  font-weight: var(--button-m-font-weight);
  letter-spacing: var(--button-m-letter-spacing);
  line-height: var(--button-m-line-height);
  margin-top: -1.5px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.button.device-max {
  display: flex;
}

.button.l {
  align-items: center;
  border-radius: 8px;
  justify-content: center;
  overflow: hidden;
  padding: 20px 0px;
  width: 404px;
}

.button.s {
  align-items: center;
  gap: 10px;
  justify-content: center;
  overflow: hidden;
}

.button.red-contour {
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.button.XL {
  align-items: center;
  border-radius: 12px;
  gap: 10px;
  justify-content: center;
  overflow: hidden;
  padding: 32px 0px;
  width: 495px;
}

.button.violet-contour {
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.button.device-desktop {
  display: flex;
}

.button.s.device-mini {
  padding: var(--spacings-spacing-md);
}

.button.l.blue {
  height: 70px;
}

.button.hover.red {
  background-color: var(--red-dark);
}

.button.rose.device-mobile {
  display: inline-flex;
}

.button.default.red-contour {
  border-color: var(--red);
}

.button.l.white {
  height: 70px;
}

.button.hover.red-contour {
  background-color: var(--light-gray);
  border-color: var(--red-dark);
}

.button.red-contour.device-mobile {
  height: 60px;
}

.button.blue.m {
  display: flex;
  width: 328px;
}

.button.default.blue {
  align-items: center;
  justify-content: center;
}

.button.rose.l {
  height: 70px;
}

.button.default.violet-contour {
  background-color: var(--white);
  border-color: var(--violet);
}

.button.hover.white {
  background-color: var(--light-gray);
}

.button.l.red-contour {
  border: 3px solid;
}

.button.rose.default {
  background-color: var(--rose);
}

.button.l.violet-contour {
  border: 3px solid;
}

.button.s.device-mobile {
  display: inline-flex;
  padding: var(--spacings-spacing-md);
}

.button.rose.device-mini {
  display: inline-flex;
}

.button.pressed.rose {
  background-color: var(--rose-dark);
}

.button.blue.s {
  border-radius: 8px;
}

.button.m.violet-contour {
  border: 2px solid;
  border-radius: 8px;
  display: flex;
  gap: 10px;
  padding: 20px 0px;
  width: 328px;
}

.button.violet-contour.device-mobile {
  height: 60px;
}

.button.red.l {
  height: 70px;
}

.button.s.green {
  border-radius: 8px;
}

.button.m.green {
  align-items: flex-start;
}

.button.l.device-mini {
  height: 70px;
}

.button.device-desktop.l {
  height: 70px;
}

.button.pressed.violet-contour {
  background-color: var(--light-gray);
  border-color: var(--violet-dark);
}

.button.inactive.rose {
  background-color: var(--rose-light);
}

.button.m.red-contour {
  border: 2px solid;
  border-radius: 8px;
  display: flex;
  gap: 10px;
  padding: 20px 0px;
  width: 328px;
}

.button.XL.violet-contour {
  border: 3px solid;
}

.button.s.red-contour {
  border-radius: 8px;
}

.button.red.s {
  border-radius: 8px;
}

.button.inactive.red-contour {
  border-color: var(--rose-light);
}

.button.red.m {
  align-items: flex-start;
}

.button.s.white {
  border-radius: 8px;
}

.button.device-desktop.s {
  height: 44px;
  padding: 20px 0px;
  width: 185px;
}

.button.default.violet {
  align-items: center;
  justify-content: center;
}

.button.XL.red-contour {
  border: 3px solid;
}

.button.violet.m {
  display: flex;
  width: 328px;
  background-color: var(--violet);
}

.button.hover.violet-contour {
  background-color: var(--light-gray);
  border-color: var(--violet-dark);
}

.button.hover.green {
  background-color: var(--swampy);
}

.button.default.white {
  align-items: center;
  justify-content: center;
}

.button.l.violet {
  height: 70px;
}

.button.inactive.violet-contour {
  background-color: var(--white);
  border-color: var(--lavender);
}

.button.l.green {
  height: 70px;
}

.button.hover.violet {
  background-color: var(--violet-dark);
}

.button.pressed.red-contour {
  background-color: var(--light-gray);
  border-color: var(--red-dark);
}

.button.hover.blue {
  background-color: var(--blue-dark);
}

.button.s.violet {
  border-radius: 8px;
}

.button.l.device-max {
  height: 70px;
}

.button.m.white {
  display: flex;
  width: 328px;
}

.button.XL.red.default {
  background-color: var(--red);
}

.button.XL.default.red-contour {
  background-color: var(--white);
}

.button.rose.device-mini.m {
  border-radius: 6px;
  padding: var(--spacings-spacing-md);
}

.button.inactive.s.violet {
  background-color: var(--lavender);
}

.button.inactive.l.white {
  background-color: var(--gray);
}

.button.inactive.blue.m {
  align-items: flex-start;
}

.button.XL.inactive.red-contour {
  background-color: var(--white);
}

.button.default.s.violet {
  background-color: var(--violet);
}

.button.inactive.m.white {
  align-items: flex-start;
}

.button.inactive.l.blue {
  background-color: var(--blue-light);
}

.button.l.default.violet {
  background-color: var(--violet);
}

.button.XL.default.white {
  background-color: var(--white);
}

.button.default.s.red-contour {
  border: 2px solid;
}

.button.pressed.s.violet {
  background-color: var(--violet-dark);
}

.button.pressed.red.s {
  background-color: var(--red-dark);
}

.button.pressed.violet.m {
  align-items: flex-start;
}

.button.inactive.s.green {
  background-color: var(--lime);
}

.button.XL.default.green {
  background-color: var(--green);
}

.button.inactive.s.red-contour {
  border: 1px solid;
}

.button.red.l.inactive {
  background-color: var(--gray);
}

.button.inactive.m.red-contour {
  background-color: var(--white);
}

.button.red.s.inactive {
  background-color: var(--gray);
}

.button.inactive.s.white {
  background-color: var(--gray);
}

.button.red.device-mini.m {
  border-radius: 6px;
  display: inline-flex;
  padding: var(--spacings-spacing-md);
}

.button.pressed.blue.m {
  align-items: flex-start;
}

.button.red.default.s {
  background-color: var(--red);
}

.button.device-mini.m.green {
  border-radius: 6px;
  display: inline-flex;
  padding: var(--spacings-spacing-md);
}

.button.green.m.device-mobile {
  display: inline-flex;
}

.button.inactive.l.green {
  background-color: var(--lime);
}

.button.default.blue.s {
  background-color: var(--blue);
}

.button.inactive.blue.s {
  background-color: var(--blue-light);
}

.button.l.default.green {
  background-color: var(--green);
}

.button.l.default.red-contour {
  background-color: var(--white);
}

.button.rose.s.device-mini {
  border-radius: 6px;
}

.button.XL.default.blue {
  background-color: var(--blue);
}

.button.red.l.default {
  background-color: var(--red);
}

.button.XL.inactive.white {
  background-color: var(--gray);
}

.button.blue.device-mini.m {
  border-radius: 6px;
  padding: var(--spacings-spacing-md);
}

.button.device-mini.m.white {
  border-radius: 6px;
  padding: var(--spacings-spacing-md);
}

.button.XL.inactive.blue {
  background-color: var(--blue-light);
}

.button.pressed.s.green {
  background-color: var(--swampy);
}

.button.hover.s.red-contour {
  border: 2px solid;
}

.button.default.s.green {
  background-color: var(--green);
}

.button.default.m.red-contour {
  background-color: var(--white);
}

.button.pressed.blue.s {
  background-color: var(--blue-dark);
}

.button.XL.inactive.green {
  background-color: var(--lime);
}

.button.default.s.white {
  background-color: var(--white);
}

.button.l.default.blue {
  background-color: var(--blue);
}

.button.inactive.l.violet {
  background-color: var(--lavender);
}

.button.inactive.violet.m {
  align-items: flex-start;
}

.button.s.device-mini.violet-contour {
  border-radius: 6px;
}

.button.inactive.l.red-contour {
  background-color: var(--white);
}

.button.XL.inactive.violet {
  background-color: var(--lavender);
}

.button.red.m.device-mobile {
  display: inline-flex;
}

.button.XL.red.inactive {
  background-color: var(--gray);
}

.button.m.device-mini.violet {
  border-radius: 6px;
  padding: var(--spacings-spacing-md);
}

.button.pressed.m.white {
  align-items: flex-start;
}

.button.pressed.s.white {
  background-color: var(--light-gray);
}

.button.l.default.white {
  background-color: var(--white);
}

.button.XL.default.violet {
  background-color: var(--violet);
}

.button.l .div-4 {
  font-family: var(--button-l-font-family);
  font-size: var(--button-l-font-size);
  font-style: var(--button-l-font-style);
  font-weight: var(--button-l-font-weight);
  letter-spacing: var(--button-l-letter-spacing);
  line-height: var(--button-l-line-height);
  white-space: nowrap;
  width: fit-content;
}

.button.s .div-4 {
  white-space: nowrap;
  width: fit-content;
}

.button.red-contour .div-4 {
  white-space: nowrap;
  width: fit-content;
}

.button.XL .div-4 {
  font-family: var(--button-XL-font-family);
  font-size: var(--button-XL-font-size);
  font-style: var(--button-XL-font-style);
  font-weight: var(--button-XL-font-weight);
  letter-spacing: var(--button-XL-letter-spacing);
  line-height: var(--button-XL-line-height);
  white-space: nowrap;
  width: fit-content;
}

.button.violet-contour .div-4 {
  white-space: nowrap;
  width: fit-content;
}

.button.s.device-mini .div-4 {
  font-family: var(--button-s-font-family);
  font-size: var(--button-s-font-size);
  font-style: var(--button-s-font-style);
  font-weight: var(--button-s-font-weight);
  letter-spacing: var(--button-s-letter-spacing);
  line-height: var(--button-s-line-height);
}

.button.hover.red .div-4 {
  color: var(--white);
}

.button.hover.s .div-4 {
  margin-bottom: -5.5px;
}

.button.XL.violet .div-4 {
  margin-top: -3px;
}

.button.default.red-contour .div-4 {
  color: var(--red);
}

.button.hover.red-contour .div-4 {
  color: var(--red-dark);
}

.button.red-contour.device-mobile .div-4 {
  margin-top: -1.5px;
}

.button.blue.m .div-4 {
  align-items: center;
  border-radius: 8px;
  display: flex;
  gap: 10px;
  height: 60px;
  justify-content: center;
  overflow: hidden;
  padding: 20px 0px;
  width: 328px;
}

.button.default.violet-contour .div-4 {
  color: var(--violet);
}

.button.XL.blue .div-4 {
  margin-top: -3px;
}

.button.hover.white .div-4 {
  color: var(--black);
}

.button.rose.default .div-4 {
  color: var(--black);
}

.button.s.device-mobile .div-4 {
  font-family: var(--button-s-font-family);
  font-size: var(--button-s-font-size);
  font-style: var(--button-s-font-style);
  font-weight: var(--button-s-font-weight);
  letter-spacing: var(--button-s-letter-spacing);
  line-height: var(--button-s-line-height);
}

.button.XL.rose .div-4 {
  margin-top: -3px;
}

.button.pressed.rose .div-4 {
  color: var(--black);
}

.button.m.violet-contour .div-4 {
  font-family: var(--button-m-font-family);
  font-size: var(--button-m-font-size);
  font-style: var(--button-m-font-style);
  font-weight: var(--button-m-font-weight);
  letter-spacing: var(--button-m-letter-spacing);
  line-height: var(--button-m-line-height);
}

.button.violet-contour.device-mobile .div-4 {
  margin-top: -1.5px;
}

.button.XL.device-mini .div-4 {
  margin-top: -3px;
}

.button.XL.red .div-4 {
  margin-top: -3px;
}

.button.device-mini.m .div-4 {
  margin-top: -2px;
}

.button.m.green .div-4 {
  align-items: center;
  border-radius: 8px;
  display: flex;
  gap: 10px;
  height: 60px;
  justify-content: center;
  overflow: hidden;
  padding: 20px 52px;
  width: 328px;
}

.button.l.device-mini .div-4 {
  margin-top: -2px;
}

.button.pressed.violet-contour .div-4 {
  color: var(--violet-dark);
}

.button.inactive.rose .div-4 {
  color: var(--gray);
}

.button.m.red-contour .div-4 {
  font-family: var(--button-m-font-family);
  font-size: var(--button-m-font-size);
  font-style: var(--button-m-font-style);
  font-weight: var(--button-m-font-weight);
  letter-spacing: var(--button-m-letter-spacing);
  line-height: var(--button-m-line-height);
}

.button.inactive.red-contour .div-4 {
  color: var(--rose-light);
}

.button.red.m .div-4 {
  align-items: center;
  border-radius: 8px;
  display: flex;
  gap: 10px;
  height: 60px;
  justify-content: center;
  overflow: hidden;
  padding: 20px 52px;
  width: 328px;
}

.button.device-desktop.s .div-4 {
  font-family: var(--button-m-font-family);
  font-size: var(--button-m-font-size);
  font-style: var(--button-m-font-style);
  font-weight: var(--button-m-font-weight);
  letter-spacing: var(--button-m-letter-spacing);
  line-height: var(--button-m-line-height);
  text-align: center;
}

.button.violet.m .div-4 {
  align-items: center;
  border-radius: 8px;
  display: flex;
  gap: 10px;
  height: 60px;
  justify-content: center;
  overflow: hidden;
  width: 328px !important;
}

.button.hover.violet-contour .div-4 {
  color: var(--violet-dark);
}

.button.XL.white .div-4 {
  margin-top: -3px;
}

.button.hover.green .div-4 {
  color: var(--black);
}

.button.XL.device-desktop .div-4 {
  margin-top: -3px;
}

.button.XL.green .div-4 {
  margin-top: -3px;
}

.button.inactive.violet-contour .div-4 {
  color: var(--lavender);
}

.button.hover.violet .div-4 {
  color: var(--white);
}

.button.pressed.red-contour .div-4 {
  color: var(--red-dark);
}

.button.XL.device-max .div-4 {
  margin-top: -3px;
}

.button.hover.blue .div-4 {
  color: var(--white);
}

.button.m.white .div-4 {
  align-items: center;
  border-radius: 8px;
  display: flex;
  gap: 10px;
  height: 60px;
  justify-content: center;
  overflow: hidden;
  padding: 20px 0px;
  width: 328px;
}

.button.XL.red.default .div-4 {
  color: var(--white);
}

.button.red.m.inactive .div-4 {
  background-color: var(--gray);
}

.button.rose.device-mini.m .div-4 {
  font-family: var(--button-s-font-family);
  font-size: var(--button-s-font-size);
  font-style: var(--button-s-font-style);
  font-weight: var(--button-s-font-weight);
  letter-spacing: var(--button-s-letter-spacing);
  line-height: var(--button-s-line-height);
}

.button.inactive.s.violet .div-4 {
  color: var(--light-gray);
}

.button.pressed.m.green .div-4 {
  background-color: var(--swampy);
}

.button.inactive.l.white .div-4 {
  color: var(--dark-gray);
}

.button.inactive.blue.m .div-4 {
  background-color: var(--blue-light);
}

.button.default.s.violet .div-4 {
  color: var(--white);
}

.button.inactive.m.white .div-4 {
  background-color: var(--gray);
}

.button.inactive.l.blue .div-4 {
  color: var(--light-gray);
}

.button.l.default.violet .div-4 {
  color: var(--white);
}

.button.XL.default.white .div-4 {
  color: var(--black);
}

.button.pressed.s.violet .div-4 {
  color: var(--white);
}

.button.pressed.red.s .div-4 {
  color: var(--white);
}

.button.default.m.white .div-4 {
  background-color: var(--white);
}

.button.pressed.violet.m .div-4 {
  background-color: var(--violet-dark);
}

.button.inactive.s.green .div-4 {
  color: var(--gray);
}

.button.XL.default.green .div-4 {
  color: var(--black);
}

.button.inactive.s.red-contour .div-4 {
  margin-bottom: -6.5px;
}

.button.red.l.inactive .div-4 {
  color: var(--light-gray);
}

.button.red.s.inactive .div-4 {
  color: var(--light-gray);
}

.button.inactive.s.white .div-4 {
  color: var(--dark-gray);
}

.button.red.device-mini.m .div-4 {
  font-family: var(--button-s-font-family);
  font-size: var(--button-s-font-size);
  font-style: var(--button-s-font-style);
  font-weight: var(--button-s-font-weight);
  letter-spacing: var(--button-s-letter-spacing);
  line-height: var(--button-s-line-height);
}

.button.pressed.blue.m .div-4 {
  background-color: var(--blue-dark);
}

.button.red.default.s .div-4 {
  color: var(--white);
}

.button.hover.s.green .div-4 {
  margin-top: -9.5px;
}

.button.device-mini.m.green .div-4 {
  font-family: var(--button-s-font-family);
  font-size: var(--button-s-font-size);
  font-style: var(--button-s-font-style);
  font-weight: var(--button-s-font-weight);
  letter-spacing: var(--button-s-letter-spacing);
  line-height: var(--button-s-line-height);
}

.button.default.violet.m .div-4 {
  background-color: var(--violet);
}

.button.inactive.l.green .div-4 {
  color: var(--gray);
}

.button.device-desktop.default.s .div-4 {
  margin-bottom: -5.5px;
  margin-top: -9.5px;
}

.button.default.blue.s .div-4 {
  color: var(--white);
}

.button.hover.device-desktop.s .div-4 {
  margin-top: -9.5px;
}

.button.hover.red.s .div-4 {
  margin-top: -9.5px;
}

.button.inactive.blue.s .div-4 {
  color: var(--light-gray);
}

.button.default.s.device-mini .div-4 {
  margin-top: -2px;
}

.button.l.default.green .div-4 {
  color: var(--black);
}

.button.red.default.m .div-4 {
  background-color: var(--red);
}

.button.pressed.s.device-mini .div-4 {
  margin-top: -2px;
}

.button.default.blue.m .div-4 {
  background-color: var(--blue);
}

.button.XL.default.blue .div-4 {
  color: var(--white);
}

.button.red.l.default .div-4 {
  color: var(--white);
}

.button.pressed.red.m .div-4 {
  background-color: var(--red-dark);
}

.button.hover.blue.s .div-4 {
  margin-top: -9.5px;
}

.button.XL.inactive.white .div-4 {
  color: var(--dark-gray);
}

.button.blue.device-mini.m .div-4 {
  font-family: var(--button-s-font-family);
  font-size: var(--button-s-font-size);
  font-style: var(--button-s-font-style);
  font-weight: var(--button-s-font-weight);
  letter-spacing: var(--button-s-letter-spacing);
  line-height: var(--button-s-line-height);
}

.button.hover.device-max.s .div-4 {
  margin-top: -9.5px;
}

.button.hover.rose.s .div-4 {
  margin-top: -9.5px;
}

.button.device-mini.m.white .div-4 {
  font-family: var(--button-s-font-family);
  font-size: var(--button-s-font-size);
  font-style: var(--button-s-font-style);
  font-weight: var(--button-s-font-weight);
  letter-spacing: var(--button-s-letter-spacing);
  line-height: var(--button-s-line-height);
}

.button.XL.inactive.blue .div-4 {
  color: var(--light-gray);
}

.button.pressed.s.green .div-4 {
  color: var(--black);
}

.button.hover.s.white .div-4 {
  margin-top: -9.5px;
}

.button.default.s.green .div-4 {
  color: var(--black);
}

.button.hover.s.device-mini .div-4 {
  margin-top: -9.5px;
}

.button.pressed.blue.s .div-4 {
  color: var(--white);
}

.button.XL.inactive.green .div-4 {
  color: var(--gray);
}

.button.default.s.white .div-4 {
  color: var(--black);
}

.button.l.default.blue .div-4 {
  color: var(--white);
}

.button.inactive.l.violet .div-4 {
  color: var(--light-gray);
}

.button.inactive.violet.m .div-4 {
  background-color: var(--lavender);
}

.button.XL.inactive.violet .div-4 {
  color: var(--light-gray);
}

.button.default.m.green .div-4 {
  background-color: var(--green);
}

.button.XL.red.inactive .div-4 {
  color: var(--light-gray);
}

.button.m.device-mini.violet .div-4 {
  font-family: var(--button-s-font-family);
  font-size: var(--button-s-font-size);
  font-style: var(--button-s-font-style);
  font-weight: var(--button-s-font-weight);
  letter-spacing: var(--button-s-letter-spacing);
  line-height: var(--button-s-line-height);
}

.button.hover.s.violet .div-4 {
  margin-top: -9.5px;
}

.button.pressed.m.white .div-4 {
  background-color: var(--light-gray);
}

.button.pressed.s.white .div-4 {
  color: var(--black);
}

.button.l.default.white .div-4 {
  color: var(--black);
}

.button.XL.default.violet .div-4 {
  color: var(--white);
}

.button.inactive.m.green .div-4 {
  background-color: var(--lime);
}

.button.white.pressed.s.device-mobile .div-4 {
  margin-top: -2px;
}

.button.inactive.s.violet.device-mobile .div-4 {
  margin-top: -2px;
}

.button.red.s.device-mini.inactive .div-4 {
  margin-top: -2px;
}

.button.device-desktop.inactive.s.violet .div-4 {
  margin-bottom: -5.5px;
  margin-top: -9.5px;
}

.button.pressed.blue.s.device-mobile .div-4 {
  margin-top: -2px;
}

.button.device-desktop.red.s.inactive .div-4 {
  margin-bottom: -5.5px;
  margin-top: -9.5px;
}

.button.rose.default.s.device-mobile .div-4 {
  margin-top: -2px;
}

.button.red.default.s.device-mobile .div-4 {
  margin-top: -2px;
}

.button.white.default.s.device-mobile .div-4 {
  margin-top: -2px;
}

.button.pressed.s.violet.device-mobile .div-4 {
  margin-top: -2px;
}

.button.white.inactive.s.device-mobile .div-4 {
  margin-top: -2px;
}

.button.inactive.s.device-mini.violet-contour .div-4 {
  margin-top: -2px;
}

.button.default.s.violet.device-mobile .div-4 {
  margin-top: -2px;
}

.button.default.blue.s.device-mobile .div-4 {
  margin-top: -2px;
}

.button.device-desktop.inactive.s.white .div-4 {
  margin-bottom: -5.5px;
  margin-top: -9.5px;
}

.button.pressed.rose.s.device-mobile .div-4 {
  margin-top: -2px;
}

.button.inactive.s.device-mini.red-contour .div-4 {
  margin-top: -8.5px;
}

.button.inactive.rose.s.device-mini .div-4 {
  margin-top: -2px;
}

.button.inactive.s.device-mini.white .div-4 {
  margin-top: -2px;
}

.button.inactive.s.blue.device-mini .div-4 {
  margin-top: -2px;
}

.button.green.inactive.s.device-mobile .div-4 {
  margin-top: -2px;
}

.button.device-desktop.inactive.blue.s .div-4 {
  margin-bottom: -5.5px;
  margin-top: -9.5px;
}

.button.pressed.red.s.device-mobile .div-4 {
  margin-top: -2px;
}

.button.inactive.device-max.s.red-contour .div-4 {
  margin-top: -8.5px;
}

.button.inactive.rose.s.device-mobile .div-4 {
  margin-top: -2px;
}

.button.inactive.blue.s.device-mobile .div-4 {
  margin-top: -2px;
}

.button.green.default.s.device-mobile .div-4 {
  margin-top: -2px;
}

.button.device-desktop.inactive.s.red-contour .div-4 {
  margin-top: -8.5px;
}

.button.inactive.s.device-mini.green .div-4 {
  margin-top: -2px;
}

.button.inactive.s.device-mini.violet .div-4 {
  margin-top: -2px;
}

.button.red.s.inactive.device-mobile .div-4 {
  margin-top: -2px;
}

.button.pressed.green.s.device-mobile .div-4 {
  margin-top: -2px;
}

.button.pressed.green .text-wrapper-3 {
  color: var(--black);
}

.button.default.white .text-wrapper-3 {
  color: var(--black);
}

.button.pressed.white .text-wrapper-3 {
  color: var(--black);
}

.button.pressed.violet .text-wrapper-3 {
  color: var(--white);
}

.button.pressed.blue .text-wrapper-3 {
  color: var(--white);
}

.button.default.blue .text-wrapper-3 {
  color: var(--white);
}

.button.inactive.blue .text-wrapper-3 {
  color: var(--light-gray);
}

.button.inactive.violet .text-wrapper-3 {
  color: var(--light-gray);
}

.button.default.violet .text-wrapper-3 {
  color: var(--white);
}

.button.default.green .text-wrapper-3 {
  color: var(--black);
}

.button.red.inactive .text-wrapper-3 {
  color: var(--light-gray);
}

.button.red.default .text-wrapper-3 {
  color: var(--white);
}

.button.pressed.red .text-wrapper-3 {
  color: var(--white);
}

.button.inactive.white .text-wrapper-3 {
  color: var(--dark-gray);
}

.button.inactive.green .text-wrapper-3 {
  color: var(--gray);
}
