.status {
  display: inline-flex;
  position: relative;
}

.status .finansiado {
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.status.in-operation {
  background-color: var(--blue);
}

.status.size-13-desktop {
  align-items: center;
  border-radius: 4px;
  justify-content: center;
  padding: 8px 24px;
}

.status.under-renovation {
  background-color: var(--gray);
}

.status.coming-soon {
  background-color: var(--violet);
}

.status.reformed {
  background-color: var(--orange);
}

.status.size-13-mobile {
  align-items: flex-start;
  border-radius: 3px;
  gap: 10px;
  padding: var(--spacings-spacing-xs) var(--spacings-spacing-sm) var(--spacings-spacing-xs) var(--spacings-spacing-sm);
}

.status.rented {
  background-color: var(--red);
}

.status.finansed {
  background-color: var(--green);
}

.status.in-operation .finansiado {
  color: var(--white);
}

.status.size-13-desktop .finansiado {
  font-family: var(--XL-small-text-ST-l-bold-font-family);
  font-size: var(--XL-small-text-ST-l-bold-font-size);
  font-style: var(--XL-small-text-ST-l-bold-font-style);
  font-weight: var(--XL-small-text-ST-l-bold-font-weight);
  letter-spacing: var(--XL-small-text-ST-l-bold-letter-spacing);
  line-height: var(--XL-small-text-ST-l-bold-line-height);
  margin-top: -0.65px;
}

.status.under-renovation .finansiado {
  color: var(--black);
}

.status.coming-soon .finansiado {
  color: var(--white);
}

.status.reformed .finansiado {
  color: var(--white);
}

.status.size-13-mobile .finansiado {
  font-family: var(--XS-small-text-ST-l-bold-font-family);
  font-size: var(--XS-small-text-ST-l-bold-font-size);
  font-style: var(--XS-small-text-ST-l-bold-font-style);
  font-weight: var(--XS-small-text-ST-l-bold-font-weight);
  letter-spacing: var(--XS-small-text-ST-l-bold-letter-spacing);
  line-height: var(--XS-small-text-ST-l-bold-line-height);
  margin-top: -1px;
}

.status.rented .finansiado {
  color: var(--white);
}

.status.finansed .finansiado {
  color: var(--black);
}
