.header-desktop {
  display: flex;
  justify-content: center;
  flex-direction: column;
  max-width: 1920px;
  gap: 36px;
  background-color: var(--black);
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #5f5f604c;
  border-radius: 0px 0px 28px 28px;
  padding: var(--spacings-spacing-lg) 0 var(--spacings-spacing-lg) 0;
  position: relative;
}

.invisible {
  display: none !important;
}

.header-desktop .frame-6 {
  align-items: center;
  flex: 0 0 auto;
  position: relative;
}

.header-desktop .frame-7 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 36px;
  justify-content: center;
  position: relative;
}

.header-desktop .div-2 {
  color: var(--white);
  font-family: var(--XL-title-TXS-font-family);
  font-size: var(--XL-title-TXS-font-size);
  font-style: var(--XL-title-TXS-font-style);
  font-weight: var(--XL-title-TXS-font-weight);
  letter-spacing: var(--XL-title-TXS-letter-spacing);
  line-height: var(--XL-title-TXS-line-height);
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.header-desktop .frame-8 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: var(--spacings-spacing-none);
  position: relative;
}

.header-desktop .arrow-caret-down-MD {
  height: 32px !important;
  position: relative !important;
  width: 32px !important;
}

.header-desktop .div-3 {
  color: var(--white);
  font-family: var(--XL-title-TXS-font-family);
  font-size: var(--XL-title-TXS-font-size);
  font-style: var(--XL-title-TXS-font-style);
  font-weight: var(--XL-title-TXS-font-weight);
  letter-spacing: var(--XL-title-TXS-letter-spacing);
  line-height: var(--XL-title-TXS-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
  cursor: pointer;
  a {
    color: white;
    text-transform: uppercase;
  }
}

.header-desktop .frame-9 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  position: relative;
}

.header-desktop .class {
  width: 323px !important;
}

.header-desktop .button-instance {
  width: 181px !important;
}

.header-desktop .class-2 {
  background-color: var(--red) !important;
  width: 181px !important;
}

.header-desktop.type-marketplace {
  align-items: center;
}

.header-desktop.type-caribs {
  align-items: flex-start;
}

.header-desktop.type-timesharing-rent {
  align-items: center;
}

.header-desktop.type-lottery {
  align-items: center;
}

.header-desktop.type-marketplace .frame-6 {
  align-self: stretch;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.header-desktop.type-caribs .frame-6 {
  display: inline-flex;
  gap: var(--spacings-spacing-lg);
  /* justify-content: center; */
  min-width: 1440px;
  margin: 0 auto;
}

.header-desktop.type-timesharing-rent .frame-6 {
  align-self: stretch;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.header-desktop.type-lottery .frame-6 {
  align-self: stretch;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.header-desktop.type-marketplace .frame-9 {
  gap: 24px;
}

.header-desktop.type-caribs .frame-9 {
  gap: 24px;
}

.header-desktop.type-timesharing-rent .frame-9 {
  gap: 24px;
}

.header-desktop.type-lottery .frame-9 {
  gap: var(--spacings-spacing-md);
  justify-content: flex-end;
}
