.language-select {
  color: var(--white);
  background-color: var(--black);
  font-family: var(--XL-caps-CS-bold-font-family);
  font-size: var(--XL-caps-CS-bold-font-size);
  font-style: var(--XL-caps-CS-bold-font-style);
  font-weight: var(--XL-caps-CS-bold-font-weight);
  letter-spacing: var(--XL-caps-CS-bold-letter-spacing);
  line-height: var(--XL-caps-CS-bold-line-height);
  border: none;
  cursor: pointer;
}
