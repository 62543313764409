a {
  text-decoration: none;
  color: inherit;
}
.footer-landing-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--white);
  height: 693px;
  /* position: relative; */
  max-width: 1920px;
  width: 100%;
  border-top-right-radius: 28px;
  border-top-left-radius: 28px;
  padding: 100px 0 0 0;
}

.invisible {
  display: none !important;
}

.footer-landing-page .frame-21 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 16px;
  left: 735px;
  /* position: absolute; */
  top: 100px;
  width: 285px;
}

.footer-landing-page .div-8 {
  align-self: stretch;
  color: var(--black);
  font-family: var(--XL-headline-HS-font-family);
  font-size: var(--XL-headline-HS-font-size);
  font-style: var(--XL-headline-HS-font-style);
  font-weight: var(--XL-headline-HS-font-weight);
  letter-spacing: var(--XL-headline-HS-letter-spacing);
  line-height: var(--XL-headline-HS-line-height);
  margin-top: -1px;
  position: relative;
}

.footer-landing-page .frame-22 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 8px;
  position: relative;
  width: 100%;
}

.footer-landing-page .proyecto {
  align-self: stretch;
  color: var(--black);
  font-family: var(--XL-body-BM-semibold-font-family);
  font-size: var(--XL-body-BM-semibold-font-size);
  font-style: var(--XL-body-BM-semibold-font-style);
  font-weight: var(--XL-body-BM-semibold-font-weight);
  letter-spacing: var(--XL-body-BM-semibold-letter-spacing);
  line-height: var(--XL-body-BM-semibold-line-height);
  margin-top: -1px;
  position: relative;
}

.footer-landing-page .div-9 {
  align-self: stretch;
  color: var(--black);
  font-family: var(--XL-body-BM-semibold-font-family);
  font-size: var(--XL-body-BM-semibold-font-size);
  font-style: var(--XL-body-BM-semibold-font-style);
  font-weight: var(--XL-body-BM-semibold-font-weight);
  letter-spacing: var(--XL-body-BM-semibold-letter-spacing);
  line-height: var(--XL-body-BM-semibold-line-height);
  position: relative;
  cursor: pointer;
}

.footer-landing-page .frame-23 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 16px;
  left: 240px;
  /* position: absolute; */
  top: 100px;
  width: 381px;
}

.footer-landing-page .frame-24 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 12px;
  position: relative;
  width: 100%;
}

.footer-landing-page .div-10 {
  align-self: stretch;
  color: var(--black);
  font-family: var(--XL-title-TM-font-family);
  font-size: var(--XL-title-TM-font-size);
  font-style: var(--XL-title-TM-font-style);
  font-weight: var(--XL-title-TM-font-weight);
  letter-spacing: var(--XL-title-TM-letter-spacing);
  line-height: var(--XL-title-TM-line-height);
  margin-top: -1px;
  position: relative;
}

.footer-landing-page .div-11 {
  align-self: stretch;
  color: var(--black);
  font-family: var(--XL-body-BM-reg-font-family);
  font-size: var(--XL-body-BM-reg-font-size);
  font-style: var(--XL-body-BM-reg-font-style);
  font-weight: var(--XL-body-BM-reg-font-weight);
  letter-spacing: var(--XL-body-BM-reg-letter-spacing);
  line-height: var(--XL-body-BM-reg-line-height);
  position: relative;
}

.footer-landing-page .frame-25 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 8px;
  position: relative;
  width: 343px;
}

.footer-landing-page .frame-26 {
  margin-top: 100px;
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
  /* left: 240px; */
  /* position: absolute; */
  /* top: 620px; */
  width: 1440px;
}

.footer-landing-page .frame-27 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 88px;
  position: relative;
}

.footer-landing-page .div-12 {
  color: var(--black);
  font-family: var(--XL-small-text-ST-l-reg-font-family);
  font-size: var(--XL-small-text-ST-l-reg-font-size);
  font-style: var(--XL-small-text-ST-l-reg-font-style);
  font-weight: var(--XL-small-text-ST-l-reg-font-weight);
  letter-spacing: var(--XL-small-text-ST-l-reg-letter-spacing);
  line-height: var(--XL-small-text-ST-l-reg-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.footer-landing-page .frame-28 {
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
  position: relative;
  width: 539px;
}

.footer-landing-page .instance-node-5 {
  flex: 0 0 auto !important;
}

.footer-landing-page .frame-29 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: var(--spacings-spacing-xl);
  left: 1141px;
  /* position: absolute; */
  top: 100px;
  width: 539px;
}

.footer-landing-page .SI-TE-INTERESA {
  color: var(--black);
  font-family: var(--XL-title-TXS-font-family);
  font-size: var(--XL-title-TXS-font-size);
  font-style: var(--XL-title-TXS-font-style);
  font-weight: var(--XL-title-TXS-font-weight);
  letter-spacing: var(--XL-title-TXS-letter-spacing);
  line-height: var(--XL-title-TXS-line-height);
  margin-top: -1px;
  position: relative;
}

.footer-landing-page .frame-30 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 32px;
  position: relative;
  width: 539px;
}

.footer-landing-page .frame-31 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 20px;
  position: relative;
  width: 100%;
}

.footer-landing-page .frame-32 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 6px;
  position: relative;
  width: 100%;
}

.footer-landing-page .tel-fono {
  align-self: stretch;
  color: var(--gray);
  font-family: var(--XL-caps-CXS-med-font-family);
  font-size: var(--XL-caps-CXS-med-font-size);
  font-style: var(--XL-caps-CXS-med-font-style);
  font-weight: var(--XL-caps-CXS-med-font-weight);
  letter-spacing: var(--XL-caps-CXS-med-letter-spacing);
  line-height: var(--XL-caps-CXS-med-line-height);
  margin-top: -1px;
  position: relative;
}

.footer-landing-page .line-5 {
  align-self: stretch;
  height: 1px;
  object-fit: cover;
  position: relative;
  width: 100%;
}

.footer-landing-page .button-3 {
  align-self: stretch !important;
  flex: 0 0 auto !important;
  width: 100% !important;
}

.footer-landing-page .language-1-eng {
  width: 539px;
}

.footer-landing-page .language-1-esp {
  align-self: stretch;
}

.footer-main-container {
  width: 1440px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.text-wrapper-70 {
  width: 300px;
}
