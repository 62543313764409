@import "https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css";
@import "https://fonts.googleapis.com/css?family=Syncopate:700";
.button {
  all: unset;
  box-sizing: border-box;
  position: relative;
}

.button .div-4 {
  text-align: center;
  position: relative;
}

.button .text-wrapper-3 {
  all: unset;
  box-sizing: border-box;
  font-family: var(--button-m-font-family);
  font-size: var(--button-m-font-size);
  font-style: var(--button-m-font-style);
  font-weight: var(--button-m-font-weight);
  letter-spacing: var(--button-m-letter-spacing);
  line-height: var(--button-m-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1.5px;
  position: relative;
}

.button.device-max {
  display: flex;
}

.button.l {
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  width: 404px;
  padding: 20px 0;
  overflow: hidden;
}

.button.s {
  justify-content: center;
  align-items: center;
  gap: 10px;
  overflow: hidden;
}

.button.red-contour {
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.button.XL {
  border-radius: 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 495px;
  padding: 32px 0;
  overflow: hidden;
}

.button.violet-contour {
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.button.device-desktop {
  display: flex;
}

.button.s.device-mini {
  padding: var(--spacings-spacing-md);
}

.button.l.blue {
  height: 70px;
}

.button.hover.red {
  background-color: var(--red-dark);
}

.button.rose.device-mobile {
  display: inline-flex;
}

.button.default.red-contour {
  border-color: var(--red);
}

.button.l.white {
  height: 70px;
}

.button.hover.red-contour {
  background-color: var(--light-gray);
  border-color: var(--red-dark);
}

.button.red-contour.device-mobile {
  height: 60px;
}

.button.blue.m {
  width: 328px;
  display: flex;
}

.button.default.blue {
  justify-content: center;
  align-items: center;
}

.button.rose.l {
  height: 70px;
}

.button.default.violet-contour {
  background-color: var(--white);
  border-color: var(--violet);
}

.button.hover.white {
  background-color: var(--light-gray);
}

.button.l.red-contour {
  border: 3px solid;
}

.button.rose.default {
  background-color: var(--rose);
}

.button.l.violet-contour {
  border: 3px solid;
}

.button.s.device-mobile {
  padding: var(--spacings-spacing-md);
  display: inline-flex;
}

.button.rose.device-mini {
  display: inline-flex;
}

.button.pressed.rose {
  background-color: var(--rose-dark);
}

.button.blue.s {
  border-radius: 8px;
}

.button.m.violet-contour {
  border: 2px solid;
  border-radius: 8px;
  gap: 10px;
  width: 328px;
  padding: 20px 0;
  display: flex;
}

.button.violet-contour.device-mobile {
  height: 60px;
}

.button.red.l {
  height: 70px;
}

.button.s.green {
  border-radius: 8px;
}

.button.m.green {
  align-items: flex-start;
}

.button.l.device-mini, .button.device-desktop.l {
  height: 70px;
}

.button.pressed.violet-contour {
  background-color: var(--light-gray);
  border-color: var(--violet-dark);
}

.button.inactive.rose {
  background-color: var(--rose-light);
}

.button.m.red-contour {
  border: 2px solid;
  border-radius: 8px;
  gap: 10px;
  width: 328px;
  padding: 20px 0;
  display: flex;
}

.button.XL.violet-contour {
  border: 3px solid;
}

.button.s.red-contour, .button.red.s {
  border-radius: 8px;
}

.button.inactive.red-contour {
  border-color: var(--rose-light);
}

.button.red.m {
  align-items: flex-start;
}

.button.s.white {
  border-radius: 8px;
}

.button.device-desktop.s {
  width: 185px;
  height: 44px;
  padding: 20px 0;
}

.button.default.violet {
  justify-content: center;
  align-items: center;
}

.button.XL.red-contour {
  border: 3px solid;
}

.button.violet.m {
  background-color: var(--violet);
  width: 328px;
  display: flex;
}

.button.hover.violet-contour {
  background-color: var(--light-gray);
  border-color: var(--violet-dark);
}

.button.hover.green {
  background-color: var(--swampy);
}

.button.default.white {
  justify-content: center;
  align-items: center;
}

.button.l.violet {
  height: 70px;
}

.button.inactive.violet-contour {
  background-color: var(--white);
  border-color: var(--lavender);
}

.button.l.green {
  height: 70px;
}

.button.hover.violet {
  background-color: var(--violet-dark);
}

.button.pressed.red-contour {
  background-color: var(--light-gray);
  border-color: var(--red-dark);
}

.button.hover.blue {
  background-color: var(--blue-dark);
}

.button.s.violet {
  border-radius: 8px;
}

.button.l.device-max {
  height: 70px;
}

.button.m.white {
  width: 328px;
  display: flex;
}

.button.XL.red.default {
  background-color: var(--red);
}

.button.XL.default.red-contour {
  background-color: var(--white);
}

.button.rose.device-mini.m {
  padding: var(--spacings-spacing-md);
  border-radius: 6px;
}

.button.inactive.s.violet {
  background-color: var(--lavender);
}

.button.inactive.l.white {
  background-color: var(--gray);
}

.button.inactive.blue.m {
  align-items: flex-start;
}

.button.XL.inactive.red-contour {
  background-color: var(--white);
}

.button.default.s.violet {
  background-color: var(--violet);
}

.button.inactive.m.white {
  align-items: flex-start;
}

.button.inactive.l.blue {
  background-color: var(--blue-light);
}

.button.l.default.violet {
  background-color: var(--violet);
}

.button.XL.default.white {
  background-color: var(--white);
}

.button.default.s.red-contour {
  border: 2px solid;
}

.button.pressed.s.violet {
  background-color: var(--violet-dark);
}

.button.pressed.red.s {
  background-color: var(--red-dark);
}

.button.pressed.violet.m {
  align-items: flex-start;
}

.button.inactive.s.green {
  background-color: var(--lime);
}

.button.XL.default.green {
  background-color: var(--green);
}

.button.inactive.s.red-contour {
  border: 1px solid;
}

.button.red.l.inactive {
  background-color: var(--gray);
}

.button.inactive.m.red-contour {
  background-color: var(--white);
}

.button.red.s.inactive, .button.inactive.s.white {
  background-color: var(--gray);
}

.button.red.device-mini.m {
  padding: var(--spacings-spacing-md);
  border-radius: 6px;
  display: inline-flex;
}

.button.pressed.blue.m {
  align-items: flex-start;
}

.button.red.default.s {
  background-color: var(--red);
}

.button.device-mini.m.green {
  padding: var(--spacings-spacing-md);
  border-radius: 6px;
  display: inline-flex;
}

.button.green.m.device-mobile {
  display: inline-flex;
}

.button.inactive.l.green {
  background-color: var(--lime);
}

.button.default.blue.s {
  background-color: var(--blue);
}

.button.inactive.blue.s {
  background-color: var(--blue-light);
}

.button.l.default.green {
  background-color: var(--green);
}

.button.l.default.red-contour {
  background-color: var(--white);
}

.button.rose.s.device-mini {
  border-radius: 6px;
}

.button.XL.default.blue {
  background-color: var(--blue);
}

.button.red.l.default {
  background-color: var(--red);
}

.button.XL.inactive.white {
  background-color: var(--gray);
}

.button.blue.device-mini.m, .button.device-mini.m.white {
  padding: var(--spacings-spacing-md);
  border-radius: 6px;
}

.button.XL.inactive.blue {
  background-color: var(--blue-light);
}

.button.pressed.s.green {
  background-color: var(--swampy);
}

.button.hover.s.red-contour {
  border: 2px solid;
}

.button.default.s.green {
  background-color: var(--green);
}

.button.default.m.red-contour {
  background-color: var(--white);
}

.button.pressed.blue.s {
  background-color: var(--blue-dark);
}

.button.XL.inactive.green {
  background-color: var(--lime);
}

.button.default.s.white {
  background-color: var(--white);
}

.button.l.default.blue {
  background-color: var(--blue);
}

.button.inactive.l.violet {
  background-color: var(--lavender);
}

.button.inactive.violet.m {
  align-items: flex-start;
}

.button.s.device-mini.violet-contour {
  border-radius: 6px;
}

.button.inactive.l.red-contour {
  background-color: var(--white);
}

.button.XL.inactive.violet {
  background-color: var(--lavender);
}

.button.red.m.device-mobile {
  display: inline-flex;
}

.button.XL.red.inactive {
  background-color: var(--gray);
}

.button.m.device-mini.violet {
  padding: var(--spacings-spacing-md);
  border-radius: 6px;
}

.button.pressed.m.white {
  align-items: flex-start;
}

.button.pressed.s.white {
  background-color: var(--light-gray);
}

.button.l.default.white {
  background-color: var(--white);
}

.button.XL.default.violet {
  background-color: var(--violet);
}

.button.l .div-4 {
  font-family: var(--button-l-font-family);
  font-size: var(--button-l-font-size);
  font-style: var(--button-l-font-style);
  font-weight: var(--button-l-font-weight);
  letter-spacing: var(--button-l-letter-spacing);
  line-height: var(--button-l-line-height);
  white-space: nowrap;
  width: fit-content;
}

.button.s .div-4, .button.red-contour .div-4 {
  white-space: nowrap;
  width: fit-content;
}

.button.XL .div-4 {
  font-family: var(--button-XL-font-family);
  font-size: var(--button-XL-font-size);
  font-style: var(--button-XL-font-style);
  font-weight: var(--button-XL-font-weight);
  letter-spacing: var(--button-XL-letter-spacing);
  line-height: var(--button-XL-line-height);
  white-space: nowrap;
  width: fit-content;
}

.button.violet-contour .div-4 {
  white-space: nowrap;
  width: fit-content;
}

.button.s.device-mini .div-4 {
  font-family: var(--button-s-font-family);
  font-size: var(--button-s-font-size);
  font-style: var(--button-s-font-style);
  font-weight: var(--button-s-font-weight);
  letter-spacing: var(--button-s-letter-spacing);
  line-height: var(--button-s-line-height);
}

.button.hover.red .div-4 {
  color: var(--white);
}

.button.hover.s .div-4 {
  margin-bottom: -5.5px;
}

.button.XL.violet .div-4 {
  margin-top: -3px;
}

.button.default.red-contour .div-4 {
  color: var(--red);
}

.button.hover.red-contour .div-4 {
  color: var(--red-dark);
}

.button.red-contour.device-mobile .div-4 {
  margin-top: -1.5px;
}

.button.blue.m .div-4 {
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 328px;
  height: 60px;
  padding: 20px 0;
  display: flex;
  overflow: hidden;
}

.button.default.violet-contour .div-4 {
  color: var(--violet);
}

.button.XL.blue .div-4 {
  margin-top: -3px;
}

.button.hover.white .div-4, .button.rose.default .div-4 {
  color: var(--black);
}

.button.s.device-mobile .div-4 {
  font-family: var(--button-s-font-family);
  font-size: var(--button-s-font-size);
  font-style: var(--button-s-font-style);
  font-weight: var(--button-s-font-weight);
  letter-spacing: var(--button-s-letter-spacing);
  line-height: var(--button-s-line-height);
}

.button.XL.rose .div-4 {
  margin-top: -3px;
}

.button.pressed.rose .div-4 {
  color: var(--black);
}

.button.m.violet-contour .div-4 {
  font-family: var(--button-m-font-family);
  font-size: var(--button-m-font-size);
  font-style: var(--button-m-font-style);
  font-weight: var(--button-m-font-weight);
  letter-spacing: var(--button-m-letter-spacing);
  line-height: var(--button-m-line-height);
}

.button.violet-contour.device-mobile .div-4 {
  margin-top: -1.5px;
}

.button.XL.device-mini .div-4, .button.XL.red .div-4 {
  margin-top: -3px;
}

.button.device-mini.m .div-4 {
  margin-top: -2px;
}

.button.m.green .div-4 {
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 328px;
  height: 60px;
  padding: 20px 52px;
  display: flex;
  overflow: hidden;
}

.button.l.device-mini .div-4 {
  margin-top: -2px;
}

.button.pressed.violet-contour .div-4 {
  color: var(--violet-dark);
}

.button.inactive.rose .div-4 {
  color: var(--gray);
}

.button.m.red-contour .div-4 {
  font-family: var(--button-m-font-family);
  font-size: var(--button-m-font-size);
  font-style: var(--button-m-font-style);
  font-weight: var(--button-m-font-weight);
  letter-spacing: var(--button-m-letter-spacing);
  line-height: var(--button-m-line-height);
}

.button.inactive.red-contour .div-4 {
  color: var(--rose-light);
}

.button.red.m .div-4 {
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 328px;
  height: 60px;
  padding: 20px 52px;
  display: flex;
  overflow: hidden;
}

.button.device-desktop.s .div-4 {
  font-family: var(--button-m-font-family);
  font-size: var(--button-m-font-size);
  font-style: var(--button-m-font-style);
  font-weight: var(--button-m-font-weight);
  letter-spacing: var(--button-m-letter-spacing);
  line-height: var(--button-m-line-height);
  text-align: center;
}

.button.violet.m .div-4 {
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  height: 60px;
  display: flex;
  overflow: hidden;
  width: 328px !important;
}

.button.hover.violet-contour .div-4 {
  color: var(--violet-dark);
}

.button.XL.white .div-4 {
  margin-top: -3px;
}

.button.hover.green .div-4 {
  color: var(--black);
}

.button.XL.device-desktop .div-4, .button.XL.green .div-4 {
  margin-top: -3px;
}

.button.inactive.violet-contour .div-4 {
  color: var(--lavender);
}

.button.hover.violet .div-4 {
  color: var(--white);
}

.button.pressed.red-contour .div-4 {
  color: var(--red-dark);
}

.button.XL.device-max .div-4 {
  margin-top: -3px;
}

.button.hover.blue .div-4 {
  color: var(--white);
}

.button.m.white .div-4 {
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 328px;
  height: 60px;
  padding: 20px 0;
  display: flex;
  overflow: hidden;
}

.button.XL.red.default .div-4 {
  color: var(--white);
}

.button.red.m.inactive .div-4 {
  background-color: var(--gray);
}

.button.rose.device-mini.m .div-4 {
  font-family: var(--button-s-font-family);
  font-size: var(--button-s-font-size);
  font-style: var(--button-s-font-style);
  font-weight: var(--button-s-font-weight);
  letter-spacing: var(--button-s-letter-spacing);
  line-height: var(--button-s-line-height);
}

.button.inactive.s.violet .div-4 {
  color: var(--light-gray);
}

.button.pressed.m.green .div-4 {
  background-color: var(--swampy);
}

.button.inactive.l.white .div-4 {
  color: var(--dark-gray);
}

.button.inactive.blue.m .div-4 {
  background-color: var(--blue-light);
}

.button.default.s.violet .div-4 {
  color: var(--white);
}

.button.inactive.m.white .div-4 {
  background-color: var(--gray);
}

.button.inactive.l.blue .div-4 {
  color: var(--light-gray);
}

.button.l.default.violet .div-4 {
  color: var(--white);
}

.button.XL.default.white .div-4 {
  color: var(--black);
}

.button.pressed.s.violet .div-4, .button.pressed.red.s .div-4 {
  color: var(--white);
}

.button.default.m.white .div-4 {
  background-color: var(--white);
}

.button.pressed.violet.m .div-4 {
  background-color: var(--violet-dark);
}

.button.inactive.s.green .div-4 {
  color: var(--gray);
}

.button.XL.default.green .div-4 {
  color: var(--black);
}

.button.inactive.s.red-contour .div-4 {
  margin-bottom: -6.5px;
}

.button.red.l.inactive .div-4, .button.red.s.inactive .div-4 {
  color: var(--light-gray);
}

.button.inactive.s.white .div-4 {
  color: var(--dark-gray);
}

.button.red.device-mini.m .div-4 {
  font-family: var(--button-s-font-family);
  font-size: var(--button-s-font-size);
  font-style: var(--button-s-font-style);
  font-weight: var(--button-s-font-weight);
  letter-spacing: var(--button-s-letter-spacing);
  line-height: var(--button-s-line-height);
}

.button.pressed.blue.m .div-4 {
  background-color: var(--blue-dark);
}

.button.red.default.s .div-4 {
  color: var(--white);
}

.button.hover.s.green .div-4 {
  margin-top: -9.5px;
}

.button.device-mini.m.green .div-4 {
  font-family: var(--button-s-font-family);
  font-size: var(--button-s-font-size);
  font-style: var(--button-s-font-style);
  font-weight: var(--button-s-font-weight);
  letter-spacing: var(--button-s-letter-spacing);
  line-height: var(--button-s-line-height);
}

.button.default.violet.m .div-4 {
  background-color: var(--violet);
}

.button.inactive.l.green .div-4 {
  color: var(--gray);
}

.button.device-desktop.default.s .div-4 {
  margin-top: -9.5px;
  margin-bottom: -5.5px;
}

.button.default.blue.s .div-4 {
  color: var(--white);
}

.button.hover.device-desktop.s .div-4, .button.hover.red.s .div-4 {
  margin-top: -9.5px;
}

.button.inactive.blue.s .div-4 {
  color: var(--light-gray);
}

.button.default.s.device-mini .div-4 {
  margin-top: -2px;
}

.button.l.default.green .div-4 {
  color: var(--black);
}

.button.red.default.m .div-4 {
  background-color: var(--red);
}

.button.pressed.s.device-mini .div-4 {
  margin-top: -2px;
}

.button.default.blue.m .div-4 {
  background-color: var(--blue);
}

.button.XL.default.blue .div-4, .button.red.l.default .div-4 {
  color: var(--white);
}

.button.pressed.red.m .div-4 {
  background-color: var(--red-dark);
}

.button.hover.blue.s .div-4 {
  margin-top: -9.5px;
}

.button.XL.inactive.white .div-4 {
  color: var(--dark-gray);
}

.button.blue.device-mini.m .div-4 {
  font-family: var(--button-s-font-family);
  font-size: var(--button-s-font-size);
  font-style: var(--button-s-font-style);
  font-weight: var(--button-s-font-weight);
  letter-spacing: var(--button-s-letter-spacing);
  line-height: var(--button-s-line-height);
}

.button.hover.device-max.s .div-4, .button.hover.rose.s .div-4 {
  margin-top: -9.5px;
}

.button.device-mini.m.white .div-4 {
  font-family: var(--button-s-font-family);
  font-size: var(--button-s-font-size);
  font-style: var(--button-s-font-style);
  font-weight: var(--button-s-font-weight);
  letter-spacing: var(--button-s-letter-spacing);
  line-height: var(--button-s-line-height);
}

.button.XL.inactive.blue .div-4 {
  color: var(--light-gray);
}

.button.pressed.s.green .div-4 {
  color: var(--black);
}

.button.hover.s.white .div-4 {
  margin-top: -9.5px;
}

.button.default.s.green .div-4 {
  color: var(--black);
}

.button.hover.s.device-mini .div-4 {
  margin-top: -9.5px;
}

.button.pressed.blue.s .div-4 {
  color: var(--white);
}

.button.XL.inactive.green .div-4 {
  color: var(--gray);
}

.button.default.s.white .div-4 {
  color: var(--black);
}

.button.l.default.blue .div-4 {
  color: var(--white);
}

.button.inactive.l.violet .div-4 {
  color: var(--light-gray);
}

.button.inactive.violet.m .div-4 {
  background-color: var(--lavender);
}

.button.XL.inactive.violet .div-4 {
  color: var(--light-gray);
}

.button.default.m.green .div-4 {
  background-color: var(--green);
}

.button.XL.red.inactive .div-4 {
  color: var(--light-gray);
}

.button.m.device-mini.violet .div-4 {
  font-family: var(--button-s-font-family);
  font-size: var(--button-s-font-size);
  font-style: var(--button-s-font-style);
  font-weight: var(--button-s-font-weight);
  letter-spacing: var(--button-s-letter-spacing);
  line-height: var(--button-s-line-height);
}

.button.hover.s.violet .div-4 {
  margin-top: -9.5px;
}

.button.pressed.m.white .div-4 {
  background-color: var(--light-gray);
}

.button.pressed.s.white .div-4, .button.l.default.white .div-4 {
  color: var(--black);
}

.button.XL.default.violet .div-4 {
  color: var(--white);
}

.button.inactive.m.green .div-4 {
  background-color: var(--lime);
}

.button.white.pressed.s.device-mobile .div-4, .button.inactive.s.violet.device-mobile .div-4, .button.red.s.device-mini.inactive .div-4 {
  margin-top: -2px;
}

.button.device-desktop.inactive.s.violet .div-4 {
  margin-top: -9.5px;
  margin-bottom: -5.5px;
}

.button.pressed.blue.s.device-mobile .div-4 {
  margin-top: -2px;
}

.button.device-desktop.red.s.inactive .div-4 {
  margin-top: -9.5px;
  margin-bottom: -5.5px;
}

.button.rose.default.s.device-mobile .div-4, .button.red.default.s.device-mobile .div-4, .button.white.default.s.device-mobile .div-4, .button.pressed.s.violet.device-mobile .div-4, .button.white.inactive.s.device-mobile .div-4, .button.inactive.s.device-mini.violet-contour .div-4, .button.default.s.violet.device-mobile .div-4, .button.default.blue.s.device-mobile .div-4 {
  margin-top: -2px;
}

.button.device-desktop.inactive.s.white .div-4 {
  margin-top: -9.5px;
  margin-bottom: -5.5px;
}

.button.pressed.rose.s.device-mobile .div-4 {
  margin-top: -2px;
}

.button.inactive.s.device-mini.red-contour .div-4 {
  margin-top: -8.5px;
}

.button.inactive.rose.s.device-mini .div-4, .button.inactive.s.device-mini.white .div-4, .button.inactive.s.blue.device-mini .div-4, .button.green.inactive.s.device-mobile .div-4 {
  margin-top: -2px;
}

.button.device-desktop.inactive.blue.s .div-4 {
  margin-top: -9.5px;
  margin-bottom: -5.5px;
}

.button.pressed.red.s.device-mobile .div-4 {
  margin-top: -2px;
}

.button.inactive.device-max.s.red-contour .div-4 {
  margin-top: -8.5px;
}

.button.inactive.rose.s.device-mobile .div-4, .button.inactive.blue.s.device-mobile .div-4, .button.green.default.s.device-mobile .div-4 {
  margin-top: -2px;
}

.button.device-desktop.inactive.s.red-contour .div-4 {
  margin-top: -8.5px;
}

.button.inactive.s.device-mini.green .div-4, .button.inactive.s.device-mini.violet .div-4, .button.red.s.inactive.device-mobile .div-4, .button.pressed.green.s.device-mobile .div-4 {
  margin-top: -2px;
}

.button.pressed.green .text-wrapper-3, .button.default.white .text-wrapper-3, .button.pressed.white .text-wrapper-3 {
  color: var(--black);
}

.button.pressed.violet .text-wrapper-3, .button.pressed.blue .text-wrapper-3, .button.default.blue .text-wrapper-3 {
  color: var(--white);
}

.button.inactive.blue .text-wrapper-3, .button.inactive.violet .text-wrapper-3 {
  color: var(--light-gray);
}

.button.default.violet .text-wrapper-3 {
  color: var(--white);
}

.button.default.green .text-wrapper-3 {
  color: var(--black);
}

.button.red.inactive .text-wrapper-3 {
  color: var(--light-gray);
}

.button.red.default .text-wrapper-3, .button.pressed.red .text-wrapper-3 {
  color: var(--white);
}

.button.inactive.white .text-wrapper-3 {
  color: var(--dark-gray);
}

.button.inactive.green .text-wrapper-3 {
  color: var(--gray);
}

.card-mini-desktop {
  background-color: var(--green);
  padding: var(--spacings-spacing-xl) var(--spacings-spacing-xl) var(--spacings-spacing-xxxl) var(--spacings-spacing-xl);
  border-radius: 12px;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  width: 452px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.card-mini-desktop .frame-17 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  gap: 8px;
  width: 100%;
  display: flex;
  position: relative;
}

.card-mini-desktop .b-squeda {
  align-self: stretch;
  position: relative;
}

.card-mini-desktop .b-squeda-2 {
  color: var(--black);
  font-family: var(--XL-headline-HS-font-family);
  font-size: var(--XL-headline-HS-font-size);
  font-style: var(--XL-headline-HS-font-style);
  font-weight: var(--XL-headline-HS-font-weight);
  letter-spacing: var(--XL-headline-HS-letter-spacing);
  line-height: var(--XL-headline-HS-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.card-mini-desktop .seleccionamos-las {
  color: var(--black);
  font-family: var(--XL-body-BS-semibold-font-family);
  font-size: var(--XL-body-BS-semibold-font-size);
  font-style: var(--XL-body-BS-semibold-font-style);
  font-weight: var(--XL-body-BS-semibold-font-weight);
  letter-spacing: var(--XL-body-BS-semibold-letter-spacing);
  line-height: var(--XL-body-BS-semibold-line-height);
  align-self: stretch;
  position: relative;
}

.card-mini-desktop .interface-chart-line-2 {
  width: 32px !important;
  height: 32px !important;
  position: relative !important;
}

.card-mini-desktop .icon-true {
  align-items: center;
}

.card-mini-desktop .icon-false {
  align-items: flex-start;
}

.card-mini-desktop .frame-17.icon-true .b-squeda {
  flex: none;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  display: flex;
}

.card-mini-desktop .frame-17.icon-false .b-squeda {
  color: var(--black);
  font-family: var(--XL-headline-HS-font-family);
  font-size: var(--XL-headline-HS-font-size);
  font-style: var(--XL-headline-HS-font-style);
  font-weight: var(--XL-headline-HS-font-weight);
  letter-spacing: var(--XL-headline-HS-letter-spacing);
  line-height: var(--XL-headline-HS-line-height);
  margin-top: -1px;
}

.default {
  background: #fff;
  border-radius: 12px;
  flex-direction: column;
  gap: 20px;
  max-width: 822px;
  padding: 22px;
  display: flex;
}

.default .inputsWrapper {
  flex-direction: row;
  gap: 16px;
  width: 100%;
  display: flex;
}

.default .inputsWrapper .inputStyle {
  box-sizing: border-box;
  border: 2px solid #5f5f60;
  border-radius: 12px;
  flex-grow: 1;
  max-width: 250px;
  padding: 24px;
  font-size: 26px;
}

.default .inputsWrapper .inputStyleBig {
  box-sizing: border-box;
  border: 2px solid #5f5f60;
  border-radius: 12px;
  flex-grow: 2;
  padding: 24px;
  font-size: 26px;
}

.default .inputsWrapper input::placeholder {
  color: var(--Dark-gray, #5f5f60);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Red Hat Display;
  font-size: 26px;
}

.default .inputsWrapper .inputStyle:last-child {
  margin-right: 0;
}

.default .checkboxWrapperInvisible, .default .checkboxWrapper {
  align-items: center;
  gap: 8px;
  display: flex;
}

.default .checkboxWrapper label {
  align-items: center;
  gap: 5px;
  font-family: Red Hat Display;
  font-size: 18px;
  font-weight: 700;
  display: flex;
}

.default .checkboxWrapper .textCheckbox {
  font-family: Red Hat Display;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
}

.default .invisible {
  display: none !important;
}

.default button {
  cursor: pointer;
  text-transform: uppercase;
  color: #fff;
  background: #7a67f2;
  border: none;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  padding: 20px 0;
  font-family: Syncopate;
  font-size: 18px;
}

.second {
  max-width: inherit;
  flex-direction: row;
  gap: 24px;
}

.second button {
  width: 260px;
}

.default.second {
  width: 100%;
}

.third {
  width: 100%;
  padding: 0;
}

.third .inputsWrapper {
  flex-direction: column;
  display: flex;
}

.third .inputsWrapper .inputStyle {
  max-width: 100%;
}

.fourth {
  width: 100%;
  padding: 0;
}

.fourth .inputsWrapper {
  flex-direction: column;
  display: flex;
}

.fourth .inputsWrapper .inputStyle {
  border: none;
  border-bottom: 1px solid;
  border-radius: 0;
  max-width: 100%;
  padding: 6px;
}

.fourth .inputsWrapper input:focus {
  outline: none;
}

.fourth button {
  padding: 32px 0;
}

.mobileForm {
  max-width: 100%;
  padding: 0;
}

.mobileForm .inputsWrapper {
  flex-direction: column;
  gap: 10px;
}

.mobileForm .inputsWrapper .inputStyle {
  max-width: 100%;
  padding: 13px 16px;
}

.mobileForm .inputsWrapper input::placeholder {
  color: var(--Dark-gray, #5f5f60);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Red Hat Display;
  font-size: 16px;
}

.mobileForm .checkboxWrapper label, .mobileForm .checkboxWrapper .textCheckbox {
  font-size: 12px;
}

.mobileForm .btnForm {
  font-size: 16px;
}

.mobileSecond .inputsWrapper .inputStyle {
  border: none;
  border-bottom: 1px solid;
  border-radius: 0;
}

.mobileSecond .inputsWrapper input:focus {
  outline: none;
}

a {
  color: inherit;
  text-decoration: none;
}

.footer-landing-page {
  background-color: var(--white);
  border-top-left-radius: 28px;
  border-top-right-radius: 28px;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1920px;
  height: 693px;
  padding: 100px 0 0;
  display: flex;
}

.footer-landing-page .frame-21 {
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  width: 285px;
  display: flex;
  top: 100px;
  left: 735px;
}

.footer-landing-page .div-8 {
  color: var(--black);
  font-family: var(--XL-headline-HS-font-family);
  font-size: var(--XL-headline-HS-font-size);
  font-style: var(--XL-headline-HS-font-style);
  font-weight: var(--XL-headline-HS-font-weight);
  letter-spacing: var(--XL-headline-HS-letter-spacing);
  line-height: var(--XL-headline-HS-line-height);
  align-self: stretch;
  margin-top: -1px;
  position: relative;
}

.footer-landing-page .frame-22 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 8px;
  width: 100%;
  display: flex;
  position: relative;
}

.footer-landing-page .proyecto {
  color: var(--black);
  font-family: var(--XL-body-BM-semibold-font-family);
  font-size: var(--XL-body-BM-semibold-font-size);
  font-style: var(--XL-body-BM-semibold-font-style);
  font-weight: var(--XL-body-BM-semibold-font-weight);
  letter-spacing: var(--XL-body-BM-semibold-letter-spacing);
  line-height: var(--XL-body-BM-semibold-line-height);
  align-self: stretch;
  margin-top: -1px;
  position: relative;
}

.footer-landing-page .div-9 {
  color: var(--black);
  font-family: var(--XL-body-BM-semibold-font-family);
  font-size: var(--XL-body-BM-semibold-font-size);
  font-style: var(--XL-body-BM-semibold-font-style);
  font-weight: var(--XL-body-BM-semibold-font-weight);
  letter-spacing: var(--XL-body-BM-semibold-letter-spacing);
  line-height: var(--XL-body-BM-semibold-line-height);
  cursor: pointer;
  align-self: stretch;
  position: relative;
}

.footer-landing-page .frame-23 {
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  width: 381px;
  display: flex;
  top: 100px;
  left: 240px;
}

.footer-landing-page .frame-24 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 12px;
  width: 100%;
  display: flex;
  position: relative;
}

.footer-landing-page .div-10 {
  color: var(--black);
  font-family: var(--XL-title-TM-font-family);
  font-size: var(--XL-title-TM-font-size);
  font-style: var(--XL-title-TM-font-style);
  font-weight: var(--XL-title-TM-font-weight);
  letter-spacing: var(--XL-title-TM-letter-spacing);
  line-height: var(--XL-title-TM-line-height);
  align-self: stretch;
  margin-top: -1px;
  position: relative;
}

.footer-landing-page .div-11 {
  color: var(--black);
  font-family: var(--XL-body-BM-reg-font-family);
  font-size: var(--XL-body-BM-reg-font-size);
  font-style: var(--XL-body-BM-reg-font-style);
  font-weight: var(--XL-body-BM-reg-font-weight);
  letter-spacing: var(--XL-body-BM-reg-letter-spacing);
  line-height: var(--XL-body-BM-reg-line-height);
  align-self: stretch;
  position: relative;
}

.footer-landing-page .frame-25 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 8px;
  width: 343px;
  display: flex;
  position: relative;
}

.footer-landing-page .frame-26 {
  justify-content: space-between;
  align-items: flex-start;
  width: 1440px;
  margin-top: 100px;
  display: flex;
}

.footer-landing-page .frame-27 {
  flex: none;
  align-items: flex-start;
  gap: 88px;
  display: inline-flex;
  position: relative;
}

.footer-landing-page .div-12 {
  color: var(--black);
  font-family: var(--XL-small-text-ST-l-reg-font-family);
  font-size: var(--XL-small-text-ST-l-reg-font-size);
  font-style: var(--XL-small-text-ST-l-reg-font-style);
  font-weight: var(--XL-small-text-ST-l-reg-font-weight);
  letter-spacing: var(--XL-small-text-ST-l-reg-letter-spacing);
  line-height: var(--XL-small-text-ST-l-reg-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.footer-landing-page .frame-28 {
  justify-content: space-between;
  align-items: flex-start;
  width: 539px;
  display: flex;
  position: relative;
}

.footer-landing-page .instance-node-5 {
  flex: none !important;
}

.footer-landing-page .frame-29 {
  align-items: flex-start;
  gap: var(--spacings-spacing-xl);
  flex-direction: column;
  width: 539px;
  display: inline-flex;
  top: 100px;
  left: 1141px;
}

.footer-landing-page .SI-TE-INTERESA {
  color: var(--black);
  font-family: var(--XL-title-TXS-font-family);
  font-size: var(--XL-title-TXS-font-size);
  font-style: var(--XL-title-TXS-font-style);
  font-weight: var(--XL-title-TXS-font-weight);
  letter-spacing: var(--XL-title-TXS-letter-spacing);
  line-height: var(--XL-title-TXS-line-height);
  margin-top: -1px;
  position: relative;
}

.footer-landing-page .frame-30 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 32px;
  width: 539px;
  display: flex;
  position: relative;
}

.footer-landing-page .frame-31 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 20px;
  width: 100%;
  display: flex;
  position: relative;
}

.footer-landing-page .frame-32 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 6px;
  width: 100%;
  display: flex;
  position: relative;
}

.footer-landing-page .tel-fono {
  color: var(--gray);
  font-family: var(--XL-caps-CXS-med-font-family);
  font-size: var(--XL-caps-CXS-med-font-size);
  font-style: var(--XL-caps-CXS-med-font-style);
  font-weight: var(--XL-caps-CXS-med-font-weight);
  letter-spacing: var(--XL-caps-CXS-med-letter-spacing);
  line-height: var(--XL-caps-CXS-med-line-height);
  align-self: stretch;
  margin-top: -1px;
  position: relative;
}

.footer-landing-page .line-5 {
  object-fit: cover;
  align-self: stretch;
  width: 100%;
  height: 1px;
  position: relative;
}

.footer-landing-page .button-3 {
  flex: none !important;
  align-self: stretch !important;
  width: 100% !important;
}

.footer-landing-page .language-1-eng {
  width: 539px;
}

.footer-landing-page .language-1-esp {
  align-self: stretch;
}

.footer-main-container {
  flex-direction: row;
  justify-content: space-between;
  width: 1440px;
  display: flex;
}

.text-wrapper-70 {
  width: 300px;
}

.links {
  flex-direction: column;
  display: inline-flex;
  position: relative;
}

.links .link {
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.links .line-2 {
  align-self: stretch;
  width: 100%;
  position: relative;
}

.links.state-1-pressed, .links.state-1-hover {
  opacity: .6;
}

.links.desktop-m {
  gap: 2px;
}

.links.mobile-l {
  gap: 1px;
}

.links.desktop-l {
  gap: 2px;
}

.links.mobile-s {
  align-items: flex-start;
  gap: 1px;
}

.links.semi {
  align-items: flex-start;
}

.links.mobile-m {
  gap: 1px;
}

.links.desktop-m.bold {
  align-items: flex-start;
}

.links.desktop-m.med {
  align-items: flex-end;
}

.links.desktop-m.reg {
  align-items: flex-start;
}

.links .color-1-dark-green {
  color: var(--swampy);
}

.links .color-1-gray {
  color: var(--gray);
}

.links .color-1-white {
  color: var(--white);
}

.links .color-1-blue {
  color: var(--blue);
}

.links .size-mobile-l {
  font-family: var(--XS-body-BL-semibold-font-family);
  font-size: var(--XS-body-BL-semibold-font-size);
  font-style: var(--XS-body-BL-semibold-font-style);
  font-weight: var(--XS-body-BL-semibold-font-weight);
  letter-spacing: var(--XS-body-BL-semibold-letter-spacing);
  line-height: var(--XS-body-BL-semibold-line-height);
}

.links .size-desktop-l {
  font-family: var(--XL-body-BS-semibold-font-family);
  font-size: var(--XL-body-BS-semibold-font-size);
  font-style: var(--XL-body-BS-semibold-font-style);
  font-weight: var(--XL-body-BS-semibold-font-weight);
  letter-spacing: var(--XL-body-BS-semibold-letter-spacing);
  line-height: var(--XL-body-BS-semibold-line-height);
}

.links .color-1-black {
  color: var(--black);
}

.links .color-1-violet {
  color: var(--violet);
}

.links .color-1-dark-gray {
  color: var(--dark-gray);
}

.links .color-1-red {
  color: var(--red);
}

.links .size-mobile-m {
  font-family: var(--XS-body-BM-semibold-font-family);
  font-size: var(--XS-body-BM-semibold-font-size);
  font-style: var(--XS-body-BM-semibold-font-style);
  font-weight: var(--XS-body-BM-semibold-font-weight);
  letter-spacing: var(--XS-body-BM-semibold-letter-spacing);
  line-height: var(--XS-body-BM-semibold-line-height);
}

.links .size-mobile-s.weight-reg {
  font-family: var(--XS-small-text-ST-l-reg-font-family);
  font-size: var(--XS-small-text-ST-l-reg-font-size);
  font-style: var(--XS-small-text-ST-l-reg-font-style);
  font-weight: var(--XS-small-text-ST-l-reg-font-weight);
  letter-spacing: var(--XS-small-text-ST-l-reg-letter-spacing);
  line-height: var(--XS-small-text-ST-l-reg-line-height);
}

.links .size-desktop-m.weight-med {
  font-family: var(--XL-small-text-ST-l-med-font-family);
  font-size: var(--XL-small-text-ST-l-med-font-size);
  font-style: var(--XL-small-text-ST-l-med-font-style);
  font-weight: var(--XL-small-text-ST-l-med-font-weight);
  letter-spacing: var(--XL-small-text-ST-l-med-letter-spacing);
  line-height: var(--XL-small-text-ST-l-med-line-height);
}

.links .weight-med.size-mobile-s {
  font-family: var(--XS-small-text-ST-l-medium-font-family);
  font-size: var(--XS-small-text-ST-l-medium-font-size);
  font-style: var(--XS-small-text-ST-l-medium-font-style);
  font-weight: var(--XS-small-text-ST-l-medium-font-weight);
  letter-spacing: var(--XS-small-text-ST-l-medium-letter-spacing);
  line-height: var(--XS-small-text-ST-l-medium-line-height);
}

.links .size-desktop-m.weight-reg {
  font-family: var(--XL-small-text-ST-l-reg-font-family);
  font-size: var(--XL-small-text-ST-l-reg-font-size);
  font-style: var(--XL-small-text-ST-l-reg-font-style);
  font-weight: var(--XL-small-text-ST-l-reg-font-weight);
  letter-spacing: var(--XL-small-text-ST-l-reg-letter-spacing);
  line-height: var(--XL-small-text-ST-l-reg-line-height);
}

.links .size-desktop-m.weight-bold {
  font-family: var(--XL-small-text-ST-l-bold-font-family);
  font-size: var(--XL-small-text-ST-l-bold-font-size);
  font-style: var(--XL-small-text-ST-l-bold-font-style);
  font-weight: var(--XL-small-text-ST-l-bold-font-weight);
  letter-spacing: var(--XL-small-text-ST-l-bold-letter-spacing);
  line-height: var(--XL-small-text-ST-l-bold-line-height);
}

.links .size-mobile-s.weight-bold {
  font-family: var(--XS-small-text-ST-l-bold-font-family);
  font-size: var(--XS-small-text-ST-l-bold-font-size);
  font-style: var(--XS-small-text-ST-l-bold-font-style);
  font-weight: var(--XS-small-text-ST-l-bold-font-weight);
  letter-spacing: var(--XS-small-text-ST-l-bold-letter-spacing);
  line-height: var(--XS-small-text-ST-l-bold-line-height);
}

.links.mobile-l .line-2, .links.med .line-2, .links.reg .line-2, .links.mobile-m .line-2 {
  object-fit: cover;
  height: 1px;
}

.links.desktop-l.bold .line-2, .links.desktop-l.semi .line-2, .links.desktop-m.bold .line-2 {
  height: 2px;
}

.links.mobile-s.bold .line-2 {
  object-fit: cover;
  height: 1px;
}

.header-desktop {
  background-color: var(--black);
  padding: var(--spacings-spacing-lg) 0 var(--spacings-spacing-lg) 0;
  border-color: #5f5f604c;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-radius: 0 0 28px 28px;
  flex-direction: column;
  justify-content: center;
  gap: 36px;
  max-width: 1920px;
  display: flex;
  position: relative;
}

.header-desktop .frame-6 {
  flex: none;
  align-items: center;
  position: relative;
}

.header-desktop .frame-7 {
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 36px;
  display: inline-flex;
  position: relative;
}

.header-desktop .div-2 {
  color: var(--white);
  font-family: var(--XL-title-TXS-font-family);
  font-size: var(--XL-title-TXS-font-size);
  font-style: var(--XL-title-TXS-font-style);
  font-weight: var(--XL-title-TXS-font-weight);
  letter-spacing: var(--XL-title-TXS-letter-spacing);
  line-height: var(--XL-title-TXS-line-height);
  white-space: nowrap;
  width: fit-content;
  position: relative;
}

.header-desktop .frame-8 {
  align-items: center;
  gap: var(--spacings-spacing-none);
  flex: none;
  display: inline-flex;
  position: relative;
}

.header-desktop .arrow-caret-down-MD {
  width: 32px !important;
  height: 32px !important;
  position: relative !important;
}

.header-desktop .div-3 {
  color: var(--white);
  font-family: var(--XL-title-TXS-font-family);
  font-size: var(--XL-title-TXS-font-size);
  font-style: var(--XL-title-TXS-font-style);
  font-weight: var(--XL-title-TXS-font-weight);
  letter-spacing: var(--XL-title-TXS-letter-spacing);
  line-height: var(--XL-title-TXS-line-height);
  white-space: nowrap;
  cursor: pointer;
  width: fit-content;
  margin-top: -1px;
  position: relative;

  & a {
    color: #fff;
    text-transform: uppercase;
  }
}

.header-desktop .frame-9 {
  flex: none;
  align-items: center;
  display: inline-flex;
  position: relative;
}

.header-desktop .class {
  width: 323px !important;
}

.header-desktop .button-instance {
  width: 181px !important;
}

.header-desktop .class-2 {
  background-color: var(--red) !important;
  width: 181px !important;
}

.header-desktop.type-marketplace {
  align-items: center;
}

.header-desktop.type-caribs {
  align-items: flex-start;
}

.header-desktop.type-timesharing-rent, .header-desktop.type-lottery {
  align-items: center;
}

.header-desktop.type-marketplace .frame-6 {
  justify-content: space-between;
  align-self: stretch;
  width: 100%;
  display: flex;
}

.header-desktop.type-caribs .frame-6 {
  gap: var(--spacings-spacing-lg);
  min-width: 1440px;
  margin: 0 auto;
  display: inline-flex;
}

.header-desktop.type-timesharing-rent .frame-6, .header-desktop.type-lottery .frame-6 {
  justify-content: space-between;
  align-self: stretch;
  width: 100%;
  display: flex;
}

.header-desktop.type-marketplace .frame-9, .header-desktop.type-caribs .frame-9, .header-desktop.type-timesharing-rent .frame-9 {
  gap: 24px;
}

.header-desktop.type-lottery .frame-9 {
  gap: var(--spacings-spacing-md);
  justify-content: flex-end;
}

.new-logo {
  align-items: center;
  gap: var(--spacings-spacing-sm);
  display: inline-flex;
  position: relative;
}

.new-logo .logo {
  position: relative;
}

.new-logo .rent-onchain {
  color: var(--white);
  white-space: nowrap;
  width: fit-content;
  position: relative;
}

.new-logo .desktop {
  width: 43.45px;
  height: 48px;
}

.new-logo .mobile {
  width: 33.49px;
  height: 37px;
}

.new-logo .size-desktop {
  font-family: var(--XS-title-TL-font-family);
  font-size: var(--XS-title-TL-font-size);
  font-style: var(--XS-title-TL-font-style);
  font-weight: var(--XS-title-TL-font-weight);
  letter-spacing: var(--XS-title-TL-letter-spacing);
  line-height: var(--XS-title-TL-line-height);
}

.new-logo .size-mobile {
  font-family: var(--logo-l-font-family);
  font-size: var(--logo-l-font-size);
  font-style: var(--logo-l-font-style);
  font-weight: var(--logo-l-font-weight);
  letter-spacing: var(--logo-l-letter-spacing);
  line-height: var(--logo-l-line-height);
}

.header-desktop-top {
  background-color: var(--black);
  padding: var(--spacings-spacing-lg) 0 var(--spacings-spacing-lg) 0;
  border-color: #5f5f604c;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 36px;
  max-width: 1920px;
  display: flex;
  position: relative;
}

.header-desktop-top .frame {
  flex: none;
  justify-content: space-between;
  align-self: stretch;
  align-items: center;
  width: 1440px;
  margin: 0 auto;
  display: flex;
  position: relative;
}

.header-desktop-top .frame-wrapper {
  align-items: center;
  gap: var(--spacings-spacing-xxxl);
  width: 941.2px;
  display: flex;
  position: relative;
}

.header-desktop-top .div {
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 36px;
  display: inline-flex;
  position: relative;
}

.header-desktop-top .new-logo-instance {
  flex: none !important;
}

.header-desktop-top .frame-2 {
  align-items: center;
  gap: var(--spacings-spacing-none);
  padding: 0px 0px var(--spacings-spacing-xs) 0px;
  flex: none;
  display: inline-flex;
  position: relative;
}

.header-desktop-top .more-wrapper {
  padding: 0px 0px var(--spacings-spacing-xxs) 0px;
  flex: none;
  align-items: center;
  gap: 10px;
  display: inline-flex;
  position: relative;
}

.header-desktop-top .more {
  color: var(--gray);
  font-family: var(--XL-small-text-ST-l-med-font-family);
  font-size: var(--XL-small-text-ST-l-med-font-size);
  font-style: var(--XL-small-text-ST-l-med-font-style);
  font-weight: var(--XL-small-text-ST-l-med-font-weight);
  letter-spacing: var(--XL-small-text-ST-l-med-letter-spacing);
  line-height: var(--XL-small-text-ST-l-med-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.header-desktop-top .instance-node {
  width: 32px !important;
  height: 32px !important;
  position: relative !important;
}

.header-desktop-top .frame-3 {
  flex: none;
  justify-content: flex-end;
  align-self: stretch;
  align-items: center;
  display: inline-flex;
  position: relative;
}

.header-desktop-top .frame-4 {
  align-items: center;
  gap: var(--spacings-spacing-none);
  flex: none;
  justify-content: flex-end;
  display: inline-flex;
  position: relative;
}

.header-desktop-top .eng-2 {
  color: var(--white);
  font-family: var(--XL-caps-CS-bold-font-family);
  font-size: var(--XL-caps-CS-bold-font-size);
  font-style: var(--XL-caps-CS-bold-font-style);
  font-weight: var(--XL-caps-CS-bold-font-weight);
  letter-spacing: var(--XL-caps-CS-bold-letter-spacing);
  line-height: var(--XL-caps-CS-bold-line-height);
  white-space: nowrap;
  width: fit-content;
  position: relative;
}

.header-desktop-top .frame-5 {
  align-self: stretch;
  align-items: center;
  gap: 16px;
  width: 88px;
  display: flex;
  position: relative;
}

.header-desktop-top .component {
  background-color: var(--white);
  border-radius: 4px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 32px;
  height: 32px;
  padding: 4px 8px;
  display: flex;
  position: relative;
}

.header-desktop-top .text-wrapper {
  color: var(--black);
  font-family: var(--button-m-font-family);
  font-size: var(--button-m-font-size);
  font-style: var(--button-m-font-style);
  font-weight: var(--button-m-font-weight);
  letter-spacing: var(--button-m-letter-spacing);
  line-height: var(--button-m-line-height);
  white-space: nowrap;
  width: fit-content;
  position: relative;
}

.header-desktop-top .div-wrapper {
  background-color: var(--gray);
  border-radius: 4px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 32px;
  height: 32px;
  padding: 4px 8px;
  display: flex;
  position: relative;
}

.header-desktop-top .text-wrapper-2 {
  color: var(--dark-gray);
  font-family: var(--button-m-font-family);
  font-size: var(--button-m-font-size);
  font-style: var(--button-m-font-style);
  font-weight: var(--button-m-font-weight);
  letter-spacing: var(--button-m-letter-spacing);
  line-height: var(--button-m-line-height);
  white-space: nowrap;
  width: fit-content;
  position: relative;
}

.header-desktop-top .caribs {
  gap: var(--spacings-spacing-lg);
}

.header-desktop-top .lottery, .header-desktop-top .academy, .header-desktop-top .marketplace, .header-desktop-top .tokenization, .header-desktop-top .timesharing-rent, .header-desktop-top .defi {
  gap: var(--spacings-spacing-xxxl);
}

.language-select {
  color: var(--white);
  background-color: var(--black);
  font-family: var(--XL-caps-CS-bold-font-family);
  font-size: var(--XL-caps-CS-bold-font-size);
  font-style: var(--XL-caps-CS-bold-font-style);
  font-weight: var(--XL-caps-CS-bold-font-weight);
  letter-spacing: var(--XL-caps-CS-bold-letter-spacing);
  line-height: var(--XL-caps-CS-bold-line-height);
  cursor: pointer;
  border: none;
}

.header-timesharing {
  flex-direction: column;
  align-items: center;
  width: 100%;
  display: inline-flex;
  position: relative;
}

.header-timesharing .instance-node-2 {
  width: 100%;
  max-width: 1920px;
}

.timer {
  justify-content: space-between;
  align-items: center;
  display: flex;
  position: relative;
}

.timer .element-d {
  color: #0000;
  white-space: nowrap;
  width: fit-content;
  position: relative;
}

.timer .span {
  color: #e73a3a;
}

.timer .text-wrapper-10 {
  color: #f29595;
}

.timer .rectangle {
  background-color: var(--rose-dark);
  position: relative;
}

.timer .element-h {
  color: #0000;
  white-space: nowrap;
  width: fit-content;
  position: relative;
}

.timer .text-wrapper-11 {
  color: #e73a3a;
}

.timer .text-wrapper-12 {
  color: #f29595;
}

.timer .rectangle-2 {
  background-color: var(--rose-dark);
  position: relative;
}

.timer .element-m {
  color: #0000;
  white-space: nowrap;
  width: fit-content;
  position: relative;
}

.timer .text-wrapper-13 {
  color: #e73a3a;
}

.timer .text-wrapper-14 {
  color: #f29595;
}

.timer .rectangle-3 {
  background-color: var(--rose-dark);
  position: relative;
}

.timer .element-s {
  color: #0000;
  white-space: nowrap;
  width: fit-content;
  position: relative;
}

.timer .text-wrapper-15 {
  color: #e73a3a;
}

.timer .text-wrapper-16 {
  color: #f29595;
}

.timer.size-15-desktop {
  padding: var(--spacings-spacing-md) var(--spacings-spacing-lg) var(--spacings-spacing-md) var(--spacings-spacing-lg);
  border-radius: 12px;
  width: 693px;
}

.timer.theme-13-light {
  background-color: var(--black);
}

.timer.theme-13-dark {
  background-color: var(--white);
}

.timer.size-15-mobile {
  padding: var(--spacings-spacing-md);
  border-radius: 8px;
  width: 328px;
}

.timer.size-15-desktop .element-d {
  font-family: var(--XL-headline-HM-font-family);
  font-size: var(--XL-headline-HM-font-size);
  font-style: var(--XL-headline-HM-font-style);
  font-weight: var(--XL-headline-HM-font-weight);
  letter-spacing: var(--XL-headline-HM-letter-spacing);
  line-height: var(--XL-headline-HM-line-height);
  margin-top: -1px;
}

.timer.size-15-mobile .element-d {
  font-family: var(--XS-headline-HS-font-family);
  font-size: var(--XS-headline-HS-font-size);
  font-style: var(--XS-headline-HS-font-style);
  font-weight: var(--XS-headline-HS-font-weight);
  letter-spacing: var(--XS-headline-HS-letter-spacing);
  line-height: var(--XS-headline-HS-line-height);
}

.timer.size-15-desktop .span {
  font-family: var(--XL-headline-HM-font-family);
  font-size: var(--XL-headline-HM-font-size);
  font-style: var(--XL-headline-HM-font-style);
  font-weight: var(--XL-headline-HM-font-weight);
  letter-spacing: var(--XL-headline-HM-letter-spacing);
  line-height: var(--XL-headline-HM-line-height);
}

.timer.size-15-mobile .span {
  font-family: var(--XS-headline-HS-font-family);
  font-size: var(--XS-headline-HS-font-size);
  font-style: var(--XS-headline-HS-font-style);
  font-weight: var(--XS-headline-HS-font-weight);
  letter-spacing: var(--XS-headline-HS-letter-spacing);
  line-height: var(--XS-headline-HS-line-height);
}

.timer.size-15-desktop .text-wrapper-10 {
  font-family: var(--XL-headline-HM-font-family);
  font-size: var(--XL-headline-HM-font-size);
  font-style: var(--XL-headline-HM-font-style);
  font-weight: var(--XL-headline-HM-font-weight);
  letter-spacing: var(--XL-headline-HM-letter-spacing);
  line-height: var(--XL-headline-HM-line-height);
}

.timer.size-15-mobile .text-wrapper-10 {
  font-family: var(--XS-headline-HS-font-family);
  font-size: var(--XS-headline-HS-font-size);
  font-style: var(--XS-headline-HS-font-style);
  font-weight: var(--XS-headline-HS-font-weight);
  letter-spacing: var(--XS-headline-HS-letter-spacing);
  line-height: var(--XS-headline-HS-line-height);
}

.timer.size-15-desktop .rectangle {
  width: 5px;
  height: 55px;
}

.timer.size-15-mobile .rectangle {
  width: 3px;
  height: 37px;
}

.timer.size-15-desktop .element-h {
  font-family: var(--XL-headline-HM-font-family);
  font-size: var(--XL-headline-HM-font-size);
  font-style: var(--XL-headline-HM-font-style);
  font-weight: var(--XL-headline-HM-font-weight);
  letter-spacing: var(--XL-headline-HM-letter-spacing);
  line-height: var(--XL-headline-HM-line-height);
  margin-top: -1px;
}

.timer.size-15-mobile .element-h {
  font-family: var(--XS-headline-HS-font-family);
  font-size: var(--XS-headline-HS-font-size);
  font-style: var(--XS-headline-HS-font-style);
  font-weight: var(--XS-headline-HS-font-weight);
  letter-spacing: var(--XS-headline-HS-letter-spacing);
  line-height: var(--XS-headline-HS-line-height);
}

.timer.size-15-desktop .text-wrapper-11 {
  font-family: var(--XL-headline-HM-font-family);
  font-size: var(--XL-headline-HM-font-size);
  font-style: var(--XL-headline-HM-font-style);
  font-weight: var(--XL-headline-HM-font-weight);
  letter-spacing: var(--XL-headline-HM-letter-spacing);
  line-height: var(--XL-headline-HM-line-height);
}

.timer.size-15-mobile .text-wrapper-11 {
  font-family: var(--XS-headline-HS-font-family);
  font-size: var(--XS-headline-HS-font-size);
  font-style: var(--XS-headline-HS-font-style);
  font-weight: var(--XS-headline-HS-font-weight);
  letter-spacing: var(--XS-headline-HS-letter-spacing);
  line-height: var(--XS-headline-HS-line-height);
}

.timer.size-15-desktop .text-wrapper-12 {
  font-family: var(--XL-headline-HM-font-family);
  font-size: var(--XL-headline-HM-font-size);
  font-style: var(--XL-headline-HM-font-style);
  font-weight: var(--XL-headline-HM-font-weight);
  letter-spacing: var(--XL-headline-HM-letter-spacing);
  line-height: var(--XL-headline-HM-line-height);
}

.timer.size-15-mobile .text-wrapper-12 {
  font-family: var(--XS-headline-HS-font-family);
  font-size: var(--XS-headline-HS-font-size);
  font-style: var(--XS-headline-HS-font-style);
  font-weight: var(--XS-headline-HS-font-weight);
  letter-spacing: var(--XS-headline-HS-letter-spacing);
  line-height: var(--XS-headline-HS-line-height);
}

.timer.size-15-desktop .rectangle-2 {
  width: 5px;
  height: 55px;
}

.timer.size-15-mobile .rectangle-2 {
  width: 3px;
  height: 37px;
}

.timer.size-15-desktop .element-m {
  font-family: var(--XL-headline-HM-font-family);
  font-size: var(--XL-headline-HM-font-size);
  font-style: var(--XL-headline-HM-font-style);
  font-weight: var(--XL-headline-HM-font-weight);
  letter-spacing: var(--XL-headline-HM-letter-spacing);
  line-height: var(--XL-headline-HM-line-height);
  margin-top: -1px;
}

.timer.size-15-mobile .element-m {
  font-family: var(--XS-headline-HS-font-family);
  font-size: var(--XS-headline-HS-font-size);
  font-style: var(--XS-headline-HS-font-style);
  font-weight: var(--XS-headline-HS-font-weight);
  letter-spacing: var(--XS-headline-HS-letter-spacing);
  line-height: var(--XS-headline-HS-line-height);
}

.timer.size-15-desktop .text-wrapper-13 {
  font-family: var(--XL-headline-HM-font-family);
  font-size: var(--XL-headline-HM-font-size);
  font-style: var(--XL-headline-HM-font-style);
  font-weight: var(--XL-headline-HM-font-weight);
  letter-spacing: var(--XL-headline-HM-letter-spacing);
  line-height: var(--XL-headline-HM-line-height);
}

.timer.size-15-mobile .text-wrapper-13 {
  font-family: var(--XS-headline-HS-font-family);
  font-size: var(--XS-headline-HS-font-size);
  font-style: var(--XS-headline-HS-font-style);
  font-weight: var(--XS-headline-HS-font-weight);
  letter-spacing: var(--XS-headline-HS-letter-spacing);
  line-height: var(--XS-headline-HS-line-height);
}

.timer.size-15-desktop .text-wrapper-14 {
  font-family: var(--XL-headline-HM-font-family);
  font-size: var(--XL-headline-HM-font-size);
  font-style: var(--XL-headline-HM-font-style);
  font-weight: var(--XL-headline-HM-font-weight);
  letter-spacing: var(--XL-headline-HM-letter-spacing);
  line-height: var(--XL-headline-HM-line-height);
}

.timer.size-15-mobile .text-wrapper-14 {
  font-family: var(--XS-headline-HS-font-family);
  font-size: var(--XS-headline-HS-font-size);
  font-style: var(--XS-headline-HS-font-style);
  font-weight: var(--XS-headline-HS-font-weight);
  letter-spacing: var(--XS-headline-HS-letter-spacing);
  line-height: var(--XS-headline-HS-line-height);
}

.timer.size-15-desktop .rectangle-3 {
  width: 5px;
  height: 55px;
}

.timer.size-15-mobile .rectangle-3 {
  width: 3px;
  height: 37px;
}

.timer.size-15-desktop .element-s {
  font-family: var(--XL-headline-HM-font-family);
  font-size: var(--XL-headline-HM-font-size);
  font-style: var(--XL-headline-HM-font-style);
  font-weight: var(--XL-headline-HM-font-weight);
  letter-spacing: var(--XL-headline-HM-letter-spacing);
  line-height: var(--XL-headline-HM-line-height);
  margin-top: -1px;
}

.timer.size-15-mobile .element-s {
  font-family: var(--XS-headline-HS-font-family);
  font-size: var(--XS-headline-HS-font-size);
  font-style: var(--XS-headline-HS-font-style);
  font-weight: var(--XS-headline-HS-font-weight);
  letter-spacing: var(--XS-headline-HS-letter-spacing);
  line-height: var(--XS-headline-HS-line-height);
}

.timer.size-15-desktop .text-wrapper-15 {
  font-family: var(--XL-headline-HM-font-family);
  font-size: var(--XL-headline-HM-font-size);
  font-style: var(--XL-headline-HM-font-style);
  font-weight: var(--XL-headline-HM-font-weight);
  letter-spacing: var(--XL-headline-HM-letter-spacing);
  line-height: var(--XL-headline-HM-line-height);
}

.timer.size-15-mobile .text-wrapper-15 {
  font-family: var(--XS-headline-HS-font-family);
  font-size: var(--XS-headline-HS-font-size);
  font-style: var(--XS-headline-HS-font-style);
  font-weight: var(--XS-headline-HS-font-weight);
  letter-spacing: var(--XS-headline-HS-letter-spacing);
  line-height: var(--XS-headline-HS-line-height);
}

.timer.size-15-desktop .text-wrapper-16 {
  font-family: var(--XL-headline-HM-font-family);
  font-size: var(--XL-headline-HM-font-size);
  font-style: var(--XL-headline-HM-font-style);
  font-weight: var(--XL-headline-HM-font-weight);
  letter-spacing: var(--XL-headline-HM-letter-spacing);
  line-height: var(--XL-headline-HM-line-height);
}

.timer.size-15-mobile .text-wrapper-16 {
  font-family: var(--XS-headline-HS-font-family);
  font-size: var(--XS-headline-HS-font-size);
  font-style: var(--XS-headline-HS-font-style);
  font-weight: var(--XS-headline-HS-font-weight);
  letter-spacing: var(--XS-headline-HS-letter-spacing);
  line-height: var(--XS-headline-HS-line-height);
}

.status {
  display: inline-flex;
  position: relative;
}

.status .finansiado {
  white-space: nowrap;
  width: fit-content;
  position: relative;
}

.status.in-operation {
  background-color: var(--blue);
}

.status.size-13-desktop {
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  padding: 8px 24px;
}

.status.under-renovation {
  background-color: var(--gray);
}

.status.coming-soon {
  background-color: var(--violet);
}

.status.reformed {
  background-color: var(--orange);
}

.status.size-13-mobile {
  padding: var(--spacings-spacing-xs) var(--spacings-spacing-sm) var(--spacings-spacing-xs) var(--spacings-spacing-sm);
  border-radius: 3px;
  align-items: flex-start;
  gap: 10px;
}

.status.rented {
  background-color: var(--red);
}

.status.finansed {
  background-color: var(--green);
}

.status.in-operation .finansiado {
  color: var(--white);
}

.status.size-13-desktop .finansiado {
  font-family: var(--XL-small-text-ST-l-bold-font-family);
  font-size: var(--XL-small-text-ST-l-bold-font-size);
  font-style: var(--XL-small-text-ST-l-bold-font-style);
  font-weight: var(--XL-small-text-ST-l-bold-font-weight);
  letter-spacing: var(--XL-small-text-ST-l-bold-letter-spacing);
  line-height: var(--XL-small-text-ST-l-bold-line-height);
  margin-top: -.65px;
}

.status.under-renovation .finansiado {
  color: var(--black);
}

.status.coming-soon .finansiado, .status.reformed .finansiado {
  color: var(--white);
}

.status.size-13-mobile .finansiado {
  font-family: var(--XS-small-text-ST-l-bold-font-family);
  font-size: var(--XS-small-text-ST-l-bold-font-size);
  font-style: var(--XS-small-text-ST-l-bold-font-style);
  font-weight: var(--XS-small-text-ST-l-bold-font-weight);
  letter-spacing: var(--XS-small-text-ST-l-bold-letter-spacing);
  line-height: var(--XS-small-text-ST-l-bold-line-height);
  margin-top: -1px;
}

.status.rented .finansiado {
  color: var(--white);
}

.status.finansed .finansiado {
  color: var(--black);
}

.real-estate-card {
  background-color: var(--white);
  border-radius: 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  width: 464px;
  padding: 24px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.real-estate-card .frame-11 {
  background-image: url("rectangle-260-6.94e3ac66.png");
  background-position: 50%;
  background-size: cover;
  align-self: stretch;
  width: 100%;
  height: 254px;
  position: relative;
}

.real-estate-card .arrow-caret-right-SM {
  width: 40px !important;
  height: 40px !important;
  position: absolute !important;
  top: 106px !important;
  left: 376px !important;
}

.real-estate-card .arrow-caret-left-SM {
  width: 40px !important;
  height: 40px !important;
  position: absolute !important;
  top: 106px !important;
  left: 0 !important;
}

.real-estate-card .slider {
  align-items: center;
  gap: var(--spacings-spacing-sm);
  justify-content: center;
  width: 296px;
  display: flex;
  position: absolute;
  top: 238px;
  left: 60px;
}

.real-estate-card .frame-12 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 16px;
  width: 100%;
  display: flex;
  position: relative;
}

.real-estate-card .frame-13 {
  align-self: stretch;
  align-items: flex-start;
  gap: var(--spacings-spacing-xs);
  flex-direction: column;
  flex: none;
  width: 100%;
  display: flex;
  position: relative;
}

.real-estate-card .frame-14 {
  flex: none;
  justify-content: space-between;
  align-self: stretch;
  align-items: center;
  width: 100%;
  display: flex;
  position: relative;
}

.real-estate-card .TULUM {
  color: var(--black);
  font-family: var(--XL-title-TS-font-family);
  font-size: var(--XL-title-TS-font-size);
  font-style: var(--XL-title-TS-font-style);
  font-weight: var(--XL-title-TS-font-weight);
  letter-spacing: var(--XL-title-TS-letter-spacing);
  line-height: var(--XL-title-TS-line-height);
  white-space: nowrap;
  width: fit-content;
  position: relative;
}

.real-estate-card .frame-15 {
  align-items: center;
  gap: var(--spacings-spacing-sm);
  flex: none;
  display: inline-flex;
  position: relative;
}

.real-estate-card .group {
  width: 106px;
  height: 31px;
  margin-right: -2px;
  position: relative;
}

.real-estate-card .overlap-group {
  width: 104px;
  height: 31px;
  position: relative;
}

.real-estate-card .element {
  color: var(--gray);
  font-family: var(--XL-body-BM-bold-font-family);
  font-size: var(--XL-body-BM-bold-font-size);
  font-style: var(--XL-body-BM-bold-font-style);
  font-weight: var(--XL-body-BM-bold-font-weight);
  letter-spacing: var(--XL-body-BM-bold-letter-spacing);
  line-height: var(--XL-body-BM-bold-line-height);
  white-space: nowrap;
  position: absolute;
  top: 0;
  left: 0;
}

.real-estate-card .line {
  width: 104px;
  height: 2px;
  position: absolute;
  top: 15px;
  left: 0;
}

.real-estate-card .status-instance {
  flex: none !important;
}

.real-estate-card .element-2 {
  color: var(--violet-dark);
  font-family: var(--XL-title-TL-font-family);
  font-size: var(--XL-title-TL-font-size);
  font-style: var(--XL-title-TL-font-style);
  font-weight: var(--XL-title-TL-font-weight);
  letter-spacing: var(--XL-title-TL-letter-spacing);
  line-height: var(--XL-title-TL-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.real-estate-card .frame-16 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 10px;
  width: 100%;
  display: flex;
  position: relative;
}

.real-estate-card .div-6 {
  color: var(--black);
  font-family: var(--XL-small-text-ST-l-reg-font-family);
  font-size: var(--XL-small-text-ST-l-reg-font-size);
  font-style: var(--XL-small-text-ST-l-reg-font-style);
  font-weight: var(--XL-small-text-ST-l-reg-font-weight);
  letter-spacing: var(--XL-small-text-ST-l-reg-letter-spacing);
  line-height: var(--XL-small-text-ST-l-reg-line-height);
  flex: 1;
  margin-top: -.65px;
  position: relative;
}

.real-estate-card .div-7 {
  color: var(--black);
  font-family: var(--XL-small-text-ST-l-bold-font-family);
  font-size: var(--XL-small-text-ST-l-bold-font-size);
  font-style: var(--XL-small-text-ST-l-bold-font-style);
  font-weight: var(--XL-small-text-ST-l-bold-font-weight);
  letter-spacing: var(--XL-small-text-ST-l-bold-letter-spacing);
  line-height: var(--XL-small-text-ST-l-bold-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -.65px;
  position: relative;
}

.real-estate-card .hu-spedes {
  color: var(--black);
  font-family: var(--XL-small-text-ST-l-reg-font-family);
  font-size: var(--XL-small-text-ST-l-reg-font-size);
  font-style: var(--XL-small-text-ST-l-reg-font-style);
  font-weight: var(--XL-small-text-ST-l-reg-font-weight);
  letter-spacing: var(--XL-small-text-ST-l-reg-letter-spacing);
  line-height: var(--XL-small-text-ST-l-reg-line-height);
  margin-top: -.65px;
  position: relative;
}

.real-estate-card .tipo-de-inmueble, .real-estate-card .apartamento {
  white-space: nowrap;
  width: fit-content;
  margin-top: -.65px;
  position: relative;
}

.real-estate-card .instance-node-4 {
  width: 24px !important;
  height: 24px !important;
  position: relative !important;
}

.real-estate-card .img-2 {
  width: 22px;
  height: 22px;
  position: relative;
}

.real-estate-card .la-fotograf-a-puede {
  color: var(--dark-gray);
  font-family: var(--XL-small-text-ST-s-semibold-font-family);
  font-size: var(--XL-small-text-ST-s-semibold-font-size);
  font-style: var(--XL-small-text-ST-s-semibold-font-style);
  font-weight: var(--XL-small-text-ST-s-semibold-font-weight);
  letter-spacing: var(--XL-small-text-ST-s-semibold-letter-spacing);
  line-height: var(--XL-small-text-ST-s-semibold-line-height);
  align-self: stretch;
  position: relative;
}

.real-estate-card .button-2 {
  align-self: stretch !important;
  width: 100% !important;
}

.real-estate-card .eng {
  flex: 1;
}

.real-estate-card .esp {
  white-space: nowrap;
  width: fit-content;
}

.real-estate-card .language-eng {
  color: #1a1a1c;
  letter-spacing: 0;
  font-family: Red Hat Display, Helvetica;
  font-size: 18px;
  font-weight: 400;
  line-height: 23.4px;
}

.real-estate-card .language-esp {
  color: var(--black);
  font-family: var(--XL-small-text-ST-l-reg-font-family);
  font-size: var(--XL-small-text-ST-l-reg-font-size);
  font-style: var(--XL-small-text-ST-l-reg-font-style);
  font-weight: var(--XL-small-text-ST-l-reg-font-weight);
  letter-spacing: var(--XL-small-text-ST-l-reg-letter-spacing);
  line-height: var(--XL-small-text-ST-l-reg-line-height);
}

.real-estate-card .language-0-eng {
  color: #1a1a1c;
  letter-spacing: 0;
  font-family: Red Hat Display, Helvetica;
  font-size: 18px;
  font-weight: 700;
  line-height: 23.4px;
}

.real-estate-card .language-0-esp {
  color: var(--black);
  font-family: var(--XL-small-text-ST-l-bold-font-family);
  font-size: var(--XL-small-text-ST-l-bold-font-size);
  font-style: var(--XL-small-text-ST-l-bold-font-style);
  font-weight: var(--XL-small-text-ST-l-bold-font-weight);
  letter-spacing: var(--XL-small-text-ST-l-bold-letter-spacing);
  line-height: var(--XL-small-text-ST-l-bold-line-height);
}

.team__name_content {
  border-top: 1px solid var(--dark-gray);
  flex-direction: column;
  align-items: center;
  display: flex;
}

.team__name_wrapper {
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-height: 100px;
  display: flex;
}

.team__name_title {
  color: var(--white);
  font-family: var(--XL-headline-HS-font-family);
  font-size: var(--XL-headline-HS-font-size);
  font-style: var(--XL-headline-HS-font-style);
  font-weight: var(--XL-headline-HS-font-weight);
  letter-spacing: var(--XL-headline-HS-letter-spacing);
  line-height: var(--XL-headline-HS-line-height);
}

.team__name_list {
  grid-gap: 40px;
  color: var(--white);
  grid-template-columns: repeat(5, 1fr);
  margin-top: 24px;
  margin-bottom: 24px;
  display: grid;
}

.member__photo {
  object-fit: cover;
  filter: grayscale();
  width: 256px;
  height: 342px;
  transition: filter .3s;
}

.member__photo:hover {
  filter: grayscale(0%);
}

.member__name-block {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.member__name {
  font-family: var(--XL-title-TXS-font-family);
  font-size: var(--XL-title-TXS-font-size);
  font-style: var(--XL-title-TXS-font-style);
  font-weight: var(--XL-title-TXS-font-weight);
  letter-spacing: var(--XL-title-TXS-letter-spacing);
  line-height: var(--XL-title-TXS-line-height);
  text-transform: uppercase;
  width: 200px;
}

.member__role {
  font-family: var(--XS-small-text-ST-l-medium-font-family);
  font-size: var(--XL-small-text-ST-l-bold-font-size);
  font-style: var(--XS-small-text-ST-l-medium-font-style);
  font-weight: var(--XS-small-text-ST-l-medium-font-weight);
  letter-spacing: var(--XS-small-text-ST-l-medium-letter-spacing);
  line-height: var(--XS-small-text-ST-l-medium-line-height);
}

.member__date {
  color: var(--gray);
  font-family: var(--XL-small-text-ST-l-med-font-family);
  font-size: var(--XL-small-text-ST-l-med-font-size);
  font-style: var(--XL-small-text-ST-l-med-font-style);
  font-weight: var(--XL-small-text-ST-l-med-font-weight);
  line-height: var(--XL-small-text-ST-l-med-line-height);
}

.linkedin_icon {
  margin-right: 3px;
}

@media (width <= 1024px) {
  .team__name_title {
    font-size: var(--XS-title-TL-font-size);
  }

  .addplus_icon {
    width: 30px;
    height: 30px;
  }

  .team__name_wrapper {
    min-height: 42px;
  }

  .team__name_list {
    grid-gap: 16px;
    color: var(--white);
    grid-template-columns: repeat(2, 1fr);
    margin-top: 24px;
    margin-bottom: 24px;
    display: grid;
  }

  .member__photo {
    width: 156px;
    height: 208px;
  }

  .member__name {
    font-size: var(--XS-small-text-ST-l-bold-font-size);
    width: 100%;
  }

  .member__role, .member__date {
    font-size: var(--XS-small-text-ST-l-medium-font-size);
  }

  .linkedin_icon {
    width: 16px;
    height: 16px;
  }
}

.member__link_no-cursor {
  cursor: default;
}

.button.success:hover, .menu-close_lg {
  cursor: pointer;
}

.success-message-overlay {
  z-index: 1000;
  background-color: #00000080;
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  inset: 0;
}

.success-message-container {
  background-color: #fff;
  border-radius: 5px;
  max-width: 90%;
  padding: 20px;
}

.success-message {
  background-color: #0000;
}

.success-message .success-message-1 {
  background-color: var(--white);
  border-radius: 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  width: 100%;
  max-width: 605px;
  height: 320px;
  margin-left: 1px;
  padding: 32px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.success-message .frame-386 {
  align-items: flex-end;
  gap: 32px;
}

.success-message .menu-close_lg {
  width: 24px;
  height: 24px;
  position: relative;
}

.success-message .frame-385 {
  align-items: center;
  gap: 32px;
}

.success-message .frame-1458 {
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.success-message .su-solicitud-se-ha-e {
  color: var(--black);
  text-align: center;
  align-self: stretch;
  margin-top: -1px;
  font-weight: 700;
  line-height: 28.8px;
  position: relative;
}

.success-message .warning-circle_-check {
  width: 32px;
  height: 32px;
  position: relative;
}

.success-message .frame-385-1 {
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 32px;
  width: 100%;
  display: flex;
  position: relative;
}

.success-message .button {
  background-color: var(--violet);
  border-radius: 8px;
  flex: 1;
  height: 70px;
  padding: 20px 0;
  position: relative;
  overflow: hidden;
}

.success-message .button-1 {
  color: var(--white);
  text-align: center;
  white-space: nowrap;
  width: fit-content;
  font-weight: 700;
  line-height: 21.6px;
  position: relative;
}

.success {
  justify-content: center;
  align-items: center;
  display: flex;
}

.success-message .frame {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  width: 100%;
  display: flex;
  position: relative;
}

:root {
  --black: #1a1a1c;
  --black-2: #000;
  --blue: #37a4e2;
  --dark-gray: #5f5f60;
  --gray: #a8a8a8;
  --green: #c5ef4b;
  --lavender: #cac2fa;
  --orange: #f5893b;
  --violet: #7a67f2;
  --violet-dark: #6956da;
  --white: #fff;
  --font-size-18px: 18px;
  --font-size-l: 16px;
  --font-size-m: 14px;
  --font-size-s: 12px;
  --font-size-xl: 20px;
  --font-size-xs: 10px;
  --font-size-xxl: 21px;
  --font-size-xxxl: 24px;
  --font-family-red_hat_display-bold: "Red Hat Display-Bold", Helvetica;
  --font-family-red_hat_display-medium: "Red Hat Display-Medium", Helvetica;
  --font-family-red_hat_display-regular: "Red Hat Display-Regular", Helvetica;
  --font-family-red_hat_display-semibold: "Red Hat Display-SemiBold", Helvetica;
  --font-family-syncopate: "Syncopate", Helvetica;
}

.xltitlets {
  font-family: var(--font-family-syncopate);
  font-size: var(--font-size-xxxl);
  letter-spacing: -2.4px;
  font-style: normal;
  font-weight: 700;
}

.buttonl {
  font-family: var(--font-family-syncopate);
  font-size: var(--font-size-18px);
  letter-spacing: -1.8px;
  font-style: normal;
  font-weight: 700;
}

.xstitletl {
  font-family: var(--font-family-syncopate);
  font-size: var(--font-size-m);
  letter-spacing: -1.4px;
  font-style: normal;
  font-weight: 700;
}

.buttonm {
  font-family: var(--font-family-syncopate);
  font-size: var(--font-size-l);
  letter-spacing: -1.6px;
  font-style: normal;
  font-weight: 700;
}

.logol {
  font-family: var(--font-family-syncopate);
  font-size: var(--font-size-xs);
  letter-spacing: -1px;
  font-style: normal;
  font-weight: 700;
}

.xsheadlinehl {
  font-family: var(--font-family-syncopate);
  font-size: var(--font-size-xxl);
  letter-spacing: -2.1px;
  font-style: normal;
  font-weight: 700;
}

.xsheadlinehm {
  font-family: var(--font-family-syncopate);
  font-size: var(--font-size-xl);
  letter-spacing: -2px;
  font-style: normal;
  font-weight: 700;
}

.xstitletm {
  font-family: var(--font-family-syncopate);
  font-size: var(--font-size-s);
  letter-spacing: -1.2px;
  font-style: normal;
  font-weight: 700;
}

.redhatdisplay-bold-eerie-black-12px {
  color: var(--black);
  font-family: var(--font-family-red_hat_display-bold);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 700;
}

.redhatdisplay-semi-bold-eerie-black-14px {
  color: var(--black);
  font-family: var(--font-family-red_hat_display-semibold);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 600;
}

.redhatdisplay-regular-normal-eerie-black-12px {
  color: var(--black);
  font-family: var(--font-family-red_hat_display-regular);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 400;
}

.redhatdisplay-medium-white-12px {
  color: var(--white);
  font-family: var(--font-family-red_hat_display-medium);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 500;
}

.redhatdisplay-medium-quick-silver-12px {
  color: var(--gray);
  font-family: var(--font-family-red_hat_display-medium);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 500;
}

.redhatdisplay-regular-normal-scorpion-16px {
  color: var(--dark-gray);
  font-family: var(--font-family-red_hat_display-regular);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 400;
}

.redhatdisplay-bold-white-14px {
  color: var(--white);
  font-family: var(--font-family-red_hat_display-bold);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 700;
}

.redhatdisplay-semi-bold-white-14px {
  color: var(--white);
  font-family: var(--font-family-red_hat_display-semibold);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 600;
}

.redhatdisplay-bold-quick-silver-14px {
  color: var(--gray);
  font-family: var(--font-family-red_hat_display-bold);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 700;
}

.redhatdisplay-regular-normal-scorpion-12px {
  color: var(--dark-gray);
  font-family: var(--font-family-red_hat_display-regular);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 400;
}

.redhatdisplay-bold-white-16px {
  color: var(--white);
  font-family: var(--font-family-red_hat_display-bold);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 700;
}

.redhatdisplay-medium-eerie-black-12px {
  color: var(--black);
  font-family: var(--font-family-red_hat_display-medium);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 500;
}

.redhatdisplay-bold-eerie-black-14px {
  color: var(--black);
  font-family: var(--font-family-red_hat_display-bold);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 700;
}

.redhatdisplay-semi-bold-white-16px {
  color: var(--white);
  font-family: var(--font-family-red_hat_display-semibold);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 600;
}

.redhatdisplay-semi-bold-quick-silver-12px {
  color: var(--gray);
  font-family: var(--font-family-red_hat_display-semibold);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 600;
}

.screen a {
  text-decoration: none;
  display: contents;
}

.valign-text-middle {
  flex-direction: column;
  justify-content: center;
  display: flex;
}

* {
  box-sizing: border-box;
}

.qu-aporta-rent {
  color: var(--white);
  font-family: var(--XS-body-BM-bold-font-family);
  font-size: var(--XS-body-BM-bold-font-size);
  font-style: var(--XS-body-BM-bold-font-style);
  font-weight: var(--XS-body-BM-bold-font-weight);
  letter-spacing: var(--XS-body-BM-bold-letter-spacing);
  line-height: var(--XS-body-BM-bold-line-height);
  white-space: nowrap;
  text-wrap: wrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.frame-108 {
  cursor: pointer;
  flex: none;
  justify-content: space-between;
  align-items: center;
  margin: 12px 0;
  display: flex;
  position: relative;
}

.rent-onchain-2 {
  color: var(--white);
  font-family: var(--XS-body-BM-reg-font-family);
  font-size: var(--XS-body-BM-reg-font-size);
  font-style: var(--XS-body-BM-reg-font-style);
  font-weight: var(--XS-body-BM-reg-font-weight);
  letter-spacing: var(--XS-body-BM-reg-letter-spacing);
  line-height: var(--XS-body-BM-reg-line-height);
  width: 328px;
  margin-bottom: 12px;
  position: relative;
}

.addplus_icon {
  width: 30px;
  height: 30px;
}

.div-16 {
  border-top: 1px solid var(--dark-gray);
  flex-direction: column;
  flex: none;
  display: flex;
  position: relative;
}

@media (width >= 1024px) {
  .faq-list {
    margin-top: 32px;
  }

  .frame-108 {
    margin: 32px 0;
  }

  .qu-aporta-rent {
    font-family: var(--XL-body-BL-bold-font-family);
    font-size: var(--XL-body-BL-bold-font-size);
    font-style: var(--XL-body-BL-bold-font-style);
    font-weight: var(--XL-body-BL-bold-font-weight);
    letter-spacing: var(--XL-body-BL-bold-letter-spacing);
    line-height: var(--XL-body-BL-bold-line-height);
  }

  .rent-onchain-2 {
    font-family: var(--XL-body-BM-reg-font-family);
    font-size: var(--XL-body-BM-reg-font-size);
    font-style: var(--XL-body-BM-reg-font-style);
    font-weight: var(--XL-body-BM-reg-font-weight);
    letter-spacing: var(--XL-body-BM-reg-letter-spacing);
    line-height: var(--XL-body-BM-reg-line-height);
    width: auto;
    margin-bottom: 24px;
  }

  .addplus_icon {
    width: 52px;
    height: 52px;
  }
}

@media screen and (width >= 1024px) and (width <= 1100px) {
  body {
    zoom: .68;
  }
}

@media screen and (width >= 1101px) and (width <= 1200px) {
  body {
    zoom: .74;
  }
}

@media screen and (width >= 1201px) and (width <= 1300px) {
  body {
    zoom: .8;
  }
}

@media screen and (width >= 1300px) and (width <= 1400px) {
  body {
    zoom: .87;
  }
}

@media screen and (width >= 1401px) and (width <= 1470px) {
  body {
    zoom: .94;
  }
}

.landing-page-XL {
  background-color: var(--black);
  align-items: center;
  gap: var(--spacings-spacing-betweenblock-desktop);
  flex-direction: column;
  justify-content: center;
  width: 100%;
  display: flex;
  position: relative;
}

.landing-page-XL .frame-36 {
  align-items: center;
  gap: var(--spacings-spacing-xxxl);
  flex-direction: column;
  width: 100%;
  height: 1080px;
  display: inline-flex;
  position: relative;
}

.landing-page-XL .header-timesharing-rent {
  flex: none !important;
}

.landing-page-XL .frame-37 {
  align-items: center;
  gap: var(--spacings-spacing-xxxl);
  flex-direction: column;
  width: 1440px;
  height: 865px;
  display: flex;
  position: relative;
}

.landing-page-XL .frame-38 {
  flex: none;
  justify-content: space-between;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  display: flex;
  position: relative;
}

.landing-page-XL .text-wrapper-17 {
  color: var(--white);
  font-family: var(--XL-headline-HM-font-family);
  font-size: var(--XL-headline-HM-font-size);
  font-style: var(--XL-headline-HM-font-style);
  font-weight: var(--XL-headline-HM-font-weight);
  letter-spacing: var(--XL-headline-HM-letter-spacing);
  line-height: var(--XL-headline-HM-line-height);
  width: 998px;
  margin-top: -1px;
  position: relative;
}

.landing-page-XL .frame-39 {
  padding: var(--spacings-spacing-md);
  background-color: #fff;
  border-radius: 8px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 418px;
  height: 176px;
  display: flex;
  position: relative;
}

.landing-page-XL .DISFRUTA-LA-VIDA-EN {
  color: #0000;
  font-family: var(--XL-caps-CS-bold-font-family);
  font-size: var(--XL-caps-CS-bold-font-size);
  font-style: var(--XL-caps-CS-bold-font-style);
  font-weight: var(--XL-caps-CS-bold-font-weight);
  letter-spacing: var(--XL-caps-CS-bold-letter-spacing);
  line-height: var(--XL-caps-CS-bold-line-height);
  align-self: stretch;
  margin-top: -1px;
  position: relative;
}

.landing-page-XL .text-wrapper-18 {
  color: #1a1a1c;
  font-family: var(--XL-caps-CS-bold-font-family);
  font-size: var(--XL-caps-CS-bold-font-size);
  font-style: var(--XL-caps-CS-bold-font-style);
  font-weight: var(--XL-caps-CS-bold-font-weight);
  letter-spacing: var(--XL-caps-CS-bold-letter-spacing);
  line-height: var(--XL-caps-CS-bold-line-height);
}

.landing-page-XL .text-wrapper-19 {
  color: #7a67f2;
  font-family: var(--XL-caps-CS-bold-font-family);
  font-size: var(--XL-caps-CS-bold-font-size);
  font-style: var(--XL-caps-CS-bold-font-style);
  font-weight: var(--XL-caps-CS-bold-font-weight);
  letter-spacing: var(--XL-caps-CS-bold-letter-spacing);
  line-height: var(--XL-caps-CS-bold-line-height);
}

.landing-page-XL .design-component-instance-node {
  height: unset !important;
  cursor: pointer !important;
  flex: none !important;
  align-self: stretch !important;
  width: 100% !important;
}

.landing-page-XL .button-4 {
  width: unset !important;
  flex: 1 !important;
  margin-top: -3px !important;
}

.landing-page-XL .player-wrapper {
  background-image: url("unsplash-gf7hhmic3vo.4b3dc41d.png");
  background-size: 100% 100%;
  width: 1440px;
  height: 619px;
  position: relative;
}

.landing-page-XL .player-instance {
  top: 260px !important;
  left: 670px !important;
}

.landing-page-XL .frame-40 {
  align-items: flex-start;
  gap: var(--spacings-spacing-betweenblock-desktop);
  flex-direction: column;
  flex: none;
  width: 1440px;
  display: flex;
  position: relative;
}

.landing-page-XL .frame-41 {
  align-self: stretch;
  align-items: center;
  gap: var(--spacings-spacing-xxl);
  flex-direction: column;
  flex: none;
  justify-content: center;
  width: 100%;
  display: flex;
  position: relative;
}

.landing-page-XL .frame-42 {
  flex: none;
  align-items: flex-start;
  gap: 200px;
  width: 1440px;
  display: flex;
  position: relative;
}

.landing-page-XL .text-wrapper-20 {
  color: var(--white);
  font-family: var(--XL-headline-HS-font-family);
  font-size: var(--XL-headline-HS-font-size);
  font-style: var(--XL-headline-HS-font-style);
  font-weight: var(--XL-headline-HS-font-weight);
  letter-spacing: var(--XL-headline-HS-letter-spacing);
  line-height: var(--XL-headline-HS-line-height);
  width: 418px;
  margin-top: -1px;
  position: relative;
}

.team-block-list {
  margin-top: var(--spacings-spacing-xl);
}

@media (width <= 1024px) {
  .team-block-list {
    margin-top: var(--spacings-spacing-md);
  }
}

.landing-page-XL .frame-43 {
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
  width: 822px;
  display: flex;
  position: relative;
}

.landing-page-XL .frame-44 {
  align-self: stretch;
  align-items: flex-start;
  gap: var(--spacings-spacing-lg);
  flex: none;
  width: 100%;
  display: flex;
  position: relative;
}

.landing-page-XL .text-wrapper-21 {
  color: var(--white);
  font-family: var(--XL-body-BM-semibold-font-family);
  font-size: var(--XL-body-BM-semibold-font-size);
  font-style: var(--XL-body-BM-semibold-font-style);
  font-weight: var(--XL-body-BM-semibold-font-weight);
  letter-spacing: var(--XL-body-BM-semibold-letter-spacing);
  line-height: var(--XL-body-BM-semibold-line-height);
  width: 380px;
  margin-top: -1px;
  position: relative;
}

.landing-page-XL .text-wrapper-22 {
  color: var(--white);
  font-family: var(--XL-body-BM-semibold-font-family);
  font-size: var(--XL-body-BM-semibold-font-size);
  font-style: var(--XL-body-BM-semibold-font-style);
  font-weight: var(--XL-body-BM-semibold-font-weight);
  letter-spacing: var(--XL-body-BM-semibold-letter-spacing);
  line-height: var(--XL-body-BM-semibold-line-height);
  flex: 1;
  margin-top: -1px;
  position: relative;
}

.landing-page-XL .frame-45 {
  align-self: stretch;
  align-items: center;
  gap: var(--spacings-spacing-lg);
  flex: none;
  justify-content: center;
  width: 100%;
  display: flex;
  position: relative;
}

.landing-page-XL img {
  width: 100%;
}

.landing-page-XL .icon-instance-node {
  width: 60px !important;
  height: 60px !important;
  position: relative !important;
}

.landing-page-XL .unsplash {
  object-fit: cover;
  flex: 1;
  height: 750px;
  position: relative;
}

.landing-page-XL .frame-46 {
  align-items: flex-end;
  gap: var(--spacings-spacing-xxl);
  flex-direction: column;
  flex: none;
  display: inline-flex;
  position: relative;
}

.landing-page-XL .frame-47 {
  flex: none;
  align-items: flex-start;
  gap: 200px;
  display: inline-flex;
  position: relative;
}

.landing-page-XL .frame-48 {
  align-self: stretch;
  align-items: flex-start;
  gap: var(--spacings-spacing-xl);
  flex-direction: column;
  width: 822px;
  display: flex;
  position: relative;
}

.landing-page-XL .frame-49 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 24px;
  width: 100%;
  display: flex;
  position: relative;
}

.landing-page-XL .text-wrapper-23 {
  color: var(--white);
  font-family: var(--XL-body-BM-bold-font-family);
  font-size: var(--XL-body-BM-bold-font-size);
  font-style: var(--XL-body-BM-bold-font-style);
  font-weight: var(--XL-body-BM-bold-font-weight);
  letter-spacing: var(--XL-body-BM-bold-letter-spacing);
  line-height: var(--XL-body-BM-bold-line-height);
  align-self: stretch;
  margin-top: -1px;
  position: relative;
}

.landing-page-XL .en-rent-onchain-tu {
  color: #0000;
  font-family: var(--XL-body-BM-semibold-font-family);
  font-size: var(--XL-body-BM-semibold-font-size);
  font-style: var(--XL-body-BM-semibold-font-style);
  font-weight: var(--XL-body-BM-semibold-font-weight);
  letter-spacing: var(--XL-body-BM-semibold-letter-spacing);
  line-height: var(--XL-body-BM-semibold-line-height);
  align-self: stretch;
  position: relative;
}

.landing-page-XL .text-wrapper-24 {
  color: #fff;
  font-family: var(--XL-body-BM-semibold-font-family);
  font-size: var(--XL-body-BM-semibold-font-size);
  font-style: var(--XL-body-BM-semibold-font-style);
  font-weight: var(--XL-body-BM-semibold-font-weight);
  letter-spacing: var(--XL-body-BM-semibold-letter-spacing);
  line-height: var(--XL-body-BM-semibold-line-height);
}

.landing-page-XL .text-wrapper-25 {
  color: #7f6eed;
  font-family: var(--XL-body-BM-semibold-font-family);
  font-size: var(--XL-body-BM-semibold-font-size);
  font-style: var(--XL-body-BM-semibold-font-style);
  font-weight: var(--XL-body-BM-semibold-font-weight);
  letter-spacing: var(--XL-body-BM-semibold-letter-spacing);
  line-height: var(--XL-body-BM-semibold-line-height);
}

.landing-page-XL .frame-50 {
  background-color: var(--white);
  align-items: flex-start;
  gap: var(--spacings-spacing-lg);
  padding: var(--spacings-spacing-lg);
  border-radius: 12px;
  flex-direction: column;
  flex: none;
  justify-content: center;
  width: 822px;
  display: flex;
  position: relative;
}

.landing-page-XL .frame-51 {
  align-self: stretch;
  align-items: flex-start;
  gap: var(--spacings-spacing-md);
  flex-direction: column;
  flex: none;
  justify-content: center;
  width: 100%;
  display: flex;
  position: relative;
}

.landing-page-XL .frame-52 {
  align-self: stretch;
  align-items: center;
  gap: var(--spacings-spacing-md);
  flex: none;
  width: 100%;
  display: flex;
  position: relative;
}

.landing-page-XL .design-component-instance-node-2 {
  width: unset !important;
  flex: 1 !important;
}

.landing-page-XL .frame-53 {
  align-items: center;
  gap: var(--spacings-spacing-sm);
  flex: none;
  display: inline-flex;
  position: relative;
}

.landing-page-XL .icon-instance-node-2 {
  width: 32px !important;
  height: 32px !important;
  position: relative !important;
}

.landing-page-XL .frame-54 {
  align-items: flex-start;
  gap: var(--spacings-spacing-sm);
  flex: none;
  display: inline-flex;
  position: relative;
}

.landing-page-XL .text-wrapper-26 {
  color: var(--black);
  font-family: var(--XL-small-text-ST-l-bold-font-family);
  font-size: var(--XL-small-text-ST-l-bold-font-size);
  font-style: var(--XL-small-text-ST-l-bold-font-style);
  font-weight: var(--XL-small-text-ST-l-bold-font-weight);
  letter-spacing: var(--XL-small-text-ST-l-bold-letter-spacing);
  line-height: var(--XL-small-text-ST-l-bold-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.landing-page-XL .text-wrapper-27 {
  color: var(--black);
  font-family: var(--XL-small-text-ST-l-med-font-family);
  font-size: var(--XL-small-text-ST-l-med-font-size);
  font-style: var(--XL-small-text-ST-l-med-font-style);
  font-weight: var(--XL-small-text-ST-l-med-font-weight);
  letter-spacing: var(--XL-small-text-ST-l-med-letter-spacing);
  line-height: var(--XL-small-text-ST-l-med-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.landing-page-XL .button-5 {
  align-self: stretch !important;
  width: 100% !important;
}

.landing-page-XL .button-6 {
  width: unset !important;
  flex: 1 !important;
}

.landing-page-XL .frame-55 {
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 200px;
  width: 100%;
  display: flex;
  position: relative;
}

.landing-page-XL .card {
  background-color: var(--white);
  border-radius: 12px;
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  gap: 10px;
  padding: 38px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.landing-page-XL .frame-56 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 16px;
  width: 100%;
  display: flex;
  position: relative;
}

.landing-page-XL .frame-57 {
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 1px;
  width: 100%;
  display: flex;
  position: relative;
}

.landing-page-XL .text-wrapper-28 {
  color: var(--black);
  font-family: var(--XL-body-BL-semibold-font-family);
  font-size: var(--XL-body-BL-semibold-font-size);
  font-style: var(--XL-body-BL-semibold-font-style);
  font-weight: var(--XL-body-BL-semibold-font-weight);
  letter-spacing: var(--XL-body-BL-semibold-letter-spacing);
  line-height: var(--XL-body-BL-semibold-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.landing-page-XL .text-wrapper-29 {
  color: var(--black);
  font-family: var(--XL-body-BL-semibold-font-family);
  font-size: var(--XL-body-BL-semibold-font-size);
  font-style: var(--XL-body-BL-semibold-font-style);
  font-weight: var(--XL-body-BL-semibold-font-weight);
  letter-spacing: var(--XL-body-BL-semibold-letter-spacing);
  line-height: var(--XL-body-BL-semibold-line-height);
  flex: 1;
  margin-top: -1px;
  position: relative;
}

.landing-page-XL .div-13 {
  color: #0000;
  font-family: var(--XL-body-BL-semibold-font-family);
  font-size: var(--XL-body-BL-semibold-font-size);
  font-style: var(--XL-body-BL-semibold-font-style);
  font-weight: var(--XL-body-BL-semibold-font-weight);
  letter-spacing: var(--XL-body-BL-semibold-letter-spacing);
  line-height: var(--XL-body-BL-semibold-line-height);
  flex: 1;
  margin-top: -1px;
  position: relative;
}

.landing-page-XL .text-wrapper-30 {
  color: #1a1a1c;
  font-family: var(--XL-body-BL-semibold-font-family);
  font-size: var(--XL-body-BL-semibold-font-size);
  font-style: var(--XL-body-BL-semibold-font-style);
  font-weight: var(--XL-body-BL-semibold-font-weight);
  letter-spacing: var(--XL-body-BL-semibold-letter-spacing);
  line-height: var(--XL-body-BL-semibold-line-height);
}

.landing-page-XL .text-wrapper-31 {
  color: #7a67f2;
  font-family: var(--XL-body-BL-semibold-font-family);
  font-size: var(--XL-body-BL-semibold-font-size);
  font-style: var(--XL-body-BL-semibold-font-style);
  font-weight: var(--XL-body-BL-semibold-font-weight);
  letter-spacing: var(--XL-body-BL-semibold-letter-spacing);
  line-height: var(--XL-body-BL-semibold-line-height);
}

.landing-page-XL .frame-58 {
  align-self: stretch;
  align-items: flex-start;
  gap: var(--spacings-spacing-lg);
  flex-direction: column;
  flex: none;
  width: 100%;
  display: flex;
  position: relative;
}

.landing-page-XL .frame-59 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 32px;
  width: 100%;
  display: flex;
  position: relative;
}

.landing-page-XL .frame-60 {
  align-self: stretch;
  align-items: flex-start;
  gap: var(--spacings-spacing-xxl);
  flex-direction: column;
  flex: none;
  width: 100%;
  display: flex;
  position: relative;
}

.landing-page-XL .disponibilidad {
  color: var(--gray);
  font-family: var(--XL-title-TL-font-family);
  font-size: var(--XL-title-TL-font-size);
  font-style: var(--XL-title-TL-font-style);
  font-weight: var(--XL-title-TL-font-weight);
  letter-spacing: var(--XL-title-TL-letter-spacing);
  line-height: var(--XL-title-TL-line-height);
  width: 418px;
  margin-top: -1px;
  position: relative;
}

.landing-page-XL .real-estate-card-landing-desktop {
  background-image: url("rectangle-260.6f9ef5d1.png") !important;
}

.landing-page-XL .real-estate-card-instance {
  color: #1a1a1c !important;
}

.landing-page-XL .real-estate-card-landing-desktop-instance {
  width: 114px !important;
}

.landing-page-XL .real-estate-card-2 {
  width: 112px !important;
}

.landing-page-XL .real-estate-card-3 {
  background-image: url("rectangle-260-1.6c5dbfa3.png") !important;
}

.landing-page-XL .real-estate-card-4 {
  background-image: url("rectangle-260-2.f3e5fb0f.png") !important;
}

.landing-page-XL .real-estate-card-5 {
  width: 109px !important;
}

.landing-page-XL .real-estate-card-6 {
  width: 107px !important;
}

.landing-page-XL .real-estate-card-7 {
  background-image: url("rectangle-260-3.fb63db59.png") !important;
}

.landing-page-XL .real-estate-card-8 {
  width: 121px !important;
}

.landing-page-XL .real-estate-card-9 {
  width: 119px !important;
}

.landing-page-XL .real-estate-card-10 {
  background-image: url("rectangle-260-4.10bdef46.png") !important;
}

.landing-page-XL .real-estate-card-11 {
  width: 122px !important;
}

.landing-page-XL .real-estate-card-12 {
  width: 120px !important;
}

.landing-page-XL .real-estate-card-13 {
  background-image: url("rectangle-260-5.7d4e4e7b.png") !important;
}

.landing-page-XL .frame-61 {
  background-color: var(--white);
  align-items: flex-start;
  gap: var(--spacings-spacing-lg);
  padding: var(--spacings-spacing-lg);
  border-radius: 12px;
  flex-direction: column;
  flex: none;
  display: inline-flex;
  position: relative;
}

.landing-page-XL .frame-62 {
  align-items: center;
  gap: var(--spacings-spacing-lg);
  width: 1392px;
  height: 70px;
  display: flex;
  position: relative;
}

.landing-page-XL .frame-63 {
  align-items: center;
  gap: var(--spacings-spacing-md);
  flex: 1;
  display: flex;
  position: relative;
}

.landing-page-XL .button-7 {
  width: 260px !important;
}

.landing-page-XL .frame-64 {
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  gap: 40px;
  height: 906px;
  display: flex;
  position: relative;
}

.landing-page-XL .unsplash-loiwhnwrq {
  flex: 1;
  align-self: stretch;
  width: 100%;
  position: relative;
}

.landing-page-XL .frame-65 {
  align-self: stretch;
  align-items: flex-start;
  gap: var(--spacings-spacing-lg);
  flex-wrap: wrap;
  width: 822px;
  display: flex;
  position: relative;
}

.landing-page-XL .card-mini-desktop-instance {
  align-self: stretch;
  background-color: var(--violet) !important;
  gap: var(--spacings-spacing-lg) !important;
  padding: var(--spacings-spacing-lg) !important;
  width: 380px !important;
}

.landing-page-XL .card-mini-desktop-2 {
  gap: var(--spacings-spacing-md) !important;
}

.landing-page-XL .card-mini-desktop-3 {
  color: var(--white) !important;
}

.landing-page-XL .card-mini-desktop-4 {
  align-self: stretch;
  background-color: var(--white) !important;
  gap: var(--spacings-spacing-lg) !important;
  padding: var(--spacings-spacing-lg) !important;
  width: 418px !important;
}

.landing-page-XL .card-mini-desktop-5 {
  white-space: unset !important;
  width: unset !important;
  flex: 1 !important;
}

.landing-page-XL .card-mini-desktop-6 {
  align-self: stretch;
  background-color: var(--white) !important;
  gap: var(--spacings-spacing-lg) !important;
  padding: var(--spacings-spacing-lg) !important;
  width: 380px !important;
}

.landing-page-XL .card-mini-desktop-7 {
  align-self: stretch;
  background-color: var(--blue) !important;
  gap: var(--spacings-spacing-lg) !important;
  padding: var(--spacings-spacing-lg) !important;
  width: 418px !important;
}

.landing-page-XL .card-mini-desktop-8 {
  color: var(--white) !important;
  white-space: unset !important;
  width: unset !important;
  flex: 1 !important;
}

.landing-page-XL .card-mini-desktop-9 {
  align-self: stretch;
  background-color: var(--orange) !important;
  gap: var(--spacings-spacing-lg) !important;
  padding: var(--spacings-spacing-lg) !important;
  width: 380px !important;
}

.landing-page-XL .card-mini-desktop-10 {
  align-self: stretch;
  background-color: var(--white) !important;
  gap: var(--spacings-spacing-lg) !important;
  padding: var(--spacings-spacing-lg) !important;
  width: 418px !important;
}

.landing-page-XL .card-mini-desktop-11 {
  align-self: stretch;
  gap: var(--spacings-spacing-lg) !important;
  padding: var(--spacings-spacing-lg) !important;
  width: 418px !important;
}

.landing-page-XL .frame-66 {
  align-items: flex-end;
  gap: var(--spacings-spacing-none);
  flex-direction: column;
  flex: none;
  display: inline-flex;
  position: relative;
}

.landing-page-XL .frame-67 {
  flex: none;
  align-items: flex-end;
  gap: 200px;
  width: 1440px;
  display: flex;
  position: relative;
}

.landing-page-XL .player-instance-wrapper {
  width: 100%;
}

.player-instance-wrapper iframe {
  border-radius: 18px;
  width: 100%;
  height: 720px;
}

.landing-page-XL .player-2 {
  top: 310px !important;
  left: 670px !important;
}

.landing-page-XL .frame-68 {
  flex-wrap: wrap;
  flex: none;
  align-items: flex-start;
  gap: 24px;
  width: 1440px;
  display: flex;
  position: relative;
}

.landing-page-XL .rectangle-4 {
  object-fit: cover;
  width: 464px;
  height: 268px;
  position: relative;
}

.landing-page-XL .card-mini-desktop-12 {
  background-color: var(--white) !important;
  gap: var(--spacings-spacing-lg) !important;
  overflow: unset !important;
  padding: var(--spacings-spacing-lg) !important;
  width: 464px !important;
  height: 268px !important;
}

.landing-page-XL .card-mini-desktop-13 {
  background-color: var(--violet) !important;
  gap: var(--spacings-spacing-lg) !important;
  overflow: unset !important;
  padding: var(--spacings-spacing-lg) !important;
  width: 464px !important;
  height: 268px !important;
}

.landing-page-XL .card-mini-desktop-14 {
  align-self: stretch;
  background-color: var(--violet) !important;
  gap: var(--spacings-spacing-lg) !important;
  overflow: unset !important;
  padding: var(--spacings-spacing-lg) !important;
  width: 464px !important;
}

.landing-page-XL .card-mini-desktop-15 {
  background-color: var(--white) !important;
  gap: var(--spacings-spacing-lg) !important;
  overflow: unset !important;
  padding: var(--spacings-spacing-lg) !important;
  width: 464px !important;
}

.landing-page-XL .frame-wrapper-2 {
  width: 100%;
}

.landing-page-XL .frame-69 {
  cursor: pointer;
  flex: none;
  justify-content: space-between;
  align-items: center;
  width: 418px;
  display: flex;
  position: relative;
}

.landing-page-XL .arrow-chevron-right {
  width: 24px !important;
  height: 24px !important;
  margin-left: -24px !important;
  position: relative !important;
}

.arrow-rotate {
  transform: rotate(90deg);
}

.landing-page-XL .frame-70 {
  background-color: var(--white);
  align-items: flex-start;
  gap: var(--spacings-spacing-lg);
  padding: var(--spacings-spacing-lg);
  border-radius: 12px;
  flex-direction: column;
  width: 822px;
  display: flex;
  position: relative;
}

.landing-page-XL .frame-71 {
  align-self: stretch;
  align-items: flex-start;
  gap: var(--spacings-spacing-md);
  flex-direction: column;
  flex: none;
  width: 100%;
  display: flex;
  position: relative;
}

.landing-page-XL .text-wrapper-32 {
  color: var(--black);
  font-family: var(--XL-body-BL-bold-font-family);
  font-size: var(--XL-body-BL-bold-font-size);
  font-style: var(--XL-body-BL-bold-font-style);
  font-weight: var(--XL-body-BL-bold-font-weight);
  letter-spacing: var(--XL-body-BL-bold-letter-spacing);
  line-height: var(--XL-body-BL-bold-line-height);
  align-self: stretch;
  margin-top: -1px;
  position: relative;
}

.landing-page-XL .timer-instance {
  background-color: var(--violet-dark) !important;
  flex: none !important;
  align-self: stretch !important;
  width: 100% !important;
}

.landing-page-XL .timer-2 {
  color: #fff !important;
}

.landing-page-XL .timer-3 {
  color: #cac2fa !important;
}

.landing-page-XL .timer-4 {
  background-color: var(--lavender) !important;
}

.landing-page-XL .frame-72 {
  align-self: stretch;
  align-items: flex-start;
  gap: var(--spacings-spacing-sm);
  flex-direction: column;
  flex: none;
  width: 100%;
  display: flex;
  position: relative;
}

.landing-page-XL .text-wrapper-33 {
  color: var(--black);
  font-family: var(--XL-body-BM-bold-font-family);
  font-size: var(--XL-body-BM-bold-font-size);
  font-style: var(--XL-body-BM-bold-font-style);
  font-weight: var(--XL-body-BM-bold-font-weight);
  letter-spacing: var(--XL-body-BM-bold-letter-spacing);
  line-height: var(--XL-body-BM-bold-line-height);
  align-self: stretch;
  margin-top: -1px;
  position: relative;
}

.landing-page-XL .text-wrapper-34 {
  color: var(--black);
  font-family: var(--XL-body-BM-semibold-font-family);
  font-size: var(--XL-body-BM-semibold-font-size);
  font-style: var(--XL-body-BM-semibold-font-style);
  font-weight: var(--XL-body-BM-semibold-font-weight);
  letter-spacing: var(--XL-body-BM-semibold-letter-spacing);
  line-height: var(--XL-body-BM-semibold-line-height);
  align-self: stretch;
  position: relative;
}

.landing-page-XL .input-instance {
  flex: none !important;
  align-self: stretch !important;
  width: 100% !important;
}

.landing-page-XL .text-wrapper-35 {
  color: var(--violet-dark);
  font-family: var(--XL-body-BS-semibold-font-family);
  font-size: var(--XL-body-BS-semibold-font-size);
  font-style: var(--XL-body-BS-semibold-font-style);
  font-weight: var(--XL-body-BS-semibold-font-weight);
  letter-spacing: var(--XL-body-BS-semibold-letter-spacing);
  line-height: var(--XL-body-BS-semibold-line-height);
  align-self: stretch;
  position: relative;
}

.fistForm {
  justify-content: flex-end;
  width: 100%;
  display: flex;
}

.video-wrapper {
  border-radius: 18px !important;
  width: 100% !important;
  height: 620px !important;
}

.alice-carousel .animated {
  animation-fill-mode: both;
}

.alice-carousel .animated-out {
  z-index: 1;
}

.alice-carousel .fadeOut {
  animation-name: fadeOut;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    visibility: hidden;
  }
}

.alice-carousel {
  direction: ltr;
  width: 100%;
  margin: auto;
  position: relative;
}

.alice-carousel__wrapper {
  box-sizing: border-box;
  width: 100%;
  height: auto;
  position: relative;
  overflow: hidden;
}

.alice-carousel__stage {
  box-sizing: border-box;
  white-space: nowrap;
  transform-style: flat;
  backface-visibility: hidden;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  position: relative;
}

.alice-carousel__stage-item {
  box-sizing: border-box;
  vertical-align: top;
  white-space: normal;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  line-height: 0;
  display: inline-block;
  position: relative;
}

.alice-carousel__stage-item * {
  line-height: initial;
}

.alice-carousel__stage-item.__hidden {
  opacity: 0;
  overflow: hidden;
}

.alice-carousel__prev-btn, .alice-carousel__next-btn {
  box-sizing: border-box;
  width: 50%;
  padding: 10px 5px;
  display: inline-block;
}

.alice-carousel__prev-btn [data-area]:after, .alice-carousel__next-btn [data-area]:after {
  content: attr(data-area);
  text-transform: capitalize;
  position: relative;
}

.alice-carousel__prev-btn {
  text-align: right;
}

.alice-carousel__prev-btn-item, .alice-carousel__next-btn-item {
  cursor: pointer;
  color: #465798;
  margin: 0;
  padding: 5px;
  display: inline-block;
}

.alice-carousel__prev-btn-item:hover, .alice-carousel__next-btn-item:hover {
  color: #8b0000;
}

.alice-carousel__prev-btn-item.__inactive, .alice-carousel__next-btn-item.__inactive {
  opacity: .4;
  pointer-events: none;
}

.alice-carousel__play-btn {
  display: inline-block;
  position: absolute;
  top: 30px;
  left: 20px;
}

.alice-carousel__play-btn:hover {
  cursor: pointer;
}

.alice-carousel__play-btn-wrapper {
  background-color: #fff;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  padding: 10px;
  position: relative;
}

.alice-carousel__play-btn-item {
  cursor: pointer;
  background: none;
  border: 0;
  outline: none;
  width: 32px;
  height: 32px;
  position: absolute;
}

.alice-carousel__play-btn-item:before, .alice-carousel__play-btn-item:after {
  pointer-events: none;
  content: "";
  border: 8px solid #0000;
  border-left: 15px solid #465798;
  border-right-width: 0;
  width: 0;
  height: 0;
  transition: all .4s linear;
  display: block;
  position: absolute;
}

.alice-carousel__play-btn-item:before {
  height: 14px;
  left: 5px;
}

.alice-carousel__play-btn-item:after {
  top: 7px;
  left: 18px;
}

.alice-carousel__play-btn-item.__pause:before, .alice-carousel__play-btn-item.__pause:after {
  border-width: 0 0 0 10px;
  height: 30px;
}

.alice-carousel__play-btn-item.__pause:after {
  top: 0;
  left: 18px;
}

.alice-carousel__dots {
  text-align: center;
  margin: 30px 3px 5px;
  padding: 0;
  list-style: none;
}

.alice-carousel__dots > li {
  display: inline-block;
}

.alice-carousel__dots-item:not(.__custom) {
  cursor: pointer;
  background-color: #e0e4fb;
  border-radius: 50%;
  width: 8px;
  height: 8px;
}

.alice-carousel__dots-item:not(.__custom):not(:last-child) {
  margin-right: 20px;
}

.alice-carousel__dots-item:not(.__custom):hover, .alice-carousel__dots-item:not(.__custom).__active {
  background-color: #6e7ebc;
}

.alice-carousel__slide-info {
  color: #465798;
  background-color: #e0e4fb99;
  border-radius: 5px;
  padding: 5px 10px;
  display: inline-block;
  position: absolute;
  top: 20px;
  right: 20px;
}

.alice-carousel__slide-info-item {
  vertical-align: middle;
  line-height: 0;
}

@media (width <= 1023px) {
  .carousel-wrapper {
    width: 100%;
    height: 310px;
    position: relative;
    overflow: hidden;
  }

  .alice-carousel__dots {
    align-items: center;
    display: flex;
    position: absolute;
    bottom: -32px;
    left: 45%;
    transform: translateX(-50%);
  }

  .alice-carousel__dots-item:not(:last-child) {
    margin-right: 10px;
  }

  .alice-carousel__stage img {
    width: 328px !important;
  }
}

@media (width >= 1024px) {
  .alice-carousel {
    justify-content: space-between;
    display: flex;
  }

  .alice-carousel__prev-btn {
    cursor: pointer;
    width: 0;
    padding: 8px;
    position: absolute;
    top: 50%;
    left: -6.5%;
    transform: translateY(-50%);
  }

  .arrow-chevron-left-1, .arrow-chevron-right-1 {
    cursor: pointer;
  }

  .alice-carousel__next-btn {
    width: 0;
    padding: 8px;
    position: absolute;
    top: 50%;
    right: -2%;
    transform: translateY(-50%);
  }

  .alice-carousel__stage img, .alice-carousel__stage-item {
    width: 1272px;
    height: 750px;
  }

  .carousel-wrapper {
    align-items: center;
    width: 1272px;
    display: flex;
  }

  .alice-carousel__wrapper {
    width: 1440px;
    height: 750px;
  }

  .icon-instance-node {
    width: 60px !important;
    height: 60px !important;
    position: relative !important;
  }
}

.diamond-2 {
  width: 24px !important;
  height: 24px !important;
}

.environment-sun, .globe, .interface-chart-line {
  width: 24px;
  height: 24px;
  position: absolute;
  top: 0;
  left: 0;
}

.interface-heart-01-2 {
  width: 24px !important;
  height: 24px !important;
}

.navigation-building, .smiley-happy {
  width: 24px;
  height: 24px;
  position: absolute;
  top: 0;
  left: 0;
}

.trophy {
  width: 18px;
  height: 18px;
  position: absolute;
  top: 0;
  left: 0;
}

.umbrella-2 {
  width: 24px !important;
  height: 24px !important;
}

.warning-shield-check {
  width: 24px;
  height: 24px;
}

@media screen and (width >= 374px) and (width <= 423px) {
  body {
    zoom: 1.02;
  }
}

@media screen and (width >= 424px) and (width <= 473px) {
  body {
    zoom: 1.15;
  }
}

@media screen and (width >= 474px) and (width <= 523px) {
  body {
    zoom: 1.3;
  }
}

@media screen and (width >= 524px) and (width <= 573px) {
  body {
    zoom: 1.4;
  }
}

@media screen and (width >= 574px) and (width <= 623px) {
  body {
    zoom: 1.5;
  }
}

@media screen and (width >= 624px) and (width <= 673px) {
  body {
    zoom: 1.6;
  }
}

@media screen and (width >= 674px) and (width <= 723px) {
  body {
    zoom: 1.7;
  }
}

@media screen and (width >= 724px) and (width <= 773px) {
  body {
    zoom: 1.9;
  }
}

@media screen and (width >= 774px) and (width <= 823px) {
  body {
    zoom: 2.1;
  }
}

@media screen and (width >= 824px) and (width <= 873px) {
  body {
    zoom: 2.2;
  }
}

@media screen and (width >= 874px) and (width <= 923px) {
  body {
    zoom: 2.4;
  }
}

@media screen and (width >= 924px) and (width <= 973px) {
  body {
    zoom: 2.5;
  }
}

@media screen and (width >= 974px) and (width <= 1024px) {
  body {
    zoom: 2.6;
  }
}

.invisible {
  display: none !important;
}

a {
  cursor: pointer;
}

.landing-page-mobile {
  background-color: var(--black);
  align-items: center;
  gap: var(--spacings-spacing-lg);
  flex-direction: column;
  display: flex;
  position: relative;
}

.landing-page-mobile .menu-mobile {
  background-color: var(--black);
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: var(--dark-gray);
  flex: none;
  justify-content: space-between;
  align-items: center;
  width: 360px;
  padding: 13px 16px;
  display: flex;
  position: relative;
}

.landing-page-mobile .design-component-instance-node-3 {
  flex: none !important;
}

.landing-page-mobile .burger {
  width: 32px;
  height: 22px;
  margin-right: -1px;
  position: relative;
}

.landing-page-mobile .frame-73 {
  align-items: center;
  gap: var(--spacings-spacing-between-blocks-mobile);
  flex-direction: column;
  flex: none;
  display: inline-flex;
  position: relative;
}

.landing-page-mobile .frame-74 {
  align-items: flex-start;
  gap: var(--spacings-spacing-md);
  flex-direction: column;
  height: 553px;
  display: inline-flex;
  position: relative;
}

.landing-page-mobile .div-14 {
  align-items: flex-start;
  gap: var(--spacings-spacing-xs);
  flex-direction: column;
  flex: none;
  display: inline-flex;
  position: relative;
}

.landing-page-mobile .text-wrapper-36 {
  color: var(--white);
  font-family: var(--XS-headline-HL-font-family);
  font-size: var(--XS-headline-HL-font-size);
  font-style: var(--XS-headline-HL-font-style);
  font-weight: var(--XS-headline-HL-font-weight);
  letter-spacing: var(--XS-headline-HL-letter-spacing);
  line-height: var(--XS-headline-HL-line-height);
  width: 328px;
  margin-top: -1px;
  position: relative;
}

.landing-page-mobile .DISFRUTA-LA-VIDA-EN-2 {
  color: #0000;
  font-family: var(--XS-body-BL-bold-font-family);
  font-size: var(--XS-body-BL-bold-font-size);
  font-style: var(--XS-body-BL-bold-font-style);
  font-weight: var(--XS-body-BL-bold-font-weight);
  letter-spacing: var(--XS-body-BL-bold-letter-spacing);
  line-height: var(--XS-body-BL-bold-line-height);
  width: 328px;
  position: relative;
}

.landing-page-mobile .text-wrapper-37 {
  color: #fff;
  font-family: var(--XS-body-BL-bold-font-family);
  font-size: var(--XS-body-BL-bold-font-size);
  font-style: var(--XS-body-BL-bold-font-style);
  font-weight: var(--XS-body-BL-bold-font-weight);
  letter-spacing: var(--XS-body-BL-bold-letter-spacing);
  line-height: var(--XS-body-BL-bold-line-height);
}

.landing-page-mobile .text-wrapper-38 {
  color: #7a67f2;
  font-family: var(--XS-body-BL-bold-font-family);
  font-size: var(--XS-body-BL-bold-font-size);
  font-style: var(--XS-body-BL-bold-font-style);
  font-weight: var(--XS-body-BL-bold-font-weight);
  letter-spacing: var(--XS-body-BL-bold-letter-spacing);
  line-height: var(--XS-body-BL-bold-line-height);
}

.landing-page-mobile .frame-75 {
  width: 328px;
  height: 322px;
  margin-bottom: 50px;
  position: relative;
}

.frame-75 iframe {
  border-radius: 20px;
}

.landing-page-mobile .player-3 {
  top: 138px !important;
  left: 142px !important;
}

.landing-page-mobile .button-8 {
  width: unset !important;
  flex: 1 !important;
}

.landing-page-mobile .frame-76 {
  align-items: flex-start;
  gap: var(--spacings-spacing-md);
  flex-direction: column;
  flex: none;
  width: 328px;
  display: flex;
  position: relative;
}

.landing-page-mobile .text-wrapper-39 {
  color: var(--white);
  font-family: var(--XS-headline-HM-font-family);
  font-size: var(--XS-headline-HM-font-size);
  font-style: var(--XS-headline-HM-font-style);
  font-weight: var(--XS-headline-HM-font-weight);
  letter-spacing: var(--XS-headline-HM-letter-spacing);
  line-height: var(--XS-headline-HM-line-height);
  align-self: stretch;
  width: 328px;
  margin-top: -1px;
  position: relative;
}

.landing-page-mobile .frame-77 {
  align-items: flex-start;
  gap: var(--spacings-spacing-sm);
  flex-direction: column;
  flex: none;
  display: inline-flex;
  position: relative;
}

.landing-page-mobile .alice-carousel {
  width: 360px;
}

.landing-page-mobile .alice-carousel__stage li {
  width: 360px !important;
}

.landing-page-mobile .alice-carousel__stage img {
  object-fit: contain;
  width: 320px;
  max-height: 410px;
}

.landing-page-mobile .text-wrapper-40 {
  color: var(--white);
  font-family: var(--XS-body-BL-bold-font-family);
  font-size: var(--XS-body-BL-bold-font-size);
  font-style: var(--XS-body-BL-bold-font-style);
  font-weight: var(--XS-body-BL-bold-font-weight);
  letter-spacing: var(--XS-body-BL-bold-letter-spacing);
  line-height: var(--XS-body-BL-bold-line-height);
  width: 328px;
  margin-top: -1px;
  position: relative;
}

.landing-page-mobile .text-wrapper-41 {
  color: var(--white);
  font-family: var(--XS-body-BL-semibold-font-family);
  font-size: var(--XS-body-BL-semibold-font-size);
  font-style: var(--XS-body-BL-semibold-font-style);
  font-weight: var(--XS-body-BL-semibold-font-weight);
  letter-spacing: var(--XS-body-BL-semibold-letter-spacing);
  line-height: var(--XS-body-BL-semibold-line-height);
  width: 328px;
  position: relative;
}

.landing-page-mobile .slider-2 {
  align-items: flex-start;
  gap: var(--spacings-spacing-sm);
  flex: none;
  width: 328px;
  display: flex;
  position: relative;
}

.landing-page-mobile .unsplash-2 {
  object-fit: cover;
  width: 328px;
  height: 279px;
  position: relative;
}

.landing-page-mobile .unsplash-3 {
  object-fit: cover;
  width: 8px;
  height: 279px;
  margin-right: -16px;
  position: relative;
}

.landing-page-mobile .unsplash-4 {
  object-fit: cover;
  width: 328px;
  height: 279px;
  margin-bottom: -4472px;
  margin-left: -55050px;
  position: relative;
}

.landing-page-mobile .unsplash-5 {
  object-fit: cover;
  width: 328px;
  height: 279px;
  margin-bottom: -4472px;
  margin-left: -54714px;
  position: relative;
}

.landing-page-mobile .unsplash-6 {
  width: 328px;
  height: 279px;
  margin-bottom: -4472px;
  margin-left: -54378px;
  position: relative;
}

.landing-page-mobile .unsplash-7 {
  object-fit: cover;
  width: 328px;
  height: 279px;
  margin-bottom: -4472px;
  margin-left: -54042px;
  position: relative;
}

.landing-page-mobile .slider-3 {
  flex: none;
  align-items: flex-start;
  gap: 8px;
  margin-right: -16px;
  padding: 0 136px;
  display: inline-flex;
  position: relative;
}

.landing-page-mobile .ellipse-3 {
  background-color: var(--white);
  border-radius: 4px;
  width: 8px;
  height: 8px;
  position: relative;
}

.landing-page-mobile .ellipse-4 {
  background-color: var(--dark-gray);
  border-radius: 4px;
  width: 8px;
  height: 8px;
  position: relative;
}

.landing-page-mobile .frame-78 {
  align-items: center;
  gap: var(--spacings-spacing-md);
  flex-direction: column;
  flex: none;
  display: inline-flex;
  position: relative;
}

.landing-page-mobile .en-rent-onchain-tu-2 {
  color: #0000;
  font-family: var(--XS-body-BL-semibold-font-family);
  font-size: var(--XS-body-BL-semibold-font-size);
  font-style: var(--XS-body-BL-semibold-font-style);
  font-weight: var(--XS-body-BL-semibold-font-weight);
  letter-spacing: var(--XS-body-BL-semibold-letter-spacing);
  line-height: var(--XS-body-BL-semibold-line-height);
  width: 328px;
  position: relative;
}

.landing-page-mobile .text-wrapper-42 {
  color: #fff;
  font-family: var(--XS-body-BL-semibold-font-family);
  font-size: var(--XS-body-BL-semibold-font-size);
  font-style: var(--XS-body-BL-semibold-font-style);
  font-weight: var(--XS-body-BL-semibold-font-weight);
  letter-spacing: var(--XS-body-BL-semibold-letter-spacing);
  line-height: var(--XS-body-BL-semibold-line-height);
}

.landing-page-mobile .text-wrapper-43 {
  color: #7a67f2;
  font-family: var(--XS-body-BL-semibold-font-family);
  font-size: var(--XS-body-BL-semibold-font-size);
  font-style: var(--XS-body-BL-semibold-font-style);
  font-weight: var(--XS-body-BL-semibold-font-weight);
  letter-spacing: var(--XS-body-BL-semibold-letter-spacing);
  line-height: var(--XS-body-BL-semibold-line-height);
}

.landing-page-mobile .frame-79 {
  background-color: var(--white);
  align-items: flex-start;
  gap: var(--spacings-spacing-md);
  padding: var(--spacings-spacing-md);
  border-radius: 8px;
  flex-direction: column;
  flex: none;
  width: 328px;
  display: flex;
  position: relative;
}

.landing-page-mobile .frame-80 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 8px;
  width: 100%;
  display: flex;
  position: relative;
}

.landing-page-mobile .design-component-instance-node-4 {
  flex: none !important;
  align-self: stretch !important;
  width: 100% !important;
}

.landing-page-mobile .frame-81 {
  align-items: center;
  gap: var(--spacings-spacing-xs);
  flex: none;
  display: inline-flex;
  position: relative;
}

.landing-page-mobile .frame-82 {
  align-items: flex-start;
  gap: var(--spacings-spacing-xxs);
  flex: none;
  display: inline-flex;
  position: relative;
}

.landing-page-mobile .text-wrapper-44 {
  color: var(--black);
  font-family: var(--XS-small-text-ST-l-bold-font-family);
  font-size: var(--XS-small-text-ST-l-bold-font-size);
  font-style: var(--XS-small-text-ST-l-bold-font-style);
  font-weight: var(--XS-small-text-ST-l-bold-font-weight);
  letter-spacing: var(--XS-small-text-ST-l-bold-letter-spacing);
  line-height: var(--XS-small-text-ST-l-bold-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.landing-page-mobile .text-wrapper-45 {
  color: var(--black);
  font-family: var(--XS-small-text-ST-l-medium-font-family);
  font-size: var(--XS-small-text-ST-l-medium-font-size);
  font-style: var(--XS-small-text-ST-l-medium-font-style);
  font-weight: var(--XS-small-text-ST-l-medium-font-weight);
  letter-spacing: var(--XS-small-text-ST-l-medium-letter-spacing);
  line-height: var(--XS-small-text-ST-l-medium-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.landing-page-mobile .frame-83 {
  align-items: flex-start;
  gap: var(--spacings-spacing-lg);
  flex-direction: column;
  flex: none;
  display: inline-flex;
  position: relative;
}

.landing-page-mobile .card-2 {
  background-color: var(--white);
  border-radius: 8px;
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 10px;
  padding: 16px;
  display: inline-flex;
  position: relative;
  overflow: hidden;
}

.landing-page-mobile .frame-84 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 8px;
  width: 296px;
  display: flex;
  position: relative;
}

.landing-page-mobile .frame-85 {
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 1px;
  width: 100%;
  display: flex;
  position: relative;
}

.landing-page-mobile .text-wrapper-46 {
  color: var(--black);
  font-family: var(--XS-body-BM-semibold-font-family);
  font-size: var(--XS-body-BM-semibold-font-size);
  font-style: var(--XS-body-BM-semibold-font-style);
  font-weight: var(--XS-body-BM-semibold-font-weight);
  letter-spacing: var(--XS-body-BM-semibold-letter-spacing);
  line-height: var(--XS-body-BM-semibold-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.landing-page-mobile .text-wrapper-47 {
  color: var(--black);
  font-family: var(--XS-body-BM-semibold-font-family);
  font-size: var(--XS-body-BM-semibold-font-size);
  font-style: var(--XS-body-BM-semibold-font-style);
  font-weight: var(--XS-body-BM-semibold-font-weight);
  letter-spacing: var(--XS-body-BM-semibold-letter-spacing);
  line-height: var(--XS-body-BM-semibold-line-height);
  flex: 1;
  margin-top: -1px;
  position: relative;
}

.landing-page-mobile .div-15 {
  color: #0000;
  font-family: var(--XS-body-BM-semibold-font-family);
  font-size: var(--XS-body-BM-semibold-font-size);
  font-style: var(--XS-body-BM-semibold-font-style);
  font-weight: var(--XS-body-BM-semibold-font-weight);
  letter-spacing: var(--XS-body-BM-semibold-letter-spacing);
  line-height: var(--XS-body-BM-semibold-line-height);
  flex: 1;
  margin-top: -1px;
  position: relative;
}

.landing-page-mobile .text-wrapper-48 {
  color: #1a1a1c;
  font-family: var(--XS-body-BM-semibold-font-family);
  font-size: var(--XS-body-BM-semibold-font-size);
  font-style: var(--XS-body-BM-semibold-font-style);
  font-weight: var(--XS-body-BM-semibold-font-weight);
  letter-spacing: var(--XS-body-BM-semibold-letter-spacing);
  line-height: var(--XS-body-BM-semibold-line-height);
}

.landing-page-mobile .text-wrapper-49 {
  color: #7a67f2;
  font-family: var(--XS-body-BM-semibold-font-family);
  font-size: var(--XS-body-BM-semibold-font-size);
  font-style: var(--XS-body-BM-semibold-font-style);
  font-weight: var(--XS-body-BM-semibold-font-weight);
  letter-spacing: var(--XS-body-BM-semibold-letter-spacing);
  line-height: var(--XS-body-BM-semibold-line-height);
}

.landing-page-mobile .frame-86 {
  align-items: flex-start;
  gap: var(--spacings-spacing-lg);
  flex-direction: column;
  flex: none;
  width: 328px;
  display: flex;
  position: relative;
}

.landing-page-mobile .text-wrapper-50 {
  color: var(--white);
  font-family: var(--XS-headline-HM-font-family);
  font-size: var(--XS-headline-HM-font-size);
  font-style: var(--XS-headline-HM-font-style);
  font-weight: var(--XS-headline-HM-font-weight);
  letter-spacing: var(--XS-headline-HM-letter-spacing);
  line-height: var(--XS-headline-HM-line-height);
  width: 328px;
  margin-top: -1px;
  position: relative;
}

.landing-page-mobile .disponibilidad-2 {
  color: var(--gray);
  font-family: var(--XS-headline-HS-font-family);
  font-size: var(--XS-headline-HS-font-size);
  font-style: var(--XS-headline-HS-font-style);
  font-weight: var(--XS-headline-HS-font-weight);
  letter-spacing: var(--XS-headline-HS-letter-spacing);
  line-height: var(--XS-headline-HS-line-height);
  width: 328px;
  margin-top: -1px;
  position: relative;
}

.landing-page-mobile .real-estate-card-14 {
  background-color: var(--white);
  align-items: flex-start;
  gap: var(--spacings-spacing-md);
  padding: var(--spacings-spacing-md);
  border-radius: 8px;
  flex-direction: column;
  flex: none;
  width: 328px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.landing-page-mobile .slider-wrapper {
  background-image: url("rectangle-260-7.f6eff882.png");
  background-position: 50%;
  background-size: cover;
  align-self: stretch;
  width: 100%;
  height: 254px;
  position: relative;
}

.landing-page-mobile .slider-4 {
  align-items: center;
  gap: var(--spacings-spacing-xs);
  justify-content: center;
  width: 296px;
  display: flex;
  position: relative;
  top: 239px;
}

.landing-page-mobile .frame-87 {
  align-self: stretch;
  align-items: flex-start;
  gap: var(--spacings-spacing-sm);
  flex-direction: column;
  flex: none;
  justify-content: center;
  width: 100%;
  display: flex;
  position: relative;
}

.landing-page-mobile .frame-88 {
  align-self: stretch;
  align-items: flex-end;
  gap: var(--spacings-spacing-xxs);
  flex-direction: column;
  flex: none;
  justify-content: center;
  width: 100%;
  display: flex;
  position: relative;
}

.landing-page-mobile .frame-89 {
  flex: none;
  justify-content: space-between;
  align-self: stretch;
  align-items: center;
  width: 100%;
  display: flex;
}

.landing-page-mobile .TULUM-2 {
  color: var(--black);
  font-family: var(--XS-headline-HS-font-family);
  font-size: var(--XS-headline-HS-font-size);
  font-style: var(--XS-headline-HS-font-style);
  font-weight: var(--XS-headline-HS-font-weight);
  letter-spacing: var(--XS-headline-HS-letter-spacing);
  line-height: var(--XS-headline-HS-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.landing-page-mobile .group-wrapper {
  align-items: center;
  gap: var(--spacings-spacing-sm);
  flex: none;
  display: inline-flex;
  position: relative;
}

.landing-page-mobile .overlap-group-wrapper {
  width: 58px;
  height: 17px;
  margin-right: -2px;
  position: relative;
}

.landing-page-mobile .overlap-group-2 {
  width: 56px;
  height: 17px;
  position: relative;
}

.landing-page-mobile .text-wrapper-51 {
  color: var(--gray);
  font-family: var(--XS-caps-CM-bold-font-family);
  font-size: var(--XS-caps-CM-bold-font-size);
  font-style: var(--XS-caps-CM-bold-font-style);
  font-weight: var(--XS-caps-CM-bold-font-weight);
  letter-spacing: var(--XS-caps-CM-bold-letter-spacing);
  line-height: var(--XS-caps-CM-bold-line-height);
  white-space: nowrap;
  position: absolute;
  top: 0;
  left: 0;
}

.landing-page-mobile .line-7 {
  object-fit: cover;
  width: 56px;
  height: 1px;
  position: absolute;
  top: 7px;
  left: 0;
}

.landing-page-mobile .text-wrapper-52 {
  color: var(--violet-dark);
  font-family: var(--XS-headline-HS-font-family);
  font-size: var(--XS-headline-HS-font-size);
  font-style: var(--XS-headline-HS-font-style);
  font-weight: var(--XS-headline-HS-font-weight);
  letter-spacing: var(--XS-headline-HS-letter-spacing);
  line-height: var(--XS-headline-HS-line-height);
  white-space: nowrap;
  width: fit-content;
  position: relative;
}

.landing-page-mobile .frame-90 {
  flex: none;
  justify-content: space-between;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  display: flex;
  position: relative;
}

.landing-page-mobile .text-wrapper-53 {
  color: var(--black);
  font-family: var(--XS-small-text-ST-l-reg-font-family);
  font-size: var(--XS-small-text-ST-l-reg-font-size);
  font-style: var(--XS-small-text-ST-l-reg-font-style);
  font-weight: var(--XS-small-text-ST-l-reg-font-weight);
  letter-spacing: var(--XS-small-text-ST-l-reg-letter-spacing);
  line-height: var(--XS-small-text-ST-l-reg-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.landing-page-mobile .la-fotograf-a-puede-2 {
  color: var(--dark-gray);
  font-family: var(--XS-small-text-ST-l-reg-font-family);
  font-size: var(--XS-small-text-ST-l-reg-font-size);
  font-style: var(--XS-small-text-ST-l-reg-font-style);
  font-weight: var(--XS-small-text-ST-l-reg-font-weight);
  letter-spacing: var(--XS-small-text-ST-l-reg-letter-spacing);
  line-height: var(--XS-small-text-ST-l-reg-line-height);
  align-self: stretch;
  position: relative;
}

.landing-page-mobile .frame-91 {
  background-image: url("rectangle-260-8.89461df5.png");
  background-position: 50%;
  background-size: cover;
  align-self: stretch;
  width: 100%;
  height: 254px;
  position: relative;
}

.landing-page-mobile .group-2 {
  width: 63px;
  height: 17px;
  margin-right: -2px;
  position: relative;
}

.landing-page-mobile .overlap-group-3 {
  width: 61px;
  height: 17px;
  position: relative;
}

.landing-page-mobile .frame-92 {
  background-image: url("rectangle-260-9.abda2bab.png");
  background-position: 50%;
  background-size: cover;
  align-self: stretch;
  width: 100%;
  height: 254px;
  position: relative;
}

.landing-page-mobile .group-3 {
  width: 60px;
  height: 17px;
  margin-right: -2px;
  position: relative;
}

.landing-page-mobile .overlap-group-4 {
  width: 58px;
  height: 17px;
  position: relative;
}

.landing-page-mobile .frame-93 {
  background-image: url("rectangle-260-10.32d2c885.png");
  background-position: 50%;
  background-size: cover;
  align-self: stretch;
  width: 100%;
  height: 254px;
  position: relative;
}

.landing-page-mobile .group-4 {
  width: 66px;
  height: 17px;
  margin-right: -2px;
  position: relative;
}

.landing-page-mobile .overlap-group-5 {
  width: 64px;
  height: 17px;
  position: relative;
}

.landing-page-mobile .interface {
  width: 24px !important;
  height: 24px !important;
  position: relative !important;
}

.icon-rotate {
  transform: rotate(45deg);
}

.landing-page-mobile .img-3 {
  width: 22px;
  height: 22px;
  position: relative;
}

.landing-page-mobile .frame-94 {
  background-image: url("rectangle-260-11.b6dda76e.png");
  background-position: 50%;
  background-size: cover;
  align-self: stretch;
  width: 100%;
  height: 254px;
  position: relative;
}

.landing-page-mobile .group-5 {
  width: 67px;
  height: 17px;
  margin-right: -2px;
  position: relative;
}

.landing-page-mobile .overlap-group-6 {
  width: 65px;
  height: 17px;
  position: relative;
}

.landing-page-mobile .frame-95 {
  background-image: url("rectangle-260-12.043581b6.png");
  background-position: 50%;
  background-size: cover;
  align-self: stretch;
  width: 100%;
  height: 254px;
  position: relative;
}

.landing-page-mobile .frame-96 {
  align-items: flex-start;
  gap: var(--spacings-spacing-sm);
  flex-wrap: wrap;
  flex: none;
  width: 328px;
  display: flex;
  position: relative;
}

.landing-page-mobile .card-middle-mobile {
  background-color: var(--violet);
  align-items: flex-start;
  gap: var(--spacings-spacing-none);
  padding: var(--spacings-spacing-md);
  border-radius: 8px;
  flex-direction: column;
  align-self: stretch;
  width: 160px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.landing-page-mobile .frame-97 {
  align-self: stretch;
  align-items: flex-start;
  gap: var(--spacings-spacing-sm);
  flex-direction: column;
  flex: none;
  width: 100%;
  display: flex;
  position: relative;
}

.landing-page-mobile .frame-98 {
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 8px;
  width: 100%;
  display: flex;
  position: relative;
}

.landing-page-mobile .visitaci-n {
  color: var(--white);
  font-family: var(--XS-title-TM-font-family);
  font-size: var(--XS-title-TM-font-size);
  font-style: var(--XS-title-TM-font-style);
  font-weight: var(--XS-title-TM-font-weight);
  letter-spacing: var(--XS-title-TM-letter-spacing);
  line-height: var(--XS-title-TM-line-height);
  flex: 1;
  position: relative;
}

.landing-page-mobile .design-component-instance-node-5 {
  left: unset !important;
  top: unset !important;
  width: 16px !important;
  height: 16px !important;
  position: relative !important;
}

.landing-page-mobile .text-wrapper-54 {
  color: var(--white);
  font-family: var(--XS-body-BM-semibold-font-family);
  font-size: var(--XS-body-BM-semibold-font-size);
  font-style: var(--XS-body-BM-semibold-font-style);
  font-weight: var(--XS-body-BM-semibold-font-weight);
  letter-spacing: var(--XS-body-BM-semibold-letter-spacing);
  line-height: var(--XS-body-BM-semibold-line-height);
  align-self: stretch;
  position: relative;
}

.landing-page-mobile .card-middle-mobile-2 {
  background-color: var(--white);
  align-items: flex-start;
  gap: var(--spacings-spacing-none);
  padding: var(--spacings-spacing-md);
  border-radius: 8px;
  flex-direction: column;
  width: 160px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.landing-page-mobile .frame-99 {
  flex: none;
  align-self: stretch;
  align-items: flex-end;
  gap: 8px;
  width: 100%;
  display: flex;
  position: relative;
}

.landing-page-mobile .visitaci-n-2 {
  color: var(--black);
  font-family: var(--XS-title-TM-font-family);
  font-size: var(--XS-title-TM-font-size);
  font-style: var(--XS-title-TM-font-style);
  font-weight: var(--XS-title-TM-font-weight);
  letter-spacing: var(--XS-title-TM-letter-spacing);
  line-height: var(--XS-title-TM-line-height);
  flex: 1;
  position: relative;
}

.landing-page-mobile .text-wrapper-55 {
  color: var(--black);
  font-family: var(--XS-body-BM-semibold-font-family);
  font-size: var(--XS-body-BM-semibold-font-size);
  font-style: var(--XS-body-BM-semibold-font-style);
  font-weight: var(--XS-body-BM-semibold-font-weight);
  letter-spacing: var(--XS-body-BM-semibold-letter-spacing);
  line-height: var(--XS-body-BM-semibold-line-height);
  align-self: stretch;
  position: relative;
}

.landing-page-mobile .card-middle-mobile-3 {
  background-color: var(--white);
  align-items: flex-start;
  gap: var(--spacings-spacing-none);
  padding: var(--spacings-spacing-md);
  border-radius: 8px;
  flex-direction: column;
  align-self: stretch;
  width: 160px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.landing-page-mobile .card-middle-mobile-4 {
  background-color: var(--blue);
  align-items: flex-start;
  gap: var(--spacings-spacing-none);
  padding: var(--spacings-spacing-md);
  border-radius: 8px;
  flex-direction: column;
  width: 160px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.landing-page-mobile .card-middle-mobile-5 {
  background-color: var(--orange);
  align-items: flex-start;
  gap: var(--spacings-spacing-none);
  padding: var(--spacings-spacing-md);
  border-radius: 8px;
  flex-direction: column;
  width: 160px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.landing-page-mobile .card-middle-mobile-6 {
  background-color: var(--white);
  align-items: flex-start;
  gap: var(--spacings-spacing-none);
  padding: var(--spacings-spacing-md);
  border-radius: 8px;
  flex-direction: column;
  align-self: stretch;
  width: 160px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.landing-page-mobile .frame-100 {
  flex: none;
  justify-content: space-between;
  align-self: stretch;
  align-items: flex-end;
  width: 100%;
  display: flex;
  position: relative;
}

.landing-page-mobile .visitaci-n-3 {
  color: var(--black);
  font-family: var(--XS-title-TM-font-family);
  font-size: var(--XS-title-TM-font-size);
  font-style: var(--XS-title-TM-font-style);
  font-weight: var(--XS-title-TM-font-weight);
  letter-spacing: var(--XS-title-TM-letter-spacing);
  line-height: var(--XS-title-TM-line-height);
  white-space: nowrap;
  width: fit-content;
  position: relative;
}

.landing-page-mobile .card-middle-mobile-7 {
  background-color: var(--green);
  align-items: flex-start;
  gap: var(--spacings-spacing-none);
  padding: var(--spacings-spacing-md);
  border-radius: 8px;
  flex-direction: column;
  width: 160px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.landing-page-mobile .interface-star {
  width: 16px;
  height: 16px;
  position: relative;
}

.landing-page-mobile .text-wrapper-56 {
  color: var(--white);
  font-family: var(--XS-body-BL-bold-font-family);
  font-size: var(--XS-body-BL-bold-font-size);
  font-style: var(--XS-body-BL-bold-font-style);
  font-weight: var(--XS-body-BL-bold-font-weight);
  letter-spacing: var(--XS-body-BL-bold-letter-spacing);
  line-height: var(--XS-body-BL-bold-line-height);
  width: 328px;
  position: relative;
}

.landing-page-mobile .frame-101 {
  width: 100%;
  position: relative;
}

.frame-101 iframe {
  border-radius: 8px;
  width: 100%;
  height: 300px;
}

.landing-page-mobile .player-4 {
  top: 117px !important;
  left: 142px !important;
}

.landing-page-mobile .rectangle-5 {
  object-fit: cover;
  width: 328px;
  height: 210px;
  position: relative;
}

.landing-page-mobile .card-middle-mobile-8 {
  background-color: var(--violet);
  align-items: flex-start;
  gap: var(--spacings-spacing-none);
  padding: var(--spacings-spacing-md);
  border-radius: 8px;
  flex-direction: column;
  align-self: stretch;
  width: 160px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.landing-page-mobile .element-reg-strate {
  color: var(--white);
  font-family: var(--XS-title-TL-font-family);
  font-size: var(--XS-title-TL-font-size);
  font-style: var(--XS-title-TL-font-style);
  font-weight: var(--XS-title-TL-font-weight);
  letter-spacing: var(--XS-title-TL-letter-spacing);
  line-height: var(--XS-title-TL-line-height);
  align-self: stretch;
  margin-top: -1px;
  position: relative;
}

.landing-page-mobile .card-middle-mobile-9 {
  align-items: flex-start;
  gap: var(--spacings-spacing-none);
  padding: var(--spacings-spacing-md);
  background-color: #fff;
  border-radius: 8px;
  flex-direction: column;
  width: 160px;
  height: 183px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.landing-page-mobile .element-reg-strate-2 {
  color: #1a1a1c;
  font-family: var(--XS-title-TL-font-family);
  font-size: var(--XS-title-TL-font-size);
  font-style: var(--XS-title-TL-font-style);
  font-weight: var(--XS-title-TL-font-weight);
  letter-spacing: var(--XS-title-TL-letter-spacing);
  line-height: var(--XS-title-TL-line-height);
  align-self: stretch;
  margin-top: -1px;
  position: relative;
}

.landing-page-mobile .s-lo-necesitas-un {
  color: #1a1a1c;
  font-family: var(--XS-body-BM-semibold-font-family);
  font-size: var(--XS-body-BM-semibold-font-size);
  font-style: var(--XS-body-BM-semibold-font-style);
  font-weight: var(--XS-body-BM-semibold-font-weight);
  letter-spacing: var(--XS-body-BM-semibold-letter-spacing);
  line-height: var(--XS-body-BM-semibold-line-height);
  align-self: stretch;
  position: relative;
}

.landing-page-mobile .card-middle-mobile-10, .landing-page-mobile .card-middle-mobile-11 {
  align-items: flex-start;
  gap: var(--spacings-spacing-none);
  padding: var(--spacings-spacing-md);
  background-color: #fff;
  border-radius: 8px;
  flex-direction: column;
  align-self: stretch;
  width: 160px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.landing-page-mobile .card-middle-mobile-12 {
  align-items: flex-start;
  gap: var(--spacings-spacing-none);
  padding: var(--spacings-spacing-md);
  background-color: #fff;
  border-radius: 8px;
  flex-direction: column;
  width: 160px;
  height: 237px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.landing-page-mobile .frame-102 {
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 16px;
  width: 100%;
  display: flex;
  position: relative;
}

.landing-page-mobile .text-wrapper-57 {
  color: var(--white);
  font-family: var(--XS-headline-HM-font-family);
  font-size: var(--XS-headline-HM-font-size);
  font-style: var(--XS-headline-HM-font-style);
  font-weight: var(--XS-headline-HM-font-weight);
  letter-spacing: var(--XS-headline-HM-letter-spacing);
  line-height: var(--XS-headline-HM-line-height);
  flex: 1;
  margin-top: -1px;
  position: relative;
}

.landing-page-mobile .close-list {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 12px;
  width: 100%;
  display: flex;
  position: relative;
}

.landing-page-mobile .line-8 {
  object-fit: cover;
  width: 328px;
  height: 1px;
  margin-top: -1px;
  position: relative;
}

.landing-page-mobile .text-wrapper-58 {
  color: var(--white);
  font-family: var(--XS-title-TL-font-family);
  font-size: var(--XS-title-TL-font-size);
  font-style: var(--XS-title-TL-font-style);
  font-weight: var(--XS-title-TL-font-weight);
  letter-spacing: var(--XS-title-TL-letter-spacing);
  line-height: var(--XS-title-TL-line-height);
  width: 191px;
  margin-top: -1px;
  position: relative;
}

.landing-page-mobile .close {
  background-image: url("menu-close-lg-17.256cf547.svg");
  background-size: 100% 100%;
  width: 17px;
  height: 17px;
  position: relative;
}

.landing-page-mobile .frame-103 {
  align-items: flex-start;
  gap: var(--spacings-spacing-md);
  flex-wrap: wrap;
  flex: none;
  width: 328px;
  display: flex;
  position: relative;
}

.landing-page-mobile .team-player-wrapper {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  display: inline-flex;
  position: relative;
}

.landing-page-mobile .rectangle-6 {
  width: 155.7px;
  height: 208px;
  position: relative;
}

.landing-page-mobile .frame-104 {
  align-items: flex-start;
  gap: var(--spacings-spacing-none);
  flex-direction: column;
  flex: none;
  width: 156px;
  display: flex;
  position: relative;
}

.landing-page-mobile .frame-105 {
  flex: none;
  justify-content: space-between;
  align-items: center;
  width: 156px;
  display: flex;
  position: relative;
}

.landing-page-mobile .name-surname {
  color: var(--white);
  font-family: var(--XS-title-TM-font-family);
  font-size: var(--XS-title-TM-font-size);
  font-style: var(--XS-title-TM-font-style);
  font-weight: var(--XS-title-TM-font-weight);
  letter-spacing: var(--XS-title-TM-letter-spacing);
  line-height: var(--XS-title-TM-line-height);
  width: 132px;
  margin-top: -1px;
  position: relative;
}

.landing-page-mobile .position {
  color: var(--white);
  font-family: var(--XS-small-text-ST-l-medium-font-family);
  font-size: var(--XS-small-text-ST-l-medium-font-size);
  font-style: var(--XS-small-text-ST-l-medium-font-style);
  font-weight: var(--XS-small-text-ST-l-medium-font-weight);
  letter-spacing: var(--XS-small-text-ST-l-medium-letter-spacing);
  line-height: var(--XS-small-text-ST-l-medium-line-height);
  align-self: stretch;
  position: relative;
}

.landing-page-mobile .from {
  width: 156px;
  height: 16px;
  position: relative;
}

.landing-page-mobile .text-wrapper-59 {
  color: var(--gray);
  font-family: var(--XS-small-text-ST-l-medium-font-family);
  font-size: var(--XS-small-text-ST-l-medium-font-size);
  font-style: var(--XS-small-text-ST-l-medium-font-style);
  font-weight: var(--XS-small-text-ST-l-medium-font-weight);
  letter-spacing: var(--XS-small-text-ST-l-medium-letter-spacing);
  line-height: var(--XS-small-text-ST-l-medium-line-height);
  white-space: nowrap;
  position: absolute;
  top: -1px;
  left: 0;
}

.landing-page-mobile .rectangle-7 {
  object-fit: cover;
  width: 155.7px;
  height: 208px;
  position: relative;
}

.landing-page-mobile .frame-106 {
  flex: none;
  justify-content: space-between;
  align-items: flex-start;
  width: 156px;
  display: flex;
  position: relative;
}

.landing-page-mobile .line-9 {
  object-fit: cover;
  width: 328px;
  height: 1px;
  position: relative;
}

.landing-page-mobile .name-surname-2 {
  color: var(--white);
  letter-spacing: -1.2px;
  width: 132px;
  margin-top: -1px;
  font-family: Syncopate, Helvetica;
  font-size: 12px;
  font-weight: 700;
  line-height: 14.4px;
  text-decoration: underline;
  position: relative;
}

.landing-page-mobile .close-2 {
  background-image: url("menu-close-lg-27.a184fcab.svg");
  background-size: 100% 100%;
  width: 17px;
  height: 17px;
  position: relative;
}

.landing-page-mobile .name-surname-3 {
  color: var(--white);
  font-family: var(--XS-title-TM-font-family);
  font-size: var(--XS-title-TM-font-size);
  font-style: var(--XS-title-TM-font-style);
  font-weight: var(--XS-title-TM-font-weight);
  letter-spacing: var(--XS-title-TM-letter-spacing);
  line-height: var(--XS-title-TM-line-height);
  width: 132px;
  position: relative;
}

.landing-page-mobile .frame-107 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 12px;
  margin-right: -1px;
  display: inline-flex;
  position: relative;
}

.landing-page-mobile .frame-109 {
  flex: none;
  align-items: center;
  gap: 128px;
  display: inline-flex;
  position: relative;
}

.landing-page-mobile .c-mo-es-el-proceso {
  color: var(--white);
  font-family: var(--XS-body-BM-bold-font-family);
  font-size: var(--XS-body-BM-bold-font-size);
  font-style: var(--XS-body-BM-bold-font-style);
  font-weight: var(--XS-body-BM-bold-font-weight);
  letter-spacing: var(--XS-body-BM-bold-letter-spacing);
  line-height: var(--XS-body-BM-bold-line-height);
  width: 183px;
  margin-top: -1px;
  position: relative;
}

.landing-page-mobile .plus {
  width: 18px;
  height: 18px;
  position: relative;
}

.plus-rotate {
  transform: rotate(45deg);
}

.landing-page-mobile .frame-110 {
  flex: none;
  align-items: center;
  gap: 120px;
  display: inline-flex;
  position: relative;
}

.landing-page-mobile .text-wrapper-60 {
  color: var(--white);
  font-family: var(--XS-body-BM-bold-font-family);
  font-size: var(--XS-body-BM-bold-font-size);
  font-style: var(--XS-body-BM-bold-font-style);
  font-weight: var(--XS-body-BM-bold-font-weight);
  letter-spacing: var(--XS-body-BM-bold-letter-spacing);
  line-height: var(--XS-body-BM-bold-line-height);
  width: 191px;
  margin-top: -1px;
  position: relative;
}

.landing-page-mobile .frame-111 {
  flex: none;
  align-items: center;
  gap: 113px;
  display: inline-flex;
  position: relative;
}

.landing-page-mobile .c-mo-declarar-los {
  color: var(--white);
  font-family: var(--XS-body-BM-bold-font-family);
  font-size: var(--XS-body-BM-bold-font-size);
  font-style: var(--XS-body-BM-bold-font-style);
  font-weight: var(--XS-body-BM-bold-font-weight);
  letter-spacing: var(--XS-body-BM-bold-letter-spacing);
  line-height: var(--XS-body-BM-bold-line-height);
  width: 198px;
  margin-top: -1px;
  position: relative;
}

.landing-page-mobile .qu-aporta-rent-2 {
  color: var(--white);
  font-family: var(--XS-body-BM-bold-font-family);
  font-size: var(--XS-body-BM-bold-font-size);
  font-style: var(--XS-body-BM-bold-font-style);
  font-weight: var(--XS-body-BM-bold-font-weight);
  letter-spacing: var(--XS-body-BM-bold-letter-spacing);
  line-height: var(--XS-body-BM-bold-line-height);
  width: 182px;
  margin-top: -1px;
  position: relative;
}

.landing-page-mobile .frame-112 {
  align-items: flex-start;
  gap: var(--spacings-spacing-lg);
  padding: 0px var(--spacings-spacing-md) 0px var(--spacings-spacing-md);
  flex-direction: column;
  flex: none;
  display: inline-flex;
  position: relative;
}

.landing-page-mobile .frame-113 {
  background-color: var(--white);
  align-items: flex-start;
  gap: var(--spacings-spacing-lg);
  padding: var(--spacings-spacing-md);
  border-radius: 8px;
  flex-direction: column;
  flex: none;
  width: 328px;
  display: flex;
  position: relative;
}

.landing-page-mobile .text-wrapper-61 {
  color: var(--black);
  font-family: var(--XS-body-BL-bold-font-family);
  font-size: var(--XS-body-BL-bold-font-size);
  font-style: var(--XS-body-BL-bold-font-style);
  font-weight: var(--XS-body-BL-bold-font-weight);
  letter-spacing: var(--XS-body-BL-bold-letter-spacing);
  line-height: var(--XS-body-BL-bold-line-height);
  align-self: stretch;
  margin-top: -1px;
  position: relative;
}

.landing-page-mobile .timer-5 {
  background-color: var(--violet-dark) !important;
  padding: var(--spacings-spacing-sm) var(--spacings-spacing-md) var(--spacings-spacing-sm) var(--spacings-spacing-md) !important;
  flex: none !important;
  align-self: stretch !important;
  width: 100% !important;
  overflow: hidden !important;
}

.landing-page-mobile .timer-6 {
  color: #fff !important;
}

.landing-page-mobile .timer-7 {
  color: #cac2fa !important;
}

.landing-page-mobile .timer-8 {
  background-color: var(--lavender) !important;
}

.landing-page-mobile .text-wrapper-62 {
  color: var(--black);
  font-family: var(--XS-body-BM-bold-font-family);
  font-size: var(--XS-body-BM-bold-font-size);
  font-style: var(--XS-body-BM-bold-font-style);
  font-weight: var(--XS-body-BM-bold-font-weight);
  letter-spacing: var(--XS-body-BM-bold-letter-spacing);
  line-height: var(--XS-body-BM-bold-line-height);
  align-self: stretch;
  margin-top: -1px;
  position: relative;
}

.landing-page-mobile .frame-114 {
  align-self: stretch;
  align-items: flex-start;
  gap: var(--spacings-spacing-md);
  flex-direction: column;
  flex: none;
  width: 100%;
  display: flex;
  position: relative;
}

.landing-page-mobile .text-wrapper-63 {
  color: var(--violet-dark);
  font-family: var(--XS-body-BM-semibold-font-family);
  font-size: var(--XS-body-BM-semibold-font-size);
  font-style: var(--XS-body-BM-semibold-font-style);
  font-weight: var(--XS-body-BM-semibold-font-weight);
  letter-spacing: var(--XS-body-BM-semibold-letter-spacing);
  line-height: var(--XS-body-BM-semibold-line-height);
  align-self: stretch;
  position: relative;
}

.landing-page-mobile .footer-landing {
  background-color: var(--white);
  border-radius: 8px 8px 0 0;
  flex: none;
  align-items: flex-start;
  gap: 10px;
  padding: 24px 16px;
  display: inline-flex;
  position: relative;
  overflow: hidden;
}

.landing-page-mobile .frame-115 {
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  width: 328px;
  display: flex;
  position: relative;
}

.landing-page-mobile .text-wrapper-64 {
  color: var(--black);
  font-family: var(--XS-title-TL-font-family);
  font-size: var(--XS-title-TL-font-size);
  font-style: var(--XS-title-TL-font-style);
  font-weight: var(--XS-title-TL-font-weight);
  letter-spacing: var(--XS-title-TL-letter-spacing);
  line-height: var(--XS-title-TL-line-height);
  width: 328px;
  margin-top: -1px;
  position: relative;
}

.landing-page-mobile .frame-116 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 20px;
  width: 328px;
  display: flex;
  position: relative;
}

.landing-page-mobile .frame-117 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 20px;
  width: 100%;
  display: flex;
  position: relative;
}

.landing-page-mobile .frame-118 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 6px;
  width: 100%;
  display: flex;
  position: relative;
}

.landing-page-mobile .tel-fono-2 {
  color: var(--gray);
  font-family: var(--XS-caps-CXS-semibold-font-family);
  font-size: var(--XS-caps-CXS-semibold-font-size);
  font-style: var(--XS-caps-CXS-semibold-font-style);
  font-weight: var(--XS-caps-CXS-semibold-font-weight);
  letter-spacing: var(--XS-caps-CXS-semibold-letter-spacing);
  line-height: var(--XS-caps-CXS-semibold-line-height);
  align-self: stretch;
  margin-top: -1px;
  position: relative;
}

.landing-page-mobile .line-10 {
  object-fit: cover;
  align-self: stretch;
  width: 100%;
  height: 1px;
  position: relative;
}

.landing-page-mobile .frame-119 {
  flex: none;
  justify-content: space-between;
  align-items: flex-start;
  width: 328px;
  display: flex;
  position: relative;
}

.landing-page-mobile .frame-120 {
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  width: 164px;
  display: flex;
  position: relative;
}

.landing-page-mobile .text-wrapper-65 {
  color: var(--black);
  font-family: var(--XS-title-TL-font-family);
  font-size: var(--XS-title-TL-font-size);
  font-style: var(--XS-title-TL-font-style);
  font-weight: var(--XS-title-TL-font-weight);
  letter-spacing: var(--XS-title-TL-letter-spacing);
  line-height: var(--XS-title-TL-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.landing-page-mobile .text-wrapper-66 {
  color: var(--black);
  font-family: var(--XS-body-BM-semibold-font-family);
  font-size: var(--XS-body-BM-semibold-font-size);
  font-style: var(--XS-body-BM-semibold-font-style);
  font-weight: var(--XS-body-BM-semibold-font-weight);
  letter-spacing: var(--XS-body-BM-semibold-letter-spacing);
  line-height: var(--XS-body-BM-semibold-line-height);
  align-self: stretch;
  margin-top: -1px;
  position: relative;
}

.landing-page-mobile .frame-121 {
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  width: 138px;
  display: flex;
  position: relative;
}

.landing-page-mobile .frame-122 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 4px;
  width: 100%;
  display: flex;
  position: relative;
}

.landing-page-mobile .text-wrapper-67 {
  color: var(--black);
  font-family: var(--XS-caps-CM-bold-font-family);
  font-size: var(--XS-caps-CM-bold-font-size);
  font-style: var(--XS-caps-CM-bold-font-style);
  font-weight: var(--XS-caps-CM-bold-font-weight);
  letter-spacing: var(--XS-caps-CM-bold-letter-spacing);
  line-height: var(--XS-caps-CM-bold-line-height);
  align-self: stretch;
  margin-top: -1px;
  position: relative;
}

.landing-page-mobile .text-wrapper-68 {
  color: var(--black);
  font-family: var(--XS-body-BM-reg-font-family);
  font-size: var(--XS-body-BM-reg-font-size);
  font-style: var(--XS-body-BM-reg-font-style);
  font-weight: var(--XS-body-BM-reg-font-weight);
  letter-spacing: var(--XS-body-BM-reg-letter-spacing);
  line-height: var(--XS-body-BM-reg-line-height);
  align-self: stretch;
  position: relative;
}

.landing-page-mobile .frame-123 {
  flex: none;
  align-items: flex-start;
  gap: 66px;
  display: inline-flex;
  position: relative;
}

.landing-page-mobile .frame-124 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 8px;
  display: inline-flex;
  position: relative;
}

.landing-page-mobile .links-3 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 1px;
  display: inline-flex;
  position: relative;
}

.landing-page-mobile .text-wrapper-69 {
  color: var(--black);
  font-family: var(--XS-small-text-ST-l-reg-font-family);
  font-size: var(--XS-small-text-ST-l-reg-font-size);
  font-style: var(--XS-small-text-ST-l-reg-font-style);
  font-weight: var(--XS-small-text-ST-l-reg-font-weight);
  letter-spacing: var(--XS-small-text-ST-l-reg-letter-spacing);
  line-height: var(--XS-small-text-ST-l-reg-line-height);
  white-space: nowrap;
  width: fit-content;
  position: relative;
}

.text-wrapper-20 {
  color: var(--white);
  font-family: var(--XL-headline-HS-font-family);
  font-size: var(--XS-headline-HM-font-size);
  font-style: var(--XL-headline-HS-font-style);
  font-weight: var(--XL-headline-HS-font-weight);
  letter-spacing: var(--XL-headline-HS-letter-spacing);
  line-height: var(--XL-headline-HS-line-height);
  margin-top: -1px;
}

.frame-wrapper-2 {
  width: 328px;
}

.frame-69 {
  justify-content: space-between;
  display: flex;
}
/*# sourceMappingURL=index.72625264.css.map */
