@media screen and (min-width: 1024px) and (max-width: 1100px) {
  body {
    zoom: 0.68; /* Ajusta el zoom según tus necesidades */
  }
}

@media screen and (min-width: 1101px) and (max-width: 1200px) {
  body {
    zoom: 0.74; /* Ajusta el zoom según tus necesidades */
  }
}

@media screen and (min-width: 1201px) and (max-width: 1300px) {
  body {
    zoom: 0.8; /* Ajusta el zoom según tus necesidades */
  }
}

@media screen and (min-width: 1300px) and (max-width: 1400px) {
  body {
    zoom: 0.87; /* Ajusta el zoom según tus necesidades */
  }
}

@media screen and (min-width: 1401px) and (max-width: 1470) {
  body {
    zoom: 0.94; /* Ajusta el zoom según tus necesidades */
  }
}

.invisible {
  display: none !important;
}

.landing-page-XL {
  align-items: center;
  background-color: var(--black);
  display: flex;
  flex-direction: column;
  gap: var(--spacings-spacing-betweenblock-desktop);
  justify-content: center;
  position: relative;
  /* width: 1920px; */
  width: 100%;
}

.landing-page-XL .frame-36 {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  gap: var(--spacings-spacing-xxxl);
  height: 1080px;
  position: relative;
  width: 100%;
}

.landing-page-XL .header-timesharing-rent {
  flex: 0 0 auto !important;
}

.landing-page-XL .frame-37 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: var(--spacings-spacing-xxxl);
  height: 865px;
  position: relative;
  width: 1440px;
}

.landing-page-XL .frame-38 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.landing-page-XL .text-wrapper-17 {
  color: var(--white);
  font-family: var(--XL-headline-HM-font-family);
  font-size: var(--XL-headline-HM-font-size);
  font-style: var(--XL-headline-HM-font-style);
  font-weight: var(--XL-headline-HM-font-weight);
  letter-spacing: var(--XL-headline-HM-letter-spacing);
  line-height: var(--XL-headline-HM-line-height);
  margin-top: -1px;
  position: relative;
  width: 998px;
}

.landing-page-XL .frame-39 {
  align-items: flex-start;
  background-color: #ffffff;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  height: 176px;
  justify-content: space-between;
  padding: var(--spacings-spacing-md);
  position: relative;
  width: 418px;
}

.landing-page-XL .DISFRUTA-LA-VIDA-EN {
  align-self: stretch;
  color: transparent;
  font-family: var(--XL-caps-CS-bold-font-family);
  font-size: var(--XL-caps-CS-bold-font-size);
  font-style: var(--XL-caps-CS-bold-font-style);
  font-weight: var(--XL-caps-CS-bold-font-weight);
  letter-spacing: var(--XL-caps-CS-bold-letter-spacing);
  line-height: var(--XL-caps-CS-bold-line-height);
  margin-top: -1px;
  position: relative;
}

.landing-page-XL .text-wrapper-18 {
  color: #1a1a1c;
  font-family: var(--XL-caps-CS-bold-font-family);
  font-size: var(--XL-caps-CS-bold-font-size);
  font-style: var(--XL-caps-CS-bold-font-style);
  font-weight: var(--XL-caps-CS-bold-font-weight);
  letter-spacing: var(--XL-caps-CS-bold-letter-spacing);
  line-height: var(--XL-caps-CS-bold-line-height);
}

.landing-page-XL .text-wrapper-19 {
  color: #7a67f2;
  font-family: var(--XL-caps-CS-bold-font-family);
  font-size: var(--XL-caps-CS-bold-font-size);
  font-style: var(--XL-caps-CS-bold-font-style);
  font-weight: var(--XL-caps-CS-bold-font-weight);
  letter-spacing: var(--XL-caps-CS-bold-letter-spacing);
  line-height: var(--XL-caps-CS-bold-line-height);
}

.landing-page-XL .design-component-instance-node {
  align-self: stretch !important;
  flex: 0 0 auto !important;
  height: unset !important;
  width: 100% !important;
  cursor: pointer !important;
}

.landing-page-XL .button-4 {
  flex: 1 !important;
  margin-top: -3px !important;
  width: unset !important;
}

.landing-page-XL .player-wrapper {
  background-image: url(../../../static/img/unsplash-gf7hhmic3vo.png);
  background-size: 100% 100%;
  height: 619px;
  position: relative;
  width: 1440px;
}

.landing-page-XL .player-instance {
  left: 670px !important;
  top: 260px !important;
}

.landing-page-XL .frame-40 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: var(--spacings-spacing-betweenblock-desktop);
  position: relative;
  width: 1440px;
}

.landing-page-XL .frame-41 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: var(--spacings-spacing-xxl);
  justify-content: center;
  position: relative;
  width: 100%;
}

.landing-page-XL .frame-42 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  gap: 200px;
  position: relative;
  width: 1440px;
}

.landing-page-XL .text-wrapper-20 {
  color: var(--white);
  font-family: var(--XL-headline-HS-font-family);
  font-size: var(--XL-headline-HS-font-size);
  font-style: var(--XL-headline-HS-font-style);
  font-weight: var(--XL-headline-HS-font-weight);
  letter-spacing: var(--XL-headline-HS-letter-spacing);
  line-height: var(--XL-headline-HS-line-height);
  margin-top: -1px;
  position: relative;
  width: 418px;
}

.team-block-list {
  margin-top: var(--spacings-spacing-xl);
}

@media (max-width: 1024px) {
  .team-block-list {
    margin-top: var(--spacings-spacing-md);
  }
}

.landing-page-XL .frame-43 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 40px;
  position: relative;
  width: 822px;
}

.landing-page-XL .frame-44 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: var(--spacings-spacing-lg);
  position: relative;
  width: 100%;
}

.landing-page-XL .text-wrapper-21 {
  color: var(--white);
  font-family: var(--XL-body-BM-semibold-font-family);
  font-size: var(--XL-body-BM-semibold-font-size);
  font-style: var(--XL-body-BM-semibold-font-style);
  font-weight: var(--XL-body-BM-semibold-font-weight);
  letter-spacing: var(--XL-body-BM-semibold-letter-spacing);
  line-height: var(--XL-body-BM-semibold-line-height);
  margin-top: -1px;
  position: relative;
  width: 380px;
}

.landing-page-XL .text-wrapper-22 {
  color: var(--white);
  flex: 1;
  font-family: var(--XL-body-BM-semibold-font-family);
  font-size: var(--XL-body-BM-semibold-font-size);
  font-style: var(--XL-body-BM-semibold-font-style);
  font-weight: var(--XL-body-BM-semibold-font-weight);
  letter-spacing: var(--XL-body-BM-semibold-letter-spacing);
  line-height: var(--XL-body-BM-semibold-line-height);
  margin-top: -1px;
  position: relative;
}

.landing-page-XL .frame-45 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: var(--spacings-spacing-lg);
  justify-content: center;
  position: relative;
  width: 100%;
}

.landing-page-XL img {
  width: 100%;
}

.landing-page-XL .icon-instance-node {
  height: 60px !important;
  position: relative !important;
  width: 60px !important;
}

.landing-page-XL .unsplash {
  flex: 1;
  flex-grow: 1;
  height: 750px;
  object-fit: cover;
  position: relative;
}

.landing-page-XL .frame-46 {
  align-items: flex-end;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: var(--spacings-spacing-xxl);
  position: relative;
}

.landing-page-XL .frame-47 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 200px;
  position: relative;
}

.landing-page-XL .frame-48 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: var(--spacings-spacing-xl);
  position: relative;
  width: 822px;
}

.landing-page-XL .frame-49 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 24px;
  position: relative;
  width: 100%;
}

.landing-page-XL .text-wrapper-23 {
  align-self: stretch;
  color: var(--white);
  font-family: var(--XL-body-BM-bold-font-family);
  font-size: var(--XL-body-BM-bold-font-size);
  font-style: var(--XL-body-BM-bold-font-style);
  font-weight: var(--XL-body-BM-bold-font-weight);
  letter-spacing: var(--XL-body-BM-bold-letter-spacing);
  line-height: var(--XL-body-BM-bold-line-height);
  margin-top: -1px;
  position: relative;
}

.landing-page-XL .en-rent-onchain-tu {
  align-self: stretch;
  color: transparent;
  font-family: var(--XL-body-BM-semibold-font-family);
  font-size: var(--XL-body-BM-semibold-font-size);
  font-style: var(--XL-body-BM-semibold-font-style);
  font-weight: var(--XL-body-BM-semibold-font-weight);
  letter-spacing: var(--XL-body-BM-semibold-letter-spacing);
  line-height: var(--XL-body-BM-semibold-line-height);
  position: relative;
}

.landing-page-XL .text-wrapper-24 {
  color: #ffffff;
  font-family: var(--XL-body-BM-semibold-font-family);
  font-size: var(--XL-body-BM-semibold-font-size);
  font-style: var(--XL-body-BM-semibold-font-style);
  font-weight: var(--XL-body-BM-semibold-font-weight);
  letter-spacing: var(--XL-body-BM-semibold-letter-spacing);
  line-height: var(--XL-body-BM-semibold-line-height);
}

.landing-page-XL .text-wrapper-25 {
  color: #7f6eed;
  font-family: var(--XL-body-BM-semibold-font-family);
  font-size: var(--XL-body-BM-semibold-font-size);
  font-style: var(--XL-body-BM-semibold-font-style);
  font-weight: var(--XL-body-BM-semibold-font-weight);
  letter-spacing: var(--XL-body-BM-semibold-letter-spacing);
  line-height: var(--XL-body-BM-semibold-line-height);
}

.landing-page-XL .frame-50 {
  align-items: flex-start;
  background-color: var(--white);
  border-radius: 12px;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: var(--spacings-spacing-lg);
  justify-content: center;
  padding: var(--spacings-spacing-lg);
  position: relative;
  width: 822px;
}

.landing-page-XL .frame-51 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: var(--spacings-spacing-md);
  justify-content: center;
  position: relative;
  width: 100%;
}

.landing-page-XL .frame-52 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: var(--spacings-spacing-md);
  position: relative;
  width: 100%;
}

.landing-page-XL .design-component-instance-node-2 {
  flex: 1 !important;
  flex-grow: 1 !important;
  width: unset !important;
}

.landing-page-XL .frame-53 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: var(--spacings-spacing-sm);
  position: relative;
}

.landing-page-XL .icon-instance-node-2 {
  height: 32px !important;
  position: relative !important;
  width: 32px !important;
}

.landing-page-XL .frame-54 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: var(--spacings-spacing-sm);
  position: relative;
}

.landing-page-XL .text-wrapper-26 {
  color: var(--black);
  font-family: var(--XL-small-text-ST-l-bold-font-family);
  font-size: var(--XL-small-text-ST-l-bold-font-size);
  font-style: var(--XL-small-text-ST-l-bold-font-style);
  font-weight: var(--XL-small-text-ST-l-bold-font-weight);
  letter-spacing: var(--XL-small-text-ST-l-bold-letter-spacing);
  line-height: var(--XL-small-text-ST-l-bold-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.landing-page-XL .text-wrapper-27 {
  color: var(--black);
  font-family: var(--XL-small-text-ST-l-med-font-family);
  font-size: var(--XL-small-text-ST-l-med-font-size);
  font-style: var(--XL-small-text-ST-l-med-font-style);
  font-weight: var(--XL-small-text-ST-l-med-font-weight);
  letter-spacing: var(--XL-small-text-ST-l-med-letter-spacing);
  line-height: var(--XL-small-text-ST-l-med-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.landing-page-XL .button-5 {
  align-self: stretch !important;
  width: 100% !important;
}

.landing-page-XL .button-6 {
  flex: 1 !important;
  width: unset !important;
}

.landing-page-XL .frame-55 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 200px;
  position: relative;
  width: 100%;
}

.landing-page-XL .card {
  align-items: flex-start;
  background-color: var(--white);
  border-radius: 12px;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 10px;
  overflow: hidden;
  padding: 38px;
  position: relative;
}

.landing-page-XL .frame-56 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 16px;
  position: relative;
  width: 100%;
}

.landing-page-XL .frame-57 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 1px;
  position: relative;
  width: 100%;
}

.landing-page-XL .text-wrapper-28 {
  color: var(--black);
  font-family: var(--XL-body-BL-semibold-font-family);
  font-size: var(--XL-body-BL-semibold-font-size);
  font-style: var(--XL-body-BL-semibold-font-style);
  font-weight: var(--XL-body-BL-semibold-font-weight);
  letter-spacing: var(--XL-body-BL-semibold-letter-spacing);
  line-height: var(--XL-body-BL-semibold-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.landing-page-XL .text-wrapper-29 {
  color: var(--black);
  flex: 1;
  font-family: var(--XL-body-BL-semibold-font-family);
  font-size: var(--XL-body-BL-semibold-font-size);
  font-style: var(--XL-body-BL-semibold-font-style);
  font-weight: var(--XL-body-BL-semibold-font-weight);
  letter-spacing: var(--XL-body-BL-semibold-letter-spacing);
  line-height: var(--XL-body-BL-semibold-line-height);
  margin-top: -1px;
  position: relative;
}

.landing-page-XL .div-13 {
  color: transparent;
  flex: 1;
  font-family: var(--XL-body-BL-semibold-font-family);
  font-size: var(--XL-body-BL-semibold-font-size);
  font-style: var(--XL-body-BL-semibold-font-style);
  font-weight: var(--XL-body-BL-semibold-font-weight);
  letter-spacing: var(--XL-body-BL-semibold-letter-spacing);
  line-height: var(--XL-body-BL-semibold-line-height);
  margin-top: -1px;
  position: relative;
}

.landing-page-XL .text-wrapper-30 {
  color: #1a1a1c;
  font-family: var(--XL-body-BL-semibold-font-family);
  font-size: var(--XL-body-BL-semibold-font-size);
  font-style: var(--XL-body-BL-semibold-font-style);
  font-weight: var(--XL-body-BL-semibold-font-weight);
  letter-spacing: var(--XL-body-BL-semibold-letter-spacing);
  line-height: var(--XL-body-BL-semibold-line-height);
}

.landing-page-XL .text-wrapper-31 {
  color: #7a67f2;
  font-family: var(--XL-body-BL-semibold-font-family);
  font-size: var(--XL-body-BL-semibold-font-size);
  font-style: var(--XL-body-BL-semibold-font-style);
  font-weight: var(--XL-body-BL-semibold-font-weight);
  letter-spacing: var(--XL-body-BL-semibold-letter-spacing);
  line-height: var(--XL-body-BL-semibold-line-height);
}

.landing-page-XL .frame-58 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: var(--spacings-spacing-lg);
  position: relative;
  width: 100%;
}

.landing-page-XL .frame-59 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 32px;
  position: relative;
  width: 100%;
}

.landing-page-XL .frame-60 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: var(--spacings-spacing-xxl);
  position: relative;
  width: 100%;
}

.landing-page-XL .disponibilidad {
  color: var(--gray);
  font-family: var(--XL-title-TL-font-family);
  font-size: var(--XL-title-TL-font-size);
  font-style: var(--XL-title-TL-font-style);
  font-weight: var(--XL-title-TL-font-weight);
  letter-spacing: var(--XL-title-TL-letter-spacing);
  line-height: var(--XL-title-TL-line-height);
  margin-top: -1px;
  position: relative;
  width: 418px;
}

.landing-page-XL .real-estate-card-landing-desktop {
  background-image: url(../../../static/img/rectangle-260.png) !important;
}

.landing-page-XL .real-estate-card-instance {
  color: #1a1a1c !important;
}

.landing-page-XL .real-estate-card-landing-desktop-instance {
  width: 114px !important;
}

.landing-page-XL .real-estate-card-2 {
  width: 112px !important;
}

.landing-page-XL .real-estate-card-3 {
  background-image: url(../../../static/img/rectangle-260-1.png) !important;
}

.landing-page-XL .real-estate-card-4 {
  background-image: url(../../../static/img/rectangle-260-2.png) !important;
}

.landing-page-XL .real-estate-card-5 {
  width: 109px !important;
}

.landing-page-XL .real-estate-card-6 {
  width: 107px !important;
}

.landing-page-XL .real-estate-card-7 {
  background-image: url(../../../static/img/rectangle-260-3.png) !important;
}

.landing-page-XL .real-estate-card-8 {
  width: 121px !important;
}

.landing-page-XL .real-estate-card-9 {
  width: 119px !important;
}

.landing-page-XL .real-estate-card-10 {
  background-image: url(../../../static/img/rectangle-260-4.png) !important;
}

.landing-page-XL .real-estate-card-11 {
  width: 122px !important;
}

.landing-page-XL .real-estate-card-12 {
  width: 120px !important;
}

.landing-page-XL .real-estate-card-13 {
  background-image: url(../../../static/img/rectangle-260-5.png) !important;
}

.landing-page-XL .frame-61 {
  align-items: flex-start;
  background-color: var(--white);
  border-radius: 12px;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: var(--spacings-spacing-lg);
  padding: var(--spacings-spacing-lg);
  position: relative;
}

.landing-page-XL .frame-62 {
  align-items: center;
  display: flex;
  gap: var(--spacings-spacing-lg);
  height: 70px;
  position: relative;
  width: 1392px;
}

.landing-page-XL .frame-63 {
  align-items: center;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: var(--spacings-spacing-md);
  position: relative;
}

.landing-page-XL .button-7 {
  width: 260px !important;
}

.landing-page-XL .frame-64 {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 40px;
  height: 906px;
  position: relative;
}

.landing-page-XL .unsplash-loiwhnwrq {
  align-self: stretch;
  flex: 1;
  flex-grow: 1;
  position: relative;
  width: 100%;
}

.landing-page-XL .frame-65 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacings-spacing-lg);
  position: relative;
  width: 822px;
}

.landing-page-XL .card-mini-desktop-instance {
  background-color: var(--violet) !important;
  gap: var(--spacings-spacing-lg) !important;
  /* height: 200px !important; */
  align-self: stretch;
  padding: var(--spacings-spacing-lg) !important;
  width: 380px !important;
}

.landing-page-XL .card-mini-desktop-2 {
  gap: var(--spacings-spacing-md) !important;
}

.landing-page-XL .card-mini-desktop-3 {
  color: var(--white) !important;
}

.landing-page-XL .card-mini-desktop-4 {
  background-color: var(--white) !important;
  gap: var(--spacings-spacing-lg) !important;
  padding: var(--spacings-spacing-lg) !important;
  width: 418px !important;
  align-self: stretch;
}

.landing-page-XL .card-mini-desktop-5 {
  flex: 1 !important;
  white-space: unset !important;
  width: unset !important;
}

.landing-page-XL .card-mini-desktop-6 {
  background-color: var(--white) !important;
  gap: var(--spacings-spacing-lg) !important;
  padding: var(--spacings-spacing-lg) !important;
  width: 380px !important;
  align-self: stretch;
}

.landing-page-XL .card-mini-desktop-7 {
  background-color: var(--blue) !important;
  gap: var(--spacings-spacing-lg) !important;
  padding: var(--spacings-spacing-lg) !important;
  width: 418px !important;
  align-self: stretch;
}

.landing-page-XL .card-mini-desktop-8 {
  color: var(--white) !important;
  flex: 1 !important;
  white-space: unset !important;
  width: unset !important;
}

.landing-page-XL .card-mini-desktop-9 {
  background-color: var(--orange) !important;
  gap: var(--spacings-spacing-lg) !important;
  padding: var(--spacings-spacing-lg) !important;
  width: 380px !important;
  align-self: stretch;
}

.landing-page-XL .card-mini-desktop-10 {
  background-color: var(--white) !important;
  gap: var(--spacings-spacing-lg) !important;
  /* height: 234px !important; */
  align-self: stretch;
  padding: var(--spacings-spacing-lg) !important;
  width: 418px !important;
}

.landing-page-XL .card-mini-desktop-11 {
  gap: var(--spacings-spacing-lg) !important;
  /* height: 200px !important; */
  align-self: stretch;
  padding: var(--spacings-spacing-lg) !important;
  width: 418px !important;
}

.landing-page-XL .frame-66 {
  align-items: flex-end;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: var(--spacings-spacing-none);
  position: relative;
}

.landing-page-XL .frame-67 {
  align-items: flex-end;
  display: flex;
  flex: 0 0 auto;
  gap: 200px;
  position: relative;
  width: 1440px;
}

.landing-page-XL .player-instance-wrapper {
  width: 100%;
}

.player-instance-wrapper iframe {
  width: 100%;
  height: 720px;
  border-radius: 18px;
}

.landing-page-XL .player-2 {
  left: 670px !important;
  top: 310px !important;
}

.landing-page-XL .frame-68 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  flex-wrap: wrap;
  gap: 24px 24px;
  position: relative;
  width: 1440px;
}

.landing-page-XL .rectangle-4 {
  height: 268px;
  object-fit: cover;
  position: relative;
  width: 464px;
}

.landing-page-XL .card-mini-desktop-12 {
  background-color: var(--white) !important;
  gap: var(--spacings-spacing-lg) !important;
  height: 268px !important;
  overflow: unset !important;
  padding: var(--spacings-spacing-lg) !important;
  width: 464px !important;
}

.landing-page-XL .card-mini-desktop-13 {
  background-color: var(--violet) !important;
  gap: var(--spacings-spacing-lg) !important;
  height: 268px !important;
  overflow: unset !important;
  padding: var(--spacings-spacing-lg) !important;
  width: 464px !important;
}

.landing-page-XL .card-mini-desktop-14 {
  background-color: var(--violet) !important;
  gap: var(--spacings-spacing-lg) !important;
  overflow: unset !important;
  padding: var(--spacings-spacing-lg) !important;
  width: 464px !important;
  align-self: stretch;
}

.landing-page-XL .card-mini-desktop-15 {
  background-color: var(--white) !important;
  gap: var(--spacings-spacing-lg) !important;
  overflow: unset !important;
  padding: var(--spacings-spacing-lg) !important;
  width: 464px !important;
}

.landing-page-XL .frame-wrapper-2 {
  width: 100%;
}

.landing-page-XL .frame-69 {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  position: relative;
  width: 418px;
  cursor: pointer;
}

.landing-page-XL .arrow-chevron-right {
  height: 24px !important;
  margin-left: -24px !important;
  position: relative !important;
  width: 24px !important;
}

.arrow-rotate {
  transform: rotate(90deg);
}

.landing-page-XL .frame-70 {
  align-items: flex-start;
  background-color: var(--white);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: var(--spacings-spacing-lg);
  padding: var(--spacings-spacing-lg);
  position: relative;
  width: 822px;
}

.landing-page-XL .frame-71 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: var(--spacings-spacing-md);
  position: relative;
  width: 100%;
}

.landing-page-XL .text-wrapper-32 {
  align-self: stretch;
  color: var(--black);
  font-family: var(--XL-body-BL-bold-font-family);
  font-size: var(--XL-body-BL-bold-font-size);
  font-style: var(--XL-body-BL-bold-font-style);
  font-weight: var(--XL-body-BL-bold-font-weight);
  letter-spacing: var(--XL-body-BL-bold-letter-spacing);
  line-height: var(--XL-body-BL-bold-line-height);
  margin-top: -1px;
  position: relative;
}

.landing-page-XL .timer-instance {
  align-self: stretch !important;
  background-color: var(--violet-dark) !important;
  flex: 0 0 auto !important;
  width: 100% !important;
}

.landing-page-XL .timer-2 {
  color: #ffffff !important;
}

.landing-page-XL .timer-3 {
  color: #cac2fa !important;
}

.landing-page-XL .timer-4 {
  background-color: var(--lavender) !important;
}

.landing-page-XL .frame-72 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: var(--spacings-spacing-sm);
  position: relative;
  width: 100%;
}

.landing-page-XL .text-wrapper-33 {
  align-self: stretch;
  color: var(--black);
  font-family: var(--XL-body-BM-bold-font-family);
  font-size: var(--XL-body-BM-bold-font-size);
  font-style: var(--XL-body-BM-bold-font-style);
  font-weight: var(--XL-body-BM-bold-font-weight);
  letter-spacing: var(--XL-body-BM-bold-letter-spacing);
  line-height: var(--XL-body-BM-bold-line-height);
  margin-top: -1px;
  position: relative;
}

.landing-page-XL .text-wrapper-34 {
  align-self: stretch;
  color: var(--black);
  font-family: var(--XL-body-BM-semibold-font-family);
  font-size: var(--XL-body-BM-semibold-font-size);
  font-style: var(--XL-body-BM-semibold-font-style);
  font-weight: var(--XL-body-BM-semibold-font-weight);
  letter-spacing: var(--XL-body-BM-semibold-letter-spacing);
  line-height: var(--XL-body-BM-semibold-line-height);
  position: relative;
}

.landing-page-XL .input-instance {
  align-self: stretch !important;
  flex: 0 0 auto !important;
  width: 100% !important;
}

.landing-page-XL .text-wrapper-35 {
  align-self: stretch;
  color: var(--violet-dark);
  font-family: var(--XL-body-BS-semibold-font-family);
  font-size: var(--XL-body-BS-semibold-font-size);
  font-style: var(--XL-body-BS-semibold-font-style);
  font-weight: var(--XL-body-BS-semibold-font-weight);
  letter-spacing: var(--XL-body-BS-semibold-letter-spacing);
  line-height: var(--XL-body-BS-semibold-line-height);
  position: relative;
}

.fistForm {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

.video-wrapper {
  width: 100% !important;
  border-radius: 18px !important;
  height: 620px !important;
}
