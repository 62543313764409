@media screen and (min-width: 374px) and (max-width: 423px) {
  body {
    zoom: 1.02; /* Ajusta el zoom según tus necesidades */
  }
}

@media screen and (min-width: 424px) and (max-width: 473px) {
  body {
    zoom: 1.15; /* Ajusta el zoom según tus necesidades */
  }
}

@media screen and (min-width: 474px) and (max-width: 523px) {
  body {
    zoom: 1.3; /* Ajusta el zoom según tus necesidades */
  }
}

@media screen and (min-width: 524px) and (max-width: 573px) {
  body {
    zoom: 1.4; /* Ajusta el zoom según tus necesidades */
  }
}

@media screen and (min-width: 574px) and (max-width: 623px) {
  body {
    zoom: 1.5; /* Ajusta el zoom según tus necesidades */
  }
}

@media screen and (min-width: 624px) and (max-width: 673px) {
  body {
    zoom: 1.6; /* Ajusta el zoom según tus necesidades */
  }
}

@media screen and (min-width: 674px) and (max-width: 723px) {
  body {
    zoom: 1.7; /* Ajusta el zoom según tus necesidades */
  }
}

@media screen and (min-width: 724px) and (max-width: 773px) {
  body {
    zoom: 1.9; /* Ajusta el zoom según tus necesidades */
  }
}

@media screen and (min-width: 774px) and (max-width: 823px) {
  body {
    zoom: 2.1; /* Ajusta el zoom según tus necesidades */
  }
}

@media screen and (min-width: 824px) and (max-width: 873px) {
  body {
    zoom: 2.2; /* Ajusta el zoom según tus necesidades */
  }
}

@media screen and (min-width: 874px) and (max-width: 923px) {
  body {
    zoom: 2.4; /* Ajusta el zoom según tus necesidades */
  }
}

@media screen and (min-width: 924px) and (max-width: 973px) {
  body {
    zoom: 2.5; /* Ajusta el zoom según tus necesidades */
  }
}

@media screen and (min-width: 974px) and (max-width: 1024px) {
  body {
    zoom: 2.6; /* Ajusta el zoom según tus necesidades */
  }
}

.invisible {
  display: none !important;
}

a {
  cursor: pointer;
}

.landing-page-mobile {
  align-items: center;
  background-color: var(--black);
  display: flex;
  flex-direction: column;
  gap: var(--spacings-spacing-lg);
  position: relative;
}

.landing-page-mobile .menu-mobile {
  align-items: center;
  background-color: var(--black);
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: var(--dark-gray);
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  padding: 13px 16px;
  position: relative;
  width: 360px;
}

.landing-page-mobile .design-component-instance-node-3 {
  flex: 0 0 auto !important;
}

.landing-page-mobile .burger {
  height: 22px;
  margin-right: -1px;
  position: relative;
  width: 32px;
}

.landing-page-mobile .frame-73 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: var(--spacings-spacing-between-blocks-mobile);
  position: relative;
}

.landing-page-mobile .frame-74 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: var(--spacings-spacing-md);
  height: 553px;
  position: relative;
}

.landing-page-mobile .div-14 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: var(--spacings-spacing-xs);
  position: relative;
}

.landing-page-mobile .text-wrapper-36 {
  color: var(--white);
  font-family: var(--XS-headline-HL-font-family);
  font-size: var(--XS-headline-HL-font-size);
  font-style: var(--XS-headline-HL-font-style);
  font-weight: var(--XS-headline-HL-font-weight);
  letter-spacing: var(--XS-headline-HL-letter-spacing);
  line-height: var(--XS-headline-HL-line-height);
  margin-top: -1px;
  position: relative;
  width: 328px;
}

.landing-page-mobile .DISFRUTA-LA-VIDA-EN-2 {
  color: transparent;
  font-family: var(--XS-body-BL-bold-font-family);
  font-size: var(--XS-body-BL-bold-font-size);
  font-style: var(--XS-body-BL-bold-font-style);
  font-weight: var(--XS-body-BL-bold-font-weight);
  letter-spacing: var(--XS-body-BL-bold-letter-spacing);
  line-height: var(--XS-body-BL-bold-line-height);
  position: relative;
  width: 328px;
}

.landing-page-mobile .text-wrapper-37 {
  color: #ffffff;
  font-family: var(--XS-body-BL-bold-font-family);
  font-size: var(--XS-body-BL-bold-font-size);
  font-style: var(--XS-body-BL-bold-font-style);
  font-weight: var(--XS-body-BL-bold-font-weight);
  letter-spacing: var(--XS-body-BL-bold-letter-spacing);
  line-height: var(--XS-body-BL-bold-line-height);
}

.landing-page-mobile .text-wrapper-38 {
  color: #7a67f2;
  font-family: var(--XS-body-BL-bold-font-family);
  font-size: var(--XS-body-BL-bold-font-size);
  font-style: var(--XS-body-BL-bold-font-style);
  font-weight: var(--XS-body-BL-bold-font-weight);
  letter-spacing: var(--XS-body-BL-bold-letter-spacing);
  line-height: var(--XS-body-BL-bold-line-height);
}

.landing-page-mobile .frame-75 {
  height: 322px;
  position: relative;
  width: 328px;
  margin-bottom: 50px;
}

.frame-75 iframe {
  border-radius: 20px;
}

.landing-page-mobile .player-3 {
  left: 142px !important;
  top: 138px !important;
}

.landing-page-mobile .button-8 {
  flex: 1 !important;
  flex-grow: 1 !important;
  width: unset !important;
}

.landing-page-mobile .frame-76 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: var(--spacings-spacing-md);
  position: relative;
  width: 328px;
}

.landing-page-mobile .text-wrapper-39 {
  align-self: stretch;
  width: 328px;
  color: var(--white);
  font-family: var(--XS-headline-HM-font-family);
  font-size: var(--XS-headline-HM-font-size);
  font-style: var(--XS-headline-HM-font-style);
  font-weight: var(--XS-headline-HM-font-weight);
  letter-spacing: var(--XS-headline-HM-letter-spacing);
  line-height: var(--XS-headline-HM-line-height);
  margin-top: -1px;
  position: relative;
}

.landing-page-mobile .frame-77 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: var(--spacings-spacing-sm);
  position: relative;
}

.landing-page-mobile .alice-carousel {
  width: 360px;
}
.landing-page-mobile .alice-carousel__stage li {
  width: 360px !important;
}

.landing-page-mobile .alice-carousel__stage img {
  width: 320px;
  max-height: 410px;
  object-fit: contain;
}

.landing-page-mobile .text-wrapper-40 {
  color: var(--white);
  font-family: var(--XS-body-BL-bold-font-family);
  font-size: var(--XS-body-BL-bold-font-size);
  font-style: var(--XS-body-BL-bold-font-style);
  font-weight: var(--XS-body-BL-bold-font-weight);
  letter-spacing: var(--XS-body-BL-bold-letter-spacing);
  line-height: var(--XS-body-BL-bold-line-height);
  margin-top: -1px;
  position: relative;
  width: 328px;
}

.landing-page-mobile .text-wrapper-41 {
  color: var(--white);
  font-family: var(--XS-body-BL-semibold-font-family);
  font-size: var(--XS-body-BL-semibold-font-size);
  font-style: var(--XS-body-BL-semibold-font-style);
  font-weight: var(--XS-body-BL-semibold-font-weight);
  letter-spacing: var(--XS-body-BL-semibold-letter-spacing);
  line-height: var(--XS-body-BL-semibold-line-height);
  position: relative;
  width: 328px;
}

.landing-page-mobile .slider-2 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  gap: var(--spacings-spacing-sm);
  position: relative;
  width: 328px;
}

.landing-page-mobile .unsplash-2 {
  height: 279px;
  object-fit: cover;
  position: relative;
  width: 328px;
}

.landing-page-mobile .unsplash-3 {
  height: 279px;
  margin-right: -16px;
  object-fit: cover;
  position: relative;
  width: 8px;
}

.landing-page-mobile .unsplash-4 {
  height: 279px;
  margin-bottom: -4472px;
  margin-left: -55050px;
  object-fit: cover;
  position: relative;
  width: 328px;
}

.landing-page-mobile .unsplash-5 {
  height: 279px;
  margin-bottom: -4472px;
  margin-left: -54714px;
  object-fit: cover;
  position: relative;
  width: 328px;
}

.landing-page-mobile .unsplash-6 {
  height: 279px;
  margin-bottom: -4472px;
  margin-left: -54378px;
  position: relative;
  width: 328px;
}

.landing-page-mobile .unsplash-7 {
  height: 279px;
  margin-bottom: -4472px;
  margin-left: -54042px;
  object-fit: cover;
  position: relative;
  width: 328px;
}

.landing-page-mobile .slider-3 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  margin-right: -16px;
  padding: 0px 136px;
  position: relative;
}

.landing-page-mobile .ellipse-3 {
  background-color: var(--white);
  border-radius: 4px;
  height: 8px;
  position: relative;
  width: 8px;
}

.landing-page-mobile .ellipse-4 {
  background-color: var(--dark-gray);
  border-radius: 4px;
  height: 8px;
  position: relative;
  width: 8px;
}

.landing-page-mobile .frame-78 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: var(--spacings-spacing-md);
  position: relative;
}

.landing-page-mobile .en-rent-onchain-tu-2 {
  color: transparent;
  font-family: var(--XS-body-BL-semibold-font-family);
  font-size: var(--XS-body-BL-semibold-font-size);
  font-style: var(--XS-body-BL-semibold-font-style);
  font-weight: var(--XS-body-BL-semibold-font-weight);
  letter-spacing: var(--XS-body-BL-semibold-letter-spacing);
  line-height: var(--XS-body-BL-semibold-line-height);
  position: relative;
  width: 328px;
}

.landing-page-mobile .text-wrapper-42 {
  color: #ffffff;
  font-family: var(--XS-body-BL-semibold-font-family);
  font-size: var(--XS-body-BL-semibold-font-size);
  font-style: var(--XS-body-BL-semibold-font-style);
  font-weight: var(--XS-body-BL-semibold-font-weight);
  letter-spacing: var(--XS-body-BL-semibold-letter-spacing);
  line-height: var(--XS-body-BL-semibold-line-height);
}

.landing-page-mobile .text-wrapper-43 {
  color: #7a67f2;
  font-family: var(--XS-body-BL-semibold-font-family);
  font-size: var(--XS-body-BL-semibold-font-size);
  font-style: var(--XS-body-BL-semibold-font-style);
  font-weight: var(--XS-body-BL-semibold-font-weight);
  letter-spacing: var(--XS-body-BL-semibold-letter-spacing);
  line-height: var(--XS-body-BL-semibold-line-height);
}

.landing-page-mobile .frame-79 {
  align-items: flex-start;
  background-color: var(--white);
  border-radius: 8px;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: var(--spacings-spacing-md);
  padding: var(--spacings-spacing-md);
  position: relative;
  width: 328px;
}

.landing-page-mobile .frame-80 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 8px;
  position: relative;
  width: 100%;
}

.landing-page-mobile .design-component-instance-node-4 {
  align-self: stretch !important;
  flex: 0 0 auto !important;
  width: 100% !important;
}

.landing-page-mobile .frame-81 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: var(--spacings-spacing-xs);
  position: relative;
}

.landing-page-mobile .frame-82 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: var(--spacings-spacing-xxs);
  position: relative;
}

.landing-page-mobile .text-wrapper-44 {
  color: var(--black);
  font-family: var(--XS-small-text-ST-l-bold-font-family);
  font-size: var(--XS-small-text-ST-l-bold-font-size);
  font-style: var(--XS-small-text-ST-l-bold-font-style);
  font-weight: var(--XS-small-text-ST-l-bold-font-weight);
  letter-spacing: var(--XS-small-text-ST-l-bold-letter-spacing);
  line-height: var(--XS-small-text-ST-l-bold-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.landing-page-mobile .text-wrapper-45 {
  color: var(--black);
  font-family: var(--XS-small-text-ST-l-medium-font-family);
  font-size: var(--XS-small-text-ST-l-medium-font-size);
  font-style: var(--XS-small-text-ST-l-medium-font-style);
  font-weight: var(--XS-small-text-ST-l-medium-font-weight);
  letter-spacing: var(--XS-small-text-ST-l-medium-letter-spacing);
  line-height: var(--XS-small-text-ST-l-medium-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.landing-page-mobile .frame-83 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: var(--spacings-spacing-lg);
  position: relative;
}

.landing-page-mobile .card-2 {
  align-items: flex-start;
  background-color: var(--white);
  border-radius: 8px;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10px;
  overflow: hidden;
  padding: 16px;
  position: relative;
}

.landing-page-mobile .frame-84 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 8px;
  position: relative;
  width: 296px;
}

.landing-page-mobile .frame-85 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 1px;
  position: relative;
  width: 100%;
}

.landing-page-mobile .text-wrapper-46 {
  color: var(--black);
  font-family: var(--XS-body-BM-semibold-font-family);
  font-size: var(--XS-body-BM-semibold-font-size);
  font-style: var(--XS-body-BM-semibold-font-style);
  font-weight: var(--XS-body-BM-semibold-font-weight);
  letter-spacing: var(--XS-body-BM-semibold-letter-spacing);
  line-height: var(--XS-body-BM-semibold-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.landing-page-mobile .text-wrapper-47 {
  color: var(--black);
  flex: 1;
  font-family: var(--XS-body-BM-semibold-font-family);
  font-size: var(--XS-body-BM-semibold-font-size);
  font-style: var(--XS-body-BM-semibold-font-style);
  font-weight: var(--XS-body-BM-semibold-font-weight);
  letter-spacing: var(--XS-body-BM-semibold-letter-spacing);
  line-height: var(--XS-body-BM-semibold-line-height);
  margin-top: -1px;
  position: relative;
}

.landing-page-mobile .div-15 {
  color: transparent;
  flex: 1;
  font-family: var(--XS-body-BM-semibold-font-family);
  font-size: var(--XS-body-BM-semibold-font-size);
  font-style: var(--XS-body-BM-semibold-font-style);
  font-weight: var(--XS-body-BM-semibold-font-weight);
  letter-spacing: var(--XS-body-BM-semibold-letter-spacing);
  line-height: var(--XS-body-BM-semibold-line-height);
  margin-top: -1px;
  position: relative;
}

.landing-page-mobile .text-wrapper-48 {
  color: #1a1a1c;
  font-family: var(--XS-body-BM-semibold-font-family);
  font-size: var(--XS-body-BM-semibold-font-size);
  font-style: var(--XS-body-BM-semibold-font-style);
  font-weight: var(--XS-body-BM-semibold-font-weight);
  letter-spacing: var(--XS-body-BM-semibold-letter-spacing);
  line-height: var(--XS-body-BM-semibold-line-height);
}

.landing-page-mobile .text-wrapper-49 {
  color: #7a67f2;
  font-family: var(--XS-body-BM-semibold-font-family);
  font-size: var(--XS-body-BM-semibold-font-size);
  font-style: var(--XS-body-BM-semibold-font-style);
  font-weight: var(--XS-body-BM-semibold-font-weight);
  letter-spacing: var(--XS-body-BM-semibold-letter-spacing);
  line-height: var(--XS-body-BM-semibold-line-height);
}

.landing-page-mobile .frame-86 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: var(--spacings-spacing-lg);
  position: relative;
  width: 328px;
}

.landing-page-mobile .text-wrapper-50 {
  color: var(--white);
  font-family: var(--XS-headline-HM-font-family);
  font-size: var(--XS-headline-HM-font-size);
  font-style: var(--XS-headline-HM-font-style);
  font-weight: var(--XS-headline-HM-font-weight);
  letter-spacing: var(--XS-headline-HM-letter-spacing);
  line-height: var(--XS-headline-HM-line-height);
  margin-top: -1px;
  position: relative;
  width: 328px;
}

.landing-page-mobile .disponibilidad-2 {
  color: var(--gray);
  font-family: var(--XS-headline-HS-font-family);
  font-size: var(--XS-headline-HS-font-size);
  font-style: var(--XS-headline-HS-font-style);
  font-weight: var(--XS-headline-HS-font-weight);
  letter-spacing: var(--XS-headline-HS-letter-spacing);
  line-height: var(--XS-headline-HS-line-height);
  margin-top: -1px;
  position: relative;
  width: 328px;
}

.landing-page-mobile .real-estate-card-14 {
  align-items: flex-start;
  background-color: var(--white);
  border-radius: 8px;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: var(--spacings-spacing-md);
  overflow: hidden;
  padding: var(--spacings-spacing-md);
  position: relative;
  width: 328px;
}

.landing-page-mobile .slider-wrapper {
  align-self: stretch;
  background-image: url(../../../static/img/rectangle-260-7.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 254px;
  position: relative;
  width: 100%;
}

.landing-page-mobile .slider-4 {
  align-items: center;
  display: flex;
  gap: var(--spacings-spacing-xs);
  justify-content: center;
  position: relative;
  top: 239px;
  width: 296px;
}

/* .landing-page-mobile .ellipse-5 {
  background-color: var(--white);
  border-radius: 3px;
  height: 6px;
  position: relative;
  width: 6px;
}

.landing-page-mobile .ellipse-6 {
  background-color: var(--white);
  border-radius: 3px;
  height: 6px;
  opacity: 0.5;
  position: relative;
  width: 6px;
} */

.landing-page-mobile .frame-87 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: var(--spacings-spacing-sm);
  justify-content: center;
  position: relative;
  width: 100%;
}

.landing-page-mobile .frame-88 {
  align-items: flex-end;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: var(--spacings-spacing-xxs);
  justify-content: center;
  position: relative;
  width: 100%;
}

.landing-page-mobile .frame-89 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  /* position: relative; */
  width: 100%;
}

.landing-page-mobile .TULUM-2 {
  color: var(--black);
  font-family: var(--XS-headline-HS-font-family);
  font-size: var(--XS-headline-HS-font-size);
  font-style: var(--XS-headline-HS-font-style);
  font-weight: var(--XS-headline-HS-font-weight);
  letter-spacing: var(--XS-headline-HS-letter-spacing);
  line-height: var(--XS-headline-HS-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.landing-page-mobile .group-wrapper {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: var(--spacings-spacing-sm);
  position: relative;
}

.landing-page-mobile .overlap-group-wrapper {
  height: 17px;
  margin-right: -2px;
  position: relative;
  width: 58px;
}

.landing-page-mobile .overlap-group-2 {
  height: 17px;
  position: relative;
  width: 56px;
}

.landing-page-mobile .text-wrapper-51 {
  color: var(--gray);
  font-family: var(--XS-caps-CM-bold-font-family);
  font-size: var(--XS-caps-CM-bold-font-size);
  font-style: var(--XS-caps-CM-bold-font-style);
  font-weight: var(--XS-caps-CM-bold-font-weight);
  left: 0;
  letter-spacing: var(--XS-caps-CM-bold-letter-spacing);
  line-height: var(--XS-caps-CM-bold-line-height);
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.landing-page-mobile .line-7 {
  height: 1px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 7px;
  width: 56px;
}

.landing-page-mobile .text-wrapper-52 {
  color: var(--violet-dark);
  font-family: var(--XS-headline-HS-font-family);
  font-size: var(--XS-headline-HS-font-size);
  font-style: var(--XS-headline-HS-font-style);
  font-weight: var(--XS-headline-HS-font-weight);
  letter-spacing: var(--XS-headline-HS-letter-spacing);
  line-height: var(--XS-headline-HS-line-height);
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.landing-page-mobile .frame-90 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.landing-page-mobile .text-wrapper-53 {
  color: var(--black);
  font-family: var(--XS-small-text-ST-l-reg-font-family);
  font-size: var(--XS-small-text-ST-l-reg-font-size);
  font-style: var(--XS-small-text-ST-l-reg-font-style);
  font-weight: var(--XS-small-text-ST-l-reg-font-weight);
  letter-spacing: var(--XS-small-text-ST-l-reg-letter-spacing);
  line-height: var(--XS-small-text-ST-l-reg-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.landing-page-mobile .la-fotograf-a-puede-2 {
  align-self: stretch;
  color: var(--dark-gray);
  font-family: var(--XS-small-text-ST-l-reg-font-family);
  font-size: var(--XS-small-text-ST-l-reg-font-size);
  font-style: var(--XS-small-text-ST-l-reg-font-style);
  font-weight: var(--XS-small-text-ST-l-reg-font-weight);
  letter-spacing: var(--XS-small-text-ST-l-reg-letter-spacing);
  line-height: var(--XS-small-text-ST-l-reg-line-height);
  position: relative;
}

.landing-page-mobile .frame-91 {
  align-self: stretch;
  background-image: url(../../../static/img/rectangle-260-8.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 254px;
  position: relative;
  width: 100%;
}

.landing-page-mobile .group-2 {
  height: 17px;
  margin-right: -2px;
  position: relative;
  width: 63px;
}

.landing-page-mobile .overlap-group-3 {
  height: 17px;
  position: relative;
  width: 61px;
}

.landing-page-mobile .frame-92 {
  align-self: stretch;
  background-image: url(../../../static/img/rectangle-260-9.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 254px;
  position: relative;
  width: 100%;
}

.landing-page-mobile .group-3 {
  height: 17px;
  margin-right: -2px;
  position: relative;
  width: 60px;
}

.landing-page-mobile .overlap-group-4 {
  height: 17px;
  position: relative;
  width: 58px;
}

.landing-page-mobile .frame-93 {
  align-self: stretch;
  background-image: url(../../../static/img/rectangle-260-10.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 254px;
  position: relative;
  width: 100%;
}

.landing-page-mobile .group-4 {
  height: 17px;
  margin-right: -2px;
  position: relative;
  width: 66px;
}

.landing-page-mobile .overlap-group-5 {
  height: 17px;
  position: relative;
  width: 64px;
}

.landing-page-mobile .interface {
  height: 24px !important;
  position: relative !important;
  width: 24px !important;
}

.icon-rotate {
  transform: rotate(45deg);
}

.landing-page-mobile .img-3 {
  height: 22px;
  position: relative;
  width: 22px;
}

.landing-page-mobile .frame-94 {
  align-self: stretch;
  background-image: url(../../../static/img/rectangle-260-11.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 254px;
  position: relative;
  width: 100%;
}

.landing-page-mobile .group-5 {
  height: 17px;
  margin-right: -2px;
  position: relative;
  width: 67px;
}

.landing-page-mobile .overlap-group-6 {
  height: 17px;
  position: relative;
  width: 65px;
}

.landing-page-mobile .frame-95 {
  align-self: stretch;
  background-image: url(../../../static/img/rectangle-260-12.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 254px;
  position: relative;
  width: 100%;
}

.landing-page-mobile .frame-96 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  flex-wrap: wrap;
  gap: var(--spacings-spacing-sm);
  position: relative;
  width: 328px;
}

.landing-page-mobile .card-middle-mobile {
  align-items: flex-start;
  background-color: var(--violet);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: var(--spacings-spacing-none);
  align-self: stretch;
  overflow: hidden;
  padding: var(--spacings-spacing-md);
  position: relative;
  width: 160px;
}

.landing-page-mobile .frame-97 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: var(--spacings-spacing-sm);
  position: relative;
  width: 100%;
}

.landing-page-mobile .frame-98 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 8px;
  position: relative;
  width: 100%;
}

.landing-page-mobile .visitaci-n {
  color: var(--white);
  flex: 1;
  font-family: var(--XS-title-TM-font-family);
  font-size: var(--XS-title-TM-font-size);
  font-style: var(--XS-title-TM-font-style);
  font-weight: var(--XS-title-TM-font-weight);
  letter-spacing: var(--XS-title-TM-letter-spacing);
  line-height: var(--XS-title-TM-line-height);
  position: relative;
}

.landing-page-mobile .design-component-instance-node-5 {
  height: 16px !important;
  left: unset !important;
  position: relative !important;
  top: unset !important;
  width: 16px !important;
}

.landing-page-mobile .text-wrapper-54 {
  align-self: stretch;
  color: var(--white);
  font-family: var(--XS-body-BM-semibold-font-family);
  font-size: var(--XS-body-BM-semibold-font-size);
  font-style: var(--XS-body-BM-semibold-font-style);
  font-weight: var(--XS-body-BM-semibold-font-weight);
  letter-spacing: var(--XS-body-BM-semibold-letter-spacing);
  line-height: var(--XS-body-BM-semibold-line-height);
  position: relative;
}

.landing-page-mobile .card-middle-mobile-2 {
  align-items: flex-start;
  background-color: var(--white);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: var(--spacings-spacing-none);
  overflow: hidden;
  padding: var(--spacings-spacing-md);
  position: relative;
  width: 160px;
}

.landing-page-mobile .frame-99 {
  align-items: flex-end;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 8px;
  position: relative;
  width: 100%;
}

.landing-page-mobile .visitaci-n-2 {
  color: var(--black);
  flex: 1;
  font-family: var(--XS-title-TM-font-family);
  font-size: var(--XS-title-TM-font-size);
  font-style: var(--XS-title-TM-font-style);
  font-weight: var(--XS-title-TM-font-weight);
  letter-spacing: var(--XS-title-TM-letter-spacing);
  line-height: var(--XS-title-TM-line-height);
  position: relative;
}

.landing-page-mobile .text-wrapper-55 {
  align-self: stretch;
  color: var(--black);
  font-family: var(--XS-body-BM-semibold-font-family);
  font-size: var(--XS-body-BM-semibold-font-size);
  font-style: var(--XS-body-BM-semibold-font-style);
  font-weight: var(--XS-body-BM-semibold-font-weight);
  letter-spacing: var(--XS-body-BM-semibold-letter-spacing);
  line-height: var(--XS-body-BM-semibold-line-height);
  position: relative;
}

.landing-page-mobile .card-middle-mobile-3 {
  align-items: flex-start;
  background-color: var(--white);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: var(--spacings-spacing-none);
  align-self: stretch;
  overflow: hidden;
  padding: var(--spacings-spacing-md);
  position: relative;
  width: 160px;
}

.landing-page-mobile .card-middle-mobile-4 {
  align-items: flex-start;
  background-color: var(--blue);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: var(--spacings-spacing-none);
  overflow: hidden;
  padding: var(--spacings-spacing-md);
  position: relative;
  width: 160px;
}

.landing-page-mobile .card-middle-mobile-5 {
  align-items: flex-start;
  background-color: var(--orange);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: var(--spacings-spacing-none);
  overflow: hidden;
  padding: var(--spacings-spacing-md);
  position: relative;
  width: 160px;
}

.landing-page-mobile .card-middle-mobile-6 {
  align-items: flex-start;
  background-color: var(--white);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: var(--spacings-spacing-none);
  align-self: stretch;
  overflow: hidden;
  padding: var(--spacings-spacing-md);
  position: relative;
  width: 160px;
}

.landing-page-mobile .frame-100 {
  align-items: flex-end;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.landing-page-mobile .visitaci-n-3 {
  color: var(--black);
  font-family: var(--XS-title-TM-font-family);
  font-size: var(--XS-title-TM-font-size);
  font-style: var(--XS-title-TM-font-style);
  font-weight: var(--XS-title-TM-font-weight);
  letter-spacing: var(--XS-title-TM-letter-spacing);
  line-height: var(--XS-title-TM-line-height);
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.landing-page-mobile .card-middle-mobile-7 {
  align-items: flex-start;
  background-color: var(--green);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: var(--spacings-spacing-none);
  overflow: hidden;
  padding: var(--spacings-spacing-md);
  position: relative;
  width: 160px;
}

.landing-page-mobile .interface-star {
  height: 16px;
  position: relative;
  width: 16px;
}

.landing-page-mobile .text-wrapper-56 {
  color: var(--white);
  font-family: var(--XS-body-BL-bold-font-family);
  font-size: var(--XS-body-BL-bold-font-size);
  font-style: var(--XS-body-BL-bold-font-style);
  font-weight: var(--XS-body-BL-bold-font-weight);
  letter-spacing: var(--XS-body-BL-bold-letter-spacing);
  line-height: var(--XS-body-BL-bold-line-height);
  position: relative;
  width: 328px;
}

.landing-page-mobile .frame-101 {
  position: relative;
  width: 100%;
}

.frame-101 iframe {
  border-radius: 8px;
  width: 100%;
  height: 300px;
}

.landing-page-mobile .player-4 {
  left: 142px !important;
  top: 117px !important;
}

.landing-page-mobile .rectangle-5 {
  height: 210px;
  object-fit: cover;
  position: relative;
  width: 328px;
}

.landing-page-mobile .card-middle-mobile-8 {
  align-items: flex-start;
  background-color: var(--violet);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: var(--spacings-spacing-none);
  overflow: hidden;
  padding: var(--spacings-spacing-md);
  position: relative;
  width: 160px;
  align-self: stretch;
}

.landing-page-mobile .element-reg-strate {
  align-self: stretch;
  color: var(--white);
  font-family: var(--XS-title-TL-font-family);
  font-size: var(--XS-title-TL-font-size);
  font-style: var(--XS-title-TL-font-style);
  font-weight: var(--XS-title-TL-font-weight);
  letter-spacing: var(--XS-title-TL-letter-spacing);
  line-height: var(--XS-title-TL-line-height);
  margin-top: -1px;
  position: relative;
}

.landing-page-mobile .card-middle-mobile-9 {
  align-items: flex-start;
  background-color: #ffffff;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: var(--spacings-spacing-none);
  height: 183px;
  overflow: hidden;
  padding: var(--spacings-spacing-md);
  position: relative;
  width: 160px;
}

.landing-page-mobile .element-reg-strate-2 {
  align-self: stretch;
  color: #1a1a1c;
  font-family: var(--XS-title-TL-font-family);
  font-size: var(--XS-title-TL-font-size);
  font-style: var(--XS-title-TL-font-style);
  font-weight: var(--XS-title-TL-font-weight);
  letter-spacing: var(--XS-title-TL-letter-spacing);
  line-height: var(--XS-title-TL-line-height);
  margin-top: -1px;
  position: relative;
}

.landing-page-mobile .s-lo-necesitas-un {
  align-self: stretch;
  color: #1a1a1c;
  font-family: var(--XS-body-BM-semibold-font-family);
  font-size: var(--XS-body-BM-semibold-font-size);
  font-style: var(--XS-body-BM-semibold-font-style);
  font-weight: var(--XS-body-BM-semibold-font-weight);
  letter-spacing: var(--XS-body-BM-semibold-letter-spacing);
  line-height: var(--XS-body-BM-semibold-line-height);
  position: relative;
}

.landing-page-mobile .card-middle-mobile-10 {
  align-items: flex-start;
  background-color: #ffffff;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: var(--spacings-spacing-none);
  align-self: stretch;
  overflow: hidden;
  padding: var(--spacings-spacing-md);
  position: relative;
  width: 160px;
}

.landing-page-mobile .card-middle-mobile-11 {
  align-items: flex-start;
  background-color: #ffffff;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: var(--spacings-spacing-none);
  align-self: stretch;
  overflow: hidden;
  padding: var(--spacings-spacing-md);
  position: relative;
  width: 160px;
}

.landing-page-mobile .card-middle-mobile-12 {
  align-items: flex-start;
  background-color: #ffffff;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: var(--spacings-spacing-none);
  height: 237px;
  overflow: hidden;
  padding: var(--spacings-spacing-md);
  position: relative;
  width: 160px;
}

.landing-page-mobile .frame-102 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 16px;
  position: relative;
  width: 100%;
}

.landing-page-mobile .text-wrapper-57 {
  color: var(--white);
  flex: 1;
  font-family: var(--XS-headline-HM-font-family);
  font-size: var(--XS-headline-HM-font-size);
  font-style: var(--XS-headline-HM-font-style);
  font-weight: var(--XS-headline-HM-font-weight);
  letter-spacing: var(--XS-headline-HM-letter-spacing);
  line-height: var(--XS-headline-HM-line-height);
  margin-top: -1px;
  position: relative;
}

.landing-page-mobile .close-list {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 12px;
  position: relative;
  width: 100%;
}

.landing-page-mobile .line-8 {
  height: 1px;
  margin-top: -1px;
  object-fit: cover;
  position: relative;
  width: 328px;
}

.landing-page-mobile .text-wrapper-58 {
  color: var(--white);
  font-family: var(--XS-title-TL-font-family);
  font-size: var(--XS-title-TL-font-size);
  font-style: var(--XS-title-TL-font-style);
  font-weight: var(--XS-title-TL-font-weight);
  letter-spacing: var(--XS-title-TL-letter-spacing);
  line-height: var(--XS-title-TL-line-height);
  margin-top: -1px;
  position: relative;
  width: 191px;
}

.landing-page-mobile .close {
  background-image: url(../../../static/img/menu-close-lg-17.svg);
  background-size: 100% 100%;
  height: 17px;
  position: relative;
  width: 17px;
}

.landing-page-mobile .frame-103 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  flex-wrap: wrap;
  gap: var(--spacings-spacing-md);
  position: relative;
  width: 328px;
}

.landing-page-mobile .team-player-wrapper {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
}

.landing-page-mobile .rectangle-6 {
  height: 208px;
  position: relative;
  width: 155.7px;
}

.landing-page-mobile .frame-104 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: var(--spacings-spacing-none);
  position: relative;
  width: 156px;
}

.landing-page-mobile .frame-105 {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  position: relative;
  width: 156px;
}

.landing-page-mobile .name-surname {
  color: var(--white);
  font-family: var(--XS-title-TM-font-family);
  font-size: var(--XS-title-TM-font-size);
  font-style: var(--XS-title-TM-font-style);
  font-weight: var(--XS-title-TM-font-weight);
  letter-spacing: var(--XS-title-TM-letter-spacing);
  line-height: var(--XS-title-TM-line-height);
  margin-top: -1px;
  position: relative;
  width: 132px;
}

.landing-page-mobile .position {
  align-self: stretch;
  color: var(--white);
  font-family: var(--XS-small-text-ST-l-medium-font-family);
  font-size: var(--XS-small-text-ST-l-medium-font-size);
  font-style: var(--XS-small-text-ST-l-medium-font-style);
  font-weight: var(--XS-small-text-ST-l-medium-font-weight);
  letter-spacing: var(--XS-small-text-ST-l-medium-letter-spacing);
  line-height: var(--XS-small-text-ST-l-medium-line-height);
  position: relative;
}

.landing-page-mobile .from {
  height: 16px;
  position: relative;
  width: 156px;
}

.landing-page-mobile .text-wrapper-59 {
  color: var(--gray);
  font-family: var(--XS-small-text-ST-l-medium-font-family);
  font-size: var(--XS-small-text-ST-l-medium-font-size);
  font-style: var(--XS-small-text-ST-l-medium-font-style);
  font-weight: var(--XS-small-text-ST-l-medium-font-weight);
  left: 0;
  letter-spacing: var(--XS-small-text-ST-l-medium-letter-spacing);
  line-height: var(--XS-small-text-ST-l-medium-line-height);
  position: absolute;
  top: -1px;
  white-space: nowrap;
}

.landing-page-mobile .rectangle-7 {
  height: 208px;
  object-fit: cover;
  position: relative;
  width: 155.7px;
}

.landing-page-mobile .frame-106 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  position: relative;
  width: 156px;
}

.landing-page-mobile .line-9 {
  height: 1px;
  object-fit: cover;
  position: relative;
  width: 328px;
}

.landing-page-mobile .name-surname-2 {
  color: var(--white);
  font-family: "Syncopate", Helvetica;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: -1.2px;
  line-height: 14.4px;
  margin-top: -1px;
  position: relative;
  text-decoration: underline;
  width: 132px;
}

.landing-page-mobile .close-2 {
  background-image: url(../../../static/img/menu-close-lg-27.svg);
  background-size: 100% 100%;
  height: 17px;
  position: relative;
  width: 17px;
}

.landing-page-mobile .name-surname-3 {
  color: var(--white);
  font-family: var(--XS-title-TM-font-family);
  font-size: var(--XS-title-TM-font-size);
  font-style: var(--XS-title-TM-font-style);
  font-weight: var(--XS-title-TM-font-weight);
  letter-spacing: var(--XS-title-TM-letter-spacing);
  line-height: var(--XS-title-TM-line-height);
  position: relative;
  width: 132px;
}

.landing-page-mobile .frame-107 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 12px;
  margin-right: -1px;
  position: relative;
}

.landing-page-mobile .frame-109 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 128px;
  position: relative;
}

.landing-page-mobile .c-mo-es-el-proceso {
  color: var(--white);
  font-family: var(--XS-body-BM-bold-font-family);
  font-size: var(--XS-body-BM-bold-font-size);
  font-style: var(--XS-body-BM-bold-font-style);
  font-weight: var(--XS-body-BM-bold-font-weight);
  letter-spacing: var(--XS-body-BM-bold-letter-spacing);
  line-height: var(--XS-body-BM-bold-line-height);
  margin-top: -1px;
  position: relative;
  width: 183px;
}

.landing-page-mobile .plus {
  height: 18px;
  position: relative;
  width: 18px;
}

.plus-rotate {
  transform: rotate(45deg);
}

.landing-page-mobile .frame-110 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 120px;
  position: relative;
}

.landing-page-mobile .text-wrapper-60 {
  color: var(--white);
  font-family: var(--XS-body-BM-bold-font-family);
  font-size: var(--XS-body-BM-bold-font-size);
  font-style: var(--XS-body-BM-bold-font-style);
  font-weight: var(--XS-body-BM-bold-font-weight);
  letter-spacing: var(--XS-body-BM-bold-letter-spacing);
  line-height: var(--XS-body-BM-bold-line-height);
  margin-top: -1px;
  position: relative;
  width: 191px;
}

.landing-page-mobile .frame-111 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 113px;
  position: relative;
}

.landing-page-mobile .c-mo-declarar-los {
  color: var(--white);
  font-family: var(--XS-body-BM-bold-font-family);
  font-size: var(--XS-body-BM-bold-font-size);
  font-style: var(--XS-body-BM-bold-font-style);
  font-weight: var(--XS-body-BM-bold-font-weight);
  letter-spacing: var(--XS-body-BM-bold-letter-spacing);
  line-height: var(--XS-body-BM-bold-line-height);
  margin-top: -1px;
  position: relative;
  width: 198px;
}

.landing-page-mobile .qu-aporta-rent-2 {
  color: var(--white);
  font-family: var(--XS-body-BM-bold-font-family);
  font-size: var(--XS-body-BM-bold-font-size);
  font-style: var(--XS-body-BM-bold-font-style);
  font-weight: var(--XS-body-BM-bold-font-weight);
  letter-spacing: var(--XS-body-BM-bold-letter-spacing);
  line-height: var(--XS-body-BM-bold-line-height);
  margin-top: -1px;
  position: relative;
  width: 182px;
}

.landing-page-mobile .frame-112 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: var(--spacings-spacing-lg);
  padding: 0px var(--spacings-spacing-md) 0px var(--spacings-spacing-md);
  position: relative;
}

.landing-page-mobile .frame-113 {
  align-items: flex-start;
  background-color: var(--white);
  border-radius: 8px;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: var(--spacings-spacing-lg);
  padding: var(--spacings-spacing-md);
  position: relative;
  width: 328px;
}

.landing-page-mobile .text-wrapper-61 {
  align-self: stretch;
  color: var(--black);
  font-family: var(--XS-body-BL-bold-font-family);
  font-size: var(--XS-body-BL-bold-font-size);
  font-style: var(--XS-body-BL-bold-font-style);
  font-weight: var(--XS-body-BL-bold-font-weight);
  letter-spacing: var(--XS-body-BL-bold-letter-spacing);
  line-height: var(--XS-body-BL-bold-line-height);
  margin-top: -1px;
  position: relative;
}

.landing-page-mobile .timer-5 {
  align-self: stretch !important;
  background-color: var(--violet-dark) !important;
  flex: 0 0 auto !important;
  overflow: hidden !important;
  padding: var(--spacings-spacing-sm) var(--spacings-spacing-md)
    var(--spacings-spacing-sm) var(--spacings-spacing-md) !important;
  width: 100% !important;
}

.landing-page-mobile .timer-6 {
  color: #ffffff !important;
}

.landing-page-mobile .timer-7 {
  color: #cac2fa !important;
}

.landing-page-mobile .timer-8 {
  background-color: var(--lavender) !important;
}

.landing-page-mobile .text-wrapper-62 {
  align-self: stretch;
  color: var(--black);
  font-family: var(--XS-body-BM-bold-font-family);
  font-size: var(--XS-body-BM-bold-font-size);
  font-style: var(--XS-body-BM-bold-font-style);
  font-weight: var(--XS-body-BM-bold-font-weight);
  letter-spacing: var(--XS-body-BM-bold-letter-spacing);
  line-height: var(--XS-body-BM-bold-line-height);
  margin-top: -1px;
  position: relative;
}

.landing-page-mobile .frame-114 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: var(--spacings-spacing-md);
  position: relative;
  width: 100%;
}

.landing-page-mobile .text-wrapper-63 {
  align-self: stretch;
  color: var(--violet-dark);
  font-family: var(--XS-body-BM-semibold-font-family);
  font-size: var(--XS-body-BM-semibold-font-size);
  font-style: var(--XS-body-BM-semibold-font-style);
  font-weight: var(--XS-body-BM-semibold-font-weight);
  letter-spacing: var(--XS-body-BM-semibold-letter-spacing);
  line-height: var(--XS-body-BM-semibold-line-height);
  position: relative;
}

.landing-page-mobile .footer-landing {
  align-items: flex-start;
  background-color: var(--white);
  border-radius: 8px 8px 0px 0px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  overflow: hidden;
  padding: 24px 16px;
  position: relative;
}

.landing-page-mobile .frame-115 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 24px;
  position: relative;
  width: 328px;
}

.landing-page-mobile .text-wrapper-64 {
  color: var(--black);
  font-family: var(--XS-title-TL-font-family);
  font-size: var(--XS-title-TL-font-size);
  font-style: var(--XS-title-TL-font-style);
  font-weight: var(--XS-title-TL-font-weight);
  letter-spacing: var(--XS-title-TL-letter-spacing);
  line-height: var(--XS-title-TL-line-height);
  margin-top: -1px;
  position: relative;
  width: 328px;
}

.landing-page-mobile .frame-116 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 20px;
  position: relative;
  width: 328px;
}

.landing-page-mobile .frame-117 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 20px;
  position: relative;
  width: 100%;
}

.landing-page-mobile .frame-118 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 6px;
  position: relative;
  width: 100%;
}

.landing-page-mobile .tel-fono-2 {
  align-self: stretch;
  color: var(--gray);
  font-family: var(--XS-caps-CXS-semibold-font-family);
  font-size: var(--XS-caps-CXS-semibold-font-size);
  font-style: var(--XS-caps-CXS-semibold-font-style);
  font-weight: var(--XS-caps-CXS-semibold-font-weight);
  letter-spacing: var(--XS-caps-CXS-semibold-letter-spacing);
  line-height: var(--XS-caps-CXS-semibold-line-height);
  margin-top: -1px;
  position: relative;
}

.landing-page-mobile .line-10 {
  align-self: stretch;
  height: 1px;
  object-fit: cover;
  position: relative;
  width: 100%;
}

.landing-page-mobile .frame-119 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  position: relative;
  width: 328px;
}

.landing-page-mobile .frame-120 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
  width: 164px;
}

.landing-page-mobile .text-wrapper-65 {
  color: var(--black);
  font-family: var(--XS-title-TL-font-family);
  font-size: var(--XS-title-TL-font-size);
  font-style: var(--XS-title-TL-font-style);
  font-weight: var(--XS-title-TL-font-weight);
  letter-spacing: var(--XS-title-TL-letter-spacing);
  line-height: var(--XS-title-TL-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.landing-page-mobile .text-wrapper-66 {
  align-self: stretch;
  color: var(--black);
  font-family: var(--XS-body-BM-semibold-font-family);
  font-size: var(--XS-body-BM-semibold-font-size);
  font-style: var(--XS-body-BM-semibold-font-style);
  font-weight: var(--XS-body-BM-semibold-font-weight);
  letter-spacing: var(--XS-body-BM-semibold-letter-spacing);
  line-height: var(--XS-body-BM-semibold-line-height);
  margin-top: -1px;
  position: relative;
}

.landing-page-mobile .frame-121 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
  width: 138px;
}

.landing-page-mobile .frame-122 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 4px;
  position: relative;
  width: 100%;
}

.landing-page-mobile .text-wrapper-67 {
  align-self: stretch;
  color: var(--black);
  font-family: var(--XS-caps-CM-bold-font-family);
  font-size: var(--XS-caps-CM-bold-font-size);
  font-style: var(--XS-caps-CM-bold-font-style);
  font-weight: var(--XS-caps-CM-bold-font-weight);
  letter-spacing: var(--XS-caps-CM-bold-letter-spacing);
  line-height: var(--XS-caps-CM-bold-line-height);
  margin-top: -1px;
  position: relative;
}

.landing-page-mobile .text-wrapper-68 {
  align-self: stretch;
  color: var(--black);
  font-family: var(--XS-body-BM-reg-font-family);
  font-size: var(--XS-body-BM-reg-font-size);
  font-style: var(--XS-body-BM-reg-font-style);
  font-weight: var(--XS-body-BM-reg-font-weight);
  letter-spacing: var(--XS-body-BM-reg-letter-spacing);
  line-height: var(--XS-body-BM-reg-line-height);
  position: relative;
}

.landing-page-mobile .frame-123 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 66px;
  position: relative;
}

.landing-page-mobile .frame-124 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 8px;
  position: relative;
}

.landing-page-mobile .links-3 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 1px;
  position: relative;
}

.landing-page-mobile .text-wrapper-69 {
  color: var(--black);
  font-family: var(--XS-small-text-ST-l-reg-font-family);
  font-size: var(--XS-small-text-ST-l-reg-font-size);
  font-style: var(--XS-small-text-ST-l-reg-font-style);
  font-weight: var(--XS-small-text-ST-l-reg-font-weight);
  letter-spacing: var(--XS-small-text-ST-l-reg-letter-spacing);
  line-height: var(--XS-small-text-ST-l-reg-line-height);
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.text-wrapper-20 {
  color: var(--white);
  font-family: var(--XL-headline-HS-font-family);
  font-size: var(--XS-headline-HM-font-size);
  font-style: var(--XL-headline-HS-font-style);
  font-weight: var(--XL-headline-HS-font-weight);
  letter-spacing: var(--XL-headline-HS-letter-spacing);
  line-height: var(--XL-headline-HS-line-height);
  margin-top: -1px;
  /* position: relative; */
}

.frame-wrapper-2 {
  width: 328px;
}

.frame-69 {
  display: flex;
  justify-content: space-between;
}
