.timer {
  align-items: center;
  display: flex;
  justify-content: space-between;
  position: relative;
}

.timer .element-d {
  color: transparent;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.timer .span {
  color: #e73a3a;
}

.timer .text-wrapper-10 {
  color: #f29595;
}

.timer .rectangle {
  background-color: var(--rose-dark);
  position: relative;
}

.timer .element-h {
  color: transparent;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.timer .text-wrapper-11 {
  color: #e73a3a;
}

.timer .text-wrapper-12 {
  color: #f29595;
}

.timer .rectangle-2 {
  background-color: var(--rose-dark);
  position: relative;
}

.timer .element-m {
  color: transparent;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.timer .text-wrapper-13 {
  color: #e73a3a;
}

.timer .text-wrapper-14 {
  color: #f29595;
}

.timer .rectangle-3 {
  background-color: var(--rose-dark);
  position: relative;
}

.timer .element-s {
  color: transparent;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.timer .text-wrapper-15 {
  color: #e73a3a;
}

.timer .text-wrapper-16 {
  color: #f29595;
}

.timer.size-15-desktop {
  border-radius: 12px;
  padding: var(--spacings-spacing-md) var(--spacings-spacing-lg) var(--spacings-spacing-md) var(--spacings-spacing-lg);
  width: 693px;
}

.timer.theme-13-light {
  background-color: var(--black);
}

.timer.theme-13-dark {
  background-color: var(--white);
}

.timer.size-15-mobile {
  border-radius: 8px;
  padding: var(--spacings-spacing-md);
  width: 328px;
}

.timer.size-15-desktop .element-d {
  font-family: var(--XL-headline-HM-font-family);
  font-size: var(--XL-headline-HM-font-size);
  font-style: var(--XL-headline-HM-font-style);
  font-weight: var(--XL-headline-HM-font-weight);
  letter-spacing: var(--XL-headline-HM-letter-spacing);
  line-height: var(--XL-headline-HM-line-height);
  margin-top: -1px;
}

.timer.size-15-mobile .element-d {
  font-family: var(--XS-headline-HS-font-family);
  font-size: var(--XS-headline-HS-font-size);
  font-style: var(--XS-headline-HS-font-style);
  font-weight: var(--XS-headline-HS-font-weight);
  letter-spacing: var(--XS-headline-HS-letter-spacing);
  line-height: var(--XS-headline-HS-line-height);
}

.timer.size-15-desktop .span {
  font-family: var(--XL-headline-HM-font-family);
  font-size: var(--XL-headline-HM-font-size);
  font-style: var(--XL-headline-HM-font-style);
  font-weight: var(--XL-headline-HM-font-weight);
  letter-spacing: var(--XL-headline-HM-letter-spacing);
  line-height: var(--XL-headline-HM-line-height);
}

.timer.size-15-mobile .span {
  font-family: var(--XS-headline-HS-font-family);
  font-size: var(--XS-headline-HS-font-size);
  font-style: var(--XS-headline-HS-font-style);
  font-weight: var(--XS-headline-HS-font-weight);
  letter-spacing: var(--XS-headline-HS-letter-spacing);
  line-height: var(--XS-headline-HS-line-height);
}

.timer.size-15-desktop .text-wrapper-10 {
  font-family: var(--XL-headline-HM-font-family);
  font-size: var(--XL-headline-HM-font-size);
  font-style: var(--XL-headline-HM-font-style);
  font-weight: var(--XL-headline-HM-font-weight);
  letter-spacing: var(--XL-headline-HM-letter-spacing);
  line-height: var(--XL-headline-HM-line-height);
}

.timer.size-15-mobile .text-wrapper-10 {
  font-family: var(--XS-headline-HS-font-family);
  font-size: var(--XS-headline-HS-font-size);
  font-style: var(--XS-headline-HS-font-style);
  font-weight: var(--XS-headline-HS-font-weight);
  letter-spacing: var(--XS-headline-HS-letter-spacing);
  line-height: var(--XS-headline-HS-line-height);
}

.timer.size-15-desktop .rectangle {
  height: 55px;
  width: 5px;
}

.timer.size-15-mobile .rectangle {
  height: 37px;
  width: 3px;
}

.timer.size-15-desktop .element-h {
  font-family: var(--XL-headline-HM-font-family);
  font-size: var(--XL-headline-HM-font-size);
  font-style: var(--XL-headline-HM-font-style);
  font-weight: var(--XL-headline-HM-font-weight);
  letter-spacing: var(--XL-headline-HM-letter-spacing);
  line-height: var(--XL-headline-HM-line-height);
  margin-top: -1px;
}

.timer.size-15-mobile .element-h {
  font-family: var(--XS-headline-HS-font-family);
  font-size: var(--XS-headline-HS-font-size);
  font-style: var(--XS-headline-HS-font-style);
  font-weight: var(--XS-headline-HS-font-weight);
  letter-spacing: var(--XS-headline-HS-letter-spacing);
  line-height: var(--XS-headline-HS-line-height);
}

.timer.size-15-desktop .text-wrapper-11 {
  font-family: var(--XL-headline-HM-font-family);
  font-size: var(--XL-headline-HM-font-size);
  font-style: var(--XL-headline-HM-font-style);
  font-weight: var(--XL-headline-HM-font-weight);
  letter-spacing: var(--XL-headline-HM-letter-spacing);
  line-height: var(--XL-headline-HM-line-height);
}

.timer.size-15-mobile .text-wrapper-11 {
  font-family: var(--XS-headline-HS-font-family);
  font-size: var(--XS-headline-HS-font-size);
  font-style: var(--XS-headline-HS-font-style);
  font-weight: var(--XS-headline-HS-font-weight);
  letter-spacing: var(--XS-headline-HS-letter-spacing);
  line-height: var(--XS-headline-HS-line-height);
}

.timer.size-15-desktop .text-wrapper-12 {
  font-family: var(--XL-headline-HM-font-family);
  font-size: var(--XL-headline-HM-font-size);
  font-style: var(--XL-headline-HM-font-style);
  font-weight: var(--XL-headline-HM-font-weight);
  letter-spacing: var(--XL-headline-HM-letter-spacing);
  line-height: var(--XL-headline-HM-line-height);
}

.timer.size-15-mobile .text-wrapper-12 {
  font-family: var(--XS-headline-HS-font-family);
  font-size: var(--XS-headline-HS-font-size);
  font-style: var(--XS-headline-HS-font-style);
  font-weight: var(--XS-headline-HS-font-weight);
  letter-spacing: var(--XS-headline-HS-letter-spacing);
  line-height: var(--XS-headline-HS-line-height);
}

.timer.size-15-desktop .rectangle-2 {
  height: 55px;
  width: 5px;
}

.timer.size-15-mobile .rectangle-2 {
  height: 37px;
  width: 3px;
}

.timer.size-15-desktop .element-m {
  font-family: var(--XL-headline-HM-font-family);
  font-size: var(--XL-headline-HM-font-size);
  font-style: var(--XL-headline-HM-font-style);
  font-weight: var(--XL-headline-HM-font-weight);
  letter-spacing: var(--XL-headline-HM-letter-spacing);
  line-height: var(--XL-headline-HM-line-height);
  margin-top: -1px;
}

.timer.size-15-mobile .element-m {
  font-family: var(--XS-headline-HS-font-family);
  font-size: var(--XS-headline-HS-font-size);
  font-style: var(--XS-headline-HS-font-style);
  font-weight: var(--XS-headline-HS-font-weight);
  letter-spacing: var(--XS-headline-HS-letter-spacing);
  line-height: var(--XS-headline-HS-line-height);
}

.timer.size-15-desktop .text-wrapper-13 {
  font-family: var(--XL-headline-HM-font-family);
  font-size: var(--XL-headline-HM-font-size);
  font-style: var(--XL-headline-HM-font-style);
  font-weight: var(--XL-headline-HM-font-weight);
  letter-spacing: var(--XL-headline-HM-letter-spacing);
  line-height: var(--XL-headline-HM-line-height);
}

.timer.size-15-mobile .text-wrapper-13 {
  font-family: var(--XS-headline-HS-font-family);
  font-size: var(--XS-headline-HS-font-size);
  font-style: var(--XS-headline-HS-font-style);
  font-weight: var(--XS-headline-HS-font-weight);
  letter-spacing: var(--XS-headline-HS-letter-spacing);
  line-height: var(--XS-headline-HS-line-height);
}

.timer.size-15-desktop .text-wrapper-14 {
  font-family: var(--XL-headline-HM-font-family);
  font-size: var(--XL-headline-HM-font-size);
  font-style: var(--XL-headline-HM-font-style);
  font-weight: var(--XL-headline-HM-font-weight);
  letter-spacing: var(--XL-headline-HM-letter-spacing);
  line-height: var(--XL-headline-HM-line-height);
}

.timer.size-15-mobile .text-wrapper-14 {
  font-family: var(--XS-headline-HS-font-family);
  font-size: var(--XS-headline-HS-font-size);
  font-style: var(--XS-headline-HS-font-style);
  font-weight: var(--XS-headline-HS-font-weight);
  letter-spacing: var(--XS-headline-HS-letter-spacing);
  line-height: var(--XS-headline-HS-line-height);
}

.timer.size-15-desktop .rectangle-3 {
  height: 55px;
  width: 5px;
}

.timer.size-15-mobile .rectangle-3 {
  height: 37px;
  width: 3px;
}

.timer.size-15-desktop .element-s {
  font-family: var(--XL-headline-HM-font-family);
  font-size: var(--XL-headline-HM-font-size);
  font-style: var(--XL-headline-HM-font-style);
  font-weight: var(--XL-headline-HM-font-weight);
  letter-spacing: var(--XL-headline-HM-letter-spacing);
  line-height: var(--XL-headline-HM-line-height);
  margin-top: -1px;
}

.timer.size-15-mobile .element-s {
  font-family: var(--XS-headline-HS-font-family);
  font-size: var(--XS-headline-HS-font-size);
  font-style: var(--XS-headline-HS-font-style);
  font-weight: var(--XS-headline-HS-font-weight);
  letter-spacing: var(--XS-headline-HS-letter-spacing);
  line-height: var(--XS-headline-HS-line-height);
}

.timer.size-15-desktop .text-wrapper-15 {
  font-family: var(--XL-headline-HM-font-family);
  font-size: var(--XL-headline-HM-font-size);
  font-style: var(--XL-headline-HM-font-style);
  font-weight: var(--XL-headline-HM-font-weight);
  letter-spacing: var(--XL-headline-HM-letter-spacing);
  line-height: var(--XL-headline-HM-line-height);
}

.timer.size-15-mobile .text-wrapper-15 {
  font-family: var(--XS-headline-HS-font-family);
  font-size: var(--XS-headline-HS-font-size);
  font-style: var(--XS-headline-HS-font-style);
  font-weight: var(--XS-headline-HS-font-weight);
  letter-spacing: var(--XS-headline-HS-letter-spacing);
  line-height: var(--XS-headline-HS-line-height);
}

.timer.size-15-desktop .text-wrapper-16 {
  font-family: var(--XL-headline-HM-font-family);
  font-size: var(--XL-headline-HM-font-size);
  font-style: var(--XL-headline-HM-font-style);
  font-weight: var(--XL-headline-HM-font-weight);
  letter-spacing: var(--XL-headline-HM-letter-spacing);
  line-height: var(--XL-headline-HM-line-height);
}

.timer.size-15-mobile .text-wrapper-16 {
  font-family: var(--XS-headline-HS-font-family);
  font-size: var(--XS-headline-HS-font-size);
  font-style: var(--XS-headline-HS-font-style);
  font-weight: var(--XS-headline-HS-font-weight);
  letter-spacing: var(--XS-headline-HS-letter-spacing);
  line-height: var(--XS-headline-HS-line-height);
}
