.qu-aporta-rent {
  color: var(--white);
  font-family: var(--XS-body-BM-bold-font-family);
  font-size: var(--XS-body-BM-bold-font-size);
  font-style: var(--XS-body-BM-bold-font-style);
  font-weight: var(--XS-body-BM-bold-font-weight);
  letter-spacing: var(--XS-body-BM-bold-letter-spacing);
  line-height: var(--XS-body-BM-bold-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
  text-wrap: wrap;
}

.frame-108 {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  position: relative;
  margin: 12px 0;
  cursor: pointer;
}

.rent-onchain-2 {
  color: var(--white);
  font-family: var(--XS-body-BM-reg-font-family);
  font-size: var(--XS-body-BM-reg-font-size);
  font-style: var(--XS-body-BM-reg-font-style);
  font-weight: var(--XS-body-BM-reg-font-weight);
  letter-spacing: var(--XS-body-BM-reg-letter-spacing);
  line-height: var(--XS-body-BM-reg-line-height);
  position: relative;
  width: 328px;
  margin-bottom: 12px;
}

.addplus_icon {
  width: 30px;
  height: 30px;
}
.div-16 {
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
  border-top: 1px solid var(--dark-gray);
}

@media (min-width: 1024px) {
  .faq-list {
    margin-top: 32px;
  }

  .frame-108 {
    margin: 32px 0;
  }
  .qu-aporta-rent {
    font-family: var(--XL-body-BL-bold-font-family);
    font-size: var(--XL-body-BL-bold-font-size);
    font-style: var(--XL-body-BL-bold-font-style);
    font-weight: var(--XL-body-BL-bold-font-weight);
    letter-spacing: var(--XL-body-BL-bold-letter-spacing);
    line-height: var(--XL-body-BL-bold-line-height);
  }

  .rent-onchain-2 {
    width: auto;
    font-family: var(--XL-body-BM-reg-font-family);
    font-size: var(--XL-body-BM-reg-font-size);
    font-style: var(--XL-body-BM-reg-font-style);
    font-weight: var(--XL-body-BM-reg-font-weight);
    letter-spacing: var(--XL-body-BM-reg-letter-spacing);
    line-height: var(--XL-body-BM-reg-line-height);
    margin-bottom: 24px;
  }

  .addplus_icon {
    width: 52px;
    height: 52px;
  }
}
