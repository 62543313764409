.header-desktop-top {
  align-items: center;
  background-color: var(--black);
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #5f5f604c;
  display: flex;
  flex-direction: column;
  gap: 36px;
  justify-content: center;
  /* padding: var(--spacings-spacing-lg) 240px var(--spacings-spacing-lg) 240px; */
  padding: var(--spacings-spacing-lg) 0 var(--spacings-spacing-lg) 0;
  position: relative;
  max-width: 1920px;
}

.invisible {
  display: none !important;
}

.header-desktop-top .frame {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  position: relative;
  /* width: 100%; */
  width: 1440px;
  margin: 0 auto;
}

.header-desktop-top .frame-wrapper {
  align-items: center;
  display: flex;
  gap: var(--spacings-spacing-xxxl);
  position: relative;
  width: 941.2px;
}

.header-desktop-top .div {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 36px;
  justify-content: center;
  position: relative;
}

.header-desktop-top .new-logo-instance {
  flex: 0 0 auto !important;
}

.header-desktop-top .frame-2 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: var(--spacings-spacing-none);
  padding: 0px 0px var(--spacings-spacing-xs) 0px;
  position: relative;
}

.header-desktop-top .more-wrapper {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  padding: 0px 0px var(--spacings-spacing-xxs) 0px;
  position: relative;
}

.header-desktop-top .more {
  color: var(--gray);
  font-family: var(--XL-small-text-ST-l-med-font-family);
  font-size: var(--XL-small-text-ST-l-med-font-size);
  font-style: var(--XL-small-text-ST-l-med-font-style);
  font-weight: var(--XL-small-text-ST-l-med-font-weight);
  letter-spacing: var(--XL-small-text-ST-l-med-letter-spacing);
  line-height: var(--XL-small-text-ST-l-med-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.header-desktop-top .instance-node {
  height: 32px !important;
  position: relative !important;
  width: 32px !important;
}

.header-desktop-top .frame-3 {
  align-items: center;
  align-self: stretch;
  display: inline-flex;
  flex: 0 0 auto;
  justify-content: flex-end;
  position: relative;
}

.header-desktop-top .frame-4 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: var(--spacings-spacing-none);
  justify-content: flex-end;
  position: relative;
}

.header-desktop-top .eng-2 {
  color: var(--white);
  font-family: var(--XL-caps-CS-bold-font-family);
  font-size: var(--XL-caps-CS-bold-font-size);
  font-style: var(--XL-caps-CS-bold-font-style);
  font-weight: var(--XL-caps-CS-bold-font-weight);
  letter-spacing: var(--XL-caps-CS-bold-letter-spacing);
  line-height: var(--XL-caps-CS-bold-line-height);
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.header-desktop-top .frame-5 {
  align-items: center;
  align-self: stretch;
  display: flex;
  gap: 16px;
  position: relative;
  width: 88px;
}

.header-desktop-top .component {
  align-items: center;
  background-color: var(--white);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 32px;
  justify-content: center;
  padding: 4px 8px;
  position: relative;
  width: 32px;
}

.header-desktop-top .text-wrapper {
  color: var(--black);
  font-family: var(--button-m-font-family);
  font-size: var(--button-m-font-size);
  font-style: var(--button-m-font-style);
  font-weight: var(--button-m-font-weight);
  letter-spacing: var(--button-m-letter-spacing);
  line-height: var(--button-m-line-height);
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.header-desktop-top .div-wrapper {
  align-items: center;
  background-color: var(--gray);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 32px;
  justify-content: center;
  padding: 4px 8px;
  position: relative;
  width: 32px;
}

.header-desktop-top .text-wrapper-2 {
  color: var(--dark-gray);
  font-family: var(--button-m-font-family);
  font-size: var(--button-m-font-size);
  font-style: var(--button-m-font-style);
  font-weight: var(--button-m-font-weight);
  letter-spacing: var(--button-m-letter-spacing);
  line-height: var(--button-m-line-height);
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.header-desktop-top .caribs {
  gap: var(--spacings-spacing-lg);
}

.header-desktop-top .lottery {
  gap: var(--spacings-spacing-xxxl);
}

.header-desktop-top .academy {
  gap: var(--spacings-spacing-xxxl);
}

.header-desktop-top .marketplace {
  gap: var(--spacings-spacing-xxxl);
}

.header-desktop-top .tokenization {
  gap: var(--spacings-spacing-xxxl);
}

.header-desktop-top .timesharing-rent {
  gap: var(--spacings-spacing-xxxl);
}

.header-desktop-top .defi {
  gap: var(--spacings-spacing-xxxl);
}
