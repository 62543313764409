.default {
  padding: 22px;
  border-radius: 12px;
  max-width: 822px;
  background: #fff;
  display: flex;
  flex-direction: column;
  gap: 20px;

  .inputsWrapper {
    display: flex;
    gap: 16px;
    flex-direction: row; /* Alinea los elementos en fila */
    width: 100%; /* Asegura que el contenedor ocupe todo el ancho */
    .inputStyle {
      flex-grow: 1;
      box-sizing: border-box;
      border-radius: 12px;
      border: 2px solid #5f5f60;
      padding: 24px;
      max-width: 250px;
      font-size: 26px;
    }
    .inputStyleBig {
      flex-grow: 2;
      box-sizing: border-box;
      border-radius: 12px;
      border: 2px solid #5f5f60;
      padding: 24px;
      font-size: 26px;
    }
    input::placeholder {
      color: var(--Dark-gray, #5f5f60);
      font-feature-settings: "clig" off, "liga" off;
      font-family: Red Hat Display;
      font-size: 26px;
    }
  }

  /* Último inputStyle sin margen derecho */
  .inputsWrapper .inputStyle:last-child {
    margin-right: 0;
  }

  .checkboxWrapperInvisible {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .checkboxWrapper {
    display: flex;
    align-items: center;
    gap: 8px;

    label {
      display: flex;
      align-items: center;
      gap: 5px;
      font-family: Red Hat Display;
      font-size: 18px;
      font-weight: 700;
    }
    .textCheckbox {
      font-family: Red Hat Display;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
    }
  }

  .invisible {
    display: none !important;
  }

  button {
    max-width: 100%;
    cursor: pointer;
    padding: 20px 0;
    border: none;
    justify-content: center;
    align-items: center;
    font-family: Syncopate;
    font-size: 18px;
    text-transform: uppercase;
    background: #7a67f2;
    color: white;
    border-radius: 8px;
  }
}

.second {
  flex-direction: row;
  max-width: inherit;
  gap: 24px;
  button {
    width: 260px;
  }
}

.default.second {
  width: 100%;
}

.third {
  width: 100%;
  padding: 0;
  .inputsWrapper {
    display: flex;
    flex-direction: column;
    .inputStyle {
      max-width: 100%;
    }
  }
}

.fourth {
  width: 100%;
  padding: 0;
  .inputsWrapper {
    display: flex;
    flex-direction: column;
    .inputStyle {
      padding: 6px;
      max-width: 100%;
      border: none;
      border-radius: 0;
      border-bottom: 1px solid;
    }
    input:focus {
      outline: none;
    }
  }
  button {
    padding: 32px 0;
  }
}

.mobileForm {
  max-width: 100%;
  padding: 0;
  .inputsWrapper {
    flex-direction: column;
    gap: 10;
    .inputStyle {
      padding: 13px 16px;
      max-width: 100%;
    }
    input::placeholder {
      color: var(--Dark-gray, #5f5f60);
      font-feature-settings: "clig" off, "liga" off;
      font-family: Red Hat Display;
      font-size: 16px;
    }
  }
  .checkboxWrapper {
    label {
      font-size: 12px;
    }
    .textCheckbox {
      font-size: 12px;
    }
  }

  .btnForm {
    font-size: 16px;
  }
}

.mobileSecond {
  .inputsWrapper {
    .inputStyle {
      border: none;
      border-radius: 0;
      border-bottom: 1px solid;
    }
    input:focus {
      outline: none;
    }
  }
}
