@media (max-width: 1023px) {
  .carousel-wrapper {
    position: relative;
    width: 100%;
    height: 310px;
    overflow: hidden;
  }

  .alice-carousel__dots {
    position: absolute;
    bottom: -32px;
    left: 45%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
  }

  .alice-carousel__dots-item:not(:last-child) {
    margin-right: 10px;
  }

  .alice-carousel__stage img {
    width: 328px !important;
  }
}

@media (min-width: 1024px) {
  .alice-carousel {
    display: flex;
    justify-content: space-between;
  }

  .alice-carousel__prev-btn {
    cursor: pointer;
    position: absolute;
    padding: 8px;
    left: -6.5%;
    top: 50%;
    width: 0;
    transform: translateY(-50%);
  }

  .arrow-chevron-left-1 {
    cursor: pointer;
  }

  .arrow-chevron-right-1 {
    cursor: pointer;
  }

  .alice-carousel__next-btn {
    position: absolute;
    padding: 8px;
    right: -2%;
    top: 50%;
    width: 0;
    transform: translateY(-50%);
  }

  .alice-carousel__stage img,
  .alice-carousel__stage-item {
    width: 1272px;
    height: 750px;
  }
  .carousel-wrapper {
    display: flex;
    width: 1272px;
    align-items: center;
  }
  .alice-carousel__wrapper {
    width: 1440px;
    height: 750px;
  }

  .icon-instance-node {
    height: 60px !important;
    position: relative !important;
    width: 60px !important;
  }
}
