.header-timesharing {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.header-timesharing .instance-node-2 {
  width: 100%;
  max-width: 1920px;
}
