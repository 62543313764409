/* screen - success-message */

@import url("https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css");
@import url("https://fonts.googleapis.com/css?family=Syncopate:700");

.button.success:hover {
  cursor: pointer;
}

.menu-close_lg {
  cursor: pointer;
}

.success-message-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  z-index: 1000; /* Asegúrate de que está sobre otros elementos */
}

.success-message-container {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  max-width: 90%;
  /* Otros estilos para tu popup */
}

.success-message {
  background-color: transparent;
}

.success-message .success-message-1 {
  align-items: flex-start;
  background-color: var(--white);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: 320px;
  margin-left: 1px;
  overflow: hidden;
  padding: 32px;
  position: relative;
  width: 100%;
  max-width: 605px;
}

.success-message .frame-386 {
  align-items: flex-end;
  gap: 32px;
}

.success-message .menu-close_lg {
  height: 24px;
  position: relative;
  width: 24px;
}

.success-message .frame-385 {
  align-items: center;
  gap: 32px;
}

.success-message .frame-1458 {
  align-items: center;
  gap: 8px;
  justify-content: center;
}

.success-message .su-solicitud-se-ha-e {
  align-self: stretch;
  color: var(--black);
  font-weight: 700;
  line-height: 28.8px;
  margin-top: -1px;
  position: relative;
  text-align: center;
}

.success-message .warning-circle_-check {
  height: 32px;
  position: relative;
  width: 32px;
}

.success-message .frame-385-1 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 32px;
  position: relative;
  width: 100%;
}

.success-message .button {
  background-color: var(--violet);
  border-radius: 8px;
  flex: 1;
  flex-grow: 1;
  height: 70px;
  overflow: hidden;
  padding: 20px 0px;
  position: relative;
}

.success-message .button-1 {
  color: var(--white);
  font-weight: 700;
  line-height: 21.6px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.success {
  align-items: center;
  display: flex;
  justify-content: center;
}

.success-message .frame {
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
  width: 100%;
}

:root {
  --black: #1a1a1c;
  --black-2: #000000;
  --blue: #37a4e2;
  --dark-gray: #5f5f60;
  --gray: #a8a8a8;
  --green: #c5ef4b;
  --lavender: #cac2fa;
  --orange: #f5893b;
  --violet: #7a67f2;
  --violet-dark: #6956da;
  --white: #ffffff;

  --font-size-18px: 18px;
  --font-size-l: 16px;
  --font-size-m: 14px;
  --font-size-s: 12px;
  --font-size-xl: 20px;
  --font-size-xs: 10px;
  --font-size-xxl: 21px;
  --font-size-xxxl: 24px;

  --font-family-red_hat_display-bold: "Red Hat Display-Bold", Helvetica;
  --font-family-red_hat_display-medium: "Red Hat Display-Medium", Helvetica;
  --font-family-red_hat_display-regular: "Red Hat Display-Regular", Helvetica;
  --font-family-red_hat_display-semibold: "Red Hat Display-SemiBold", Helvetica;
  --font-family-syncopate: "Syncopate", Helvetica;
}
.xltitlets {
  font-family: var(--font-family-syncopate);
  font-size: var(--font-size-xxxl);
  font-style: normal;
  font-weight: 700;
  letter-spacing: -2.4px;
}

.buttonl {
  font-family: var(--font-family-syncopate);
  font-size: var(--font-size-18px);
  font-style: normal;
  font-weight: 700;
  letter-spacing: -1.8px;
}

.xstitletl {
  font-family: var(--font-family-syncopate);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 700;
  letter-spacing: -1.4px;
}

.buttonm {
  font-family: var(--font-family-syncopate);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 700;
  letter-spacing: -1.6px;
}

.logol {
  font-family: var(--font-family-syncopate);
  font-size: var(--font-size-xs);
  font-style: normal;
  font-weight: 700;
  letter-spacing: -1px;
}

.xsheadlinehl {
  font-family: var(--font-family-syncopate);
  font-size: var(--font-size-xxl);
  font-style: normal;
  font-weight: 700;
  letter-spacing: -2.1px;
}

.xsheadlinehm {
  font-family: var(--font-family-syncopate);
  font-size: var(--font-size-xl);
  font-style: normal;
  font-weight: 700;
  letter-spacing: -2px;
}

.xstitletm {
  font-family: var(--font-family-syncopate);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 700;
  letter-spacing: -1.2px;
}

.redhatdisplay-bold-eerie-black-12px {
  color: var(--black);
  font-family: var(--font-family-red_hat_display-bold);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 700;
}

.redhatdisplay-semi-bold-eerie-black-14px {
  color: var(--black);
  font-family: var(--font-family-red_hat_display-semibold);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 600;
}

.redhatdisplay-regular-normal-eerie-black-12px {
  color: var(--black);
  font-family: var(--font-family-red_hat_display-regular);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 400;
}

.redhatdisplay-medium-white-12px {
  color: var(--white);
  font-family: var(--font-family-red_hat_display-medium);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 500;
}

.redhatdisplay-medium-quick-silver-12px {
  color: var(--gray);
  font-family: var(--font-family-red_hat_display-medium);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 500;
}

.redhatdisplay-regular-normal-scorpion-16px {
  color: var(--dark-gray);
  font-family: var(--font-family-red_hat_display-regular);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 400;
}

.redhatdisplay-bold-white-14px {
  color: var(--white);
  font-family: var(--font-family-red_hat_display-bold);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 700;
}

.redhatdisplay-semi-bold-white-14px {
  color: var(--white);
  font-family: var(--font-family-red_hat_display-semibold);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 600;
}

.redhatdisplay-bold-quick-silver-14px {
  color: var(--gray);
  font-family: var(--font-family-red_hat_display-bold);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 700;
}

.redhatdisplay-regular-normal-scorpion-12px {
  color: var(--dark-gray);
  font-family: var(--font-family-red_hat_display-regular);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 400;
}

.redhatdisplay-bold-white-16px {
  color: var(--white);
  font-family: var(--font-family-red_hat_display-bold);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 700;
}

.redhatdisplay-medium-eerie-black-12px {
  color: var(--black);
  font-family: var(--font-family-red_hat_display-medium);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 500;
}

.redhatdisplay-bold-eerie-black-14px {
  color: var(--black);
  font-family: var(--font-family-red_hat_display-bold);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 700;
}

.redhatdisplay-semi-bold-white-16px {
  color: var(--white);
  font-family: var(--font-family-red_hat_display-semibold);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 600;
}

.redhatdisplay-semi-bold-quick-silver-12px {
  color: var(--gray);
  font-family: var(--font-family-red_hat_display-semibold);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 600;
}

.screen a {
  display: contents;
  text-decoration: none;
}

.valign-text-middle {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

* {
  box-sizing: border-box;
}
