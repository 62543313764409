.card-mini-desktop {
  align-items: center;
  background-color: var(--green);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow: hidden;
  padding: var(--spacings-spacing-xl) var(--spacings-spacing-xl) var(--spacings-spacing-xxxl) var(--spacings-spacing-xl);
  position: relative;
  width: 452px;
}

.card-mini-desktop .frame-17 {
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 8px;
  position: relative;
  width: 100%;
}

.card-mini-desktop .b-squeda {
  align-self: stretch;
  position: relative;
}

.card-mini-desktop .b-squeda-2 {
  color: var(--black);
  font-family: var(--XL-headline-HS-font-family);
  font-size: var(--XL-headline-HS-font-size);
  font-style: var(--XL-headline-HS-font-style);
  font-weight: var(--XL-headline-HS-font-weight);
  letter-spacing: var(--XL-headline-HS-letter-spacing);
  line-height: var(--XL-headline-HS-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.card-mini-desktop .seleccionamos-las {
  align-self: stretch;
  color: var(--black);
  font-family: var(--XL-body-BS-semibold-font-family);
  font-size: var(--XL-body-BS-semibold-font-size);
  font-style: var(--XL-body-BS-semibold-font-style);
  font-weight: var(--XL-body-BS-semibold-font-weight);
  letter-spacing: var(--XL-body-BS-semibold-letter-spacing);
  line-height: var(--XL-body-BS-semibold-line-height);
  position: relative;
}

.card-mini-desktop .interface-chart-line-2 {
  height: 32px !important;
  position: relative !important;
  width: 32px !important;
}

.card-mini-desktop .icon-true {
  align-items: center;
}

.card-mini-desktop .icon-false {
  align-items: flex-start;
}

.card-mini-desktop .frame-17.icon-true .b-squeda {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  width: 100%;
}

.card-mini-desktop .frame-17.icon-false .b-squeda {
  color: var(--black);
  font-family: var(--XL-headline-HS-font-family);
  font-size: var(--XL-headline-HS-font-size);
  font-style: var(--XL-headline-HS-font-style);
  font-weight: var(--XL-headline-HS-font-weight);
  letter-spacing: var(--XL-headline-HS-letter-spacing);
  line-height: var(--XL-headline-HS-line-height);
  margin-top: -1px;
}
