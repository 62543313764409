.real-estate-card {
  align-items: flex-start;
  background-color: var(--white);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  overflow: hidden;
  padding: 24px;
  position: relative;
  width: 464px;
}

.real-estate-card .frame-11 {
  align-self: stretch;
  background-image: url(../../../static/img/rectangle-260-6.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 254px;
  position: relative;
  width: 100%;
}

.real-estate-card .arrow-caret-right-SM {
  height: 40px !important;
  left: 376px !important;
  position: absolute !important;
  top: 106px !important;
  width: 40px !important;
}

.real-estate-card .arrow-caret-left-SM {
  height: 40px !important;
  left: 0 !important;
  position: absolute !important;
  top: 106px !important;
  width: 40px !important;
}

.real-estate-card .slider {
  align-items: center;
  display: flex;
  gap: var(--spacings-spacing-sm);
  justify-content: center;
  left: 60px;
  position: absolute;
  top: 238px;
  width: 296px;
}

/* .real-estate-card .ellipse {
  background-color: var(--white);
  border-radius: 4px;
  height: 8px;
  position: relative;
  width: 8px;
} */

/* .real-estate-card .ellipse-2 {
  background-color: var(--white);
  border-radius: 4px;
  height: 8px;
  opacity: 0.5;
  position: relative;
  width: 8px;
} */

.real-estate-card .frame-12 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 16px;
  position: relative;
  width: 100%;
}

.real-estate-card .frame-13 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: var(--spacings-spacing-xs);
  position: relative;
  width: 100%;
}

.real-estate-card .frame-14 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.real-estate-card .TULUM {
  color: var(--black);
  font-family: var(--XL-title-TS-font-family);
  font-size: var(--XL-title-TS-font-size);
  font-style: var(--XL-title-TS-font-style);
  font-weight: var(--XL-title-TS-font-weight);
  letter-spacing: var(--XL-title-TS-letter-spacing);
  line-height: var(--XL-title-TS-line-height);
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.real-estate-card .frame-15 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: var(--spacings-spacing-sm);
  position: relative;
}

.real-estate-card .group {
  height: 31px;
  margin-right: -2px;
  position: relative;
  width: 106px;
}

.real-estate-card .overlap-group {
  height: 31px;
  position: relative;
  width: 104px;
}

.real-estate-card .element {
  color: var(--gray);
  font-family: var(--XL-body-BM-bold-font-family);
  font-size: var(--XL-body-BM-bold-font-size);
  font-style: var(--XL-body-BM-bold-font-style);
  font-weight: var(--XL-body-BM-bold-font-weight);
  left: 0;
  letter-spacing: var(--XL-body-BM-bold-letter-spacing);
  line-height: var(--XL-body-BM-bold-line-height);
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.real-estate-card .line {
  height: 2px;
  left: 0;
  position: absolute;
  top: 15px;
  width: 104px;
}

.real-estate-card .status-instance {
  flex: 0 0 auto !important;
}

.real-estate-card .element-2 {
  color: var(--violet-dark);
  font-family: var(--XL-title-TL-font-family);
  font-size: var(--XL-title-TL-font-size);
  font-style: var(--XL-title-TL-font-style);
  font-weight: var(--XL-title-TL-font-weight);
  letter-spacing: var(--XL-title-TL-letter-spacing);
  line-height: var(--XL-title-TL-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.real-estate-card .frame-16 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10px;
  position: relative;
  width: 100%;
}

.real-estate-card .div-6 {
  color: var(--black);
  flex: 1;
  font-family: var(--XL-small-text-ST-l-reg-font-family);
  font-size: var(--XL-small-text-ST-l-reg-font-size);
  font-style: var(--XL-small-text-ST-l-reg-font-style);
  font-weight: var(--XL-small-text-ST-l-reg-font-weight);
  letter-spacing: var(--XL-small-text-ST-l-reg-letter-spacing);
  line-height: var(--XL-small-text-ST-l-reg-line-height);
  margin-top: -0.65px;
  position: relative;
}

.real-estate-card .div-7 {
  color: var(--black);
  font-family: var(--XL-small-text-ST-l-bold-font-family);
  font-size: var(--XL-small-text-ST-l-bold-font-size);
  font-style: var(--XL-small-text-ST-l-bold-font-style);
  font-weight: var(--XL-small-text-ST-l-bold-font-weight);
  letter-spacing: var(--XL-small-text-ST-l-bold-letter-spacing);
  line-height: var(--XL-small-text-ST-l-bold-line-height);
  margin-top: -0.65px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.real-estate-card .hu-spedes {
  color: var(--black);
  font-family: var(--XL-small-text-ST-l-reg-font-family);
  font-size: var(--XL-small-text-ST-l-reg-font-size);
  font-style: var(--XL-small-text-ST-l-reg-font-style);
  font-weight: var(--XL-small-text-ST-l-reg-font-weight);
  letter-spacing: var(--XL-small-text-ST-l-reg-letter-spacing);
  line-height: var(--XL-small-text-ST-l-reg-line-height);
  margin-top: -0.65px;
  position: relative;
}

.real-estate-card .tipo-de-inmueble {
  margin-top: -0.65px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.real-estate-card .apartamento {
  margin-top: -0.65px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.real-estate-card .instance-node-4 {
  height: 24px !important;
  position: relative !important;
  width: 24px !important;
}

.real-estate-card .img-2 {
  height: 22px;
  position: relative;
  width: 22px;
}

.real-estate-card .la-fotograf-a-puede {
  align-self: stretch;
  color: var(--dark-gray);
  font-family: var(--XL-small-text-ST-s-semibold-font-family);
  font-size: var(--XL-small-text-ST-s-semibold-font-size);
  font-style: var(--XL-small-text-ST-s-semibold-font-style);
  font-weight: var(--XL-small-text-ST-s-semibold-font-weight);
  letter-spacing: var(--XL-small-text-ST-s-semibold-letter-spacing);
  line-height: var(--XL-small-text-ST-s-semibold-line-height);
  position: relative;
}

.real-estate-card .button-2 {
  align-self: stretch !important;
  width: 100% !important;
}

.real-estate-card .eng {
  flex: 1;
}

.real-estate-card .esp {
  white-space: nowrap;
  width: fit-content;
}

.real-estate-card .language-eng {
  color: #1a1a1c;
  font-family: "Red Hat Display", Helvetica;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 23.4px;
}

.real-estate-card .language-esp {
  color: var(--black);
  font-family: var(--XL-small-text-ST-l-reg-font-family);
  font-size: var(--XL-small-text-ST-l-reg-font-size);
  font-style: var(--XL-small-text-ST-l-reg-font-style);
  font-weight: var(--XL-small-text-ST-l-reg-font-weight);
  letter-spacing: var(--XL-small-text-ST-l-reg-letter-spacing);
  line-height: var(--XL-small-text-ST-l-reg-line-height);
}

.real-estate-card .language-0-eng {
  color: #1a1a1c;
  font-family: "Red Hat Display", Helvetica;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 23.4px;
}

.real-estate-card .language-0-esp {
  color: var(--black);
  font-family: var(--XL-small-text-ST-l-bold-font-family);
  font-size: var(--XL-small-text-ST-l-bold-font-size);
  font-style: var(--XL-small-text-ST-l-bold-font-style);
  font-weight: var(--XL-small-text-ST-l-bold-font-weight);
  letter-spacing: var(--XL-small-text-ST-l-bold-letter-spacing);
  line-height: var(--XL-small-text-ST-l-bold-line-height);
}
