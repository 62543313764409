.new-logo {
  align-items: center;
  display: inline-flex;
  gap: var(--spacings-spacing-sm);
  position: relative;
}

.new-logo .logo {
  position: relative;
}

.new-logo .rent-onchain {
  color: var(--white);
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.new-logo .desktop {
  height: 48px;
  width: 43.45px;
}

.new-logo .mobile {
  height: 37px;
  width: 33.49px;
}

.new-logo .size-desktop {
  font-family: var(--XS-title-TL-font-family);
  font-size: var(--XS-title-TL-font-size);
  font-style: var(--XS-title-TL-font-style);
  font-weight: var(--XS-title-TL-font-weight);
  letter-spacing: var(--XS-title-TL-letter-spacing);
  line-height: var(--XS-title-TL-line-height);
}

.new-logo .size-mobile {
  font-family: var(--logo-l-font-family);
  font-size: var(--logo-l-font-size);
  font-style: var(--logo-l-font-style);
  font-weight: var(--logo-l-font-weight);
  letter-spacing: var(--logo-l-letter-spacing);
  line-height: var(--logo-l-line-height);
}
