.team__name_content {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-top: 1px solid var(--dark-gray);
}

.team__name_wrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  min-height: 100px;
  cursor: pointer;
}

.team__name_title {
  color: var(--white);
  font-family: var(--XL-headline-HS-font-family);
  font-size: var(--XL-headline-HS-font-size);
  font-style: var(--XL-headline-HS-font-style);
  font-weight: var(--XL-headline-HS-font-weight);
  letter-spacing: var(--XL-headline-HS-letter-spacing);
  line-height: var(--XL-headline-HS-line-height);
}

.icon-rotate {
  transform: rotate(45deg);
}

.team__name_list {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 40px;
  color: var(--white);
  margin-top: 24px;
  margin-bottom: 24px;
}

.member__photo {
  width: 256px;
  height: 342px;
  object-fit: cover;

  filter: grayscale(100%);
  transition: filter 0.3s ease;
}

.member__photo:hover {
  filter: grayscale(0%);
}

.member__name-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.member__name {
  font-family: var(--XL-title-TXS-font-family);
  font-size: var(--XL-title-TXS-font-size);
  font-style: var(--XL-title-TXS-font-style);
  font-weight: var(--XL-title-TXS-font-weight);
  letter-spacing: var(--XL-title-TXS-letter-spacing);
  line-height: var(--XL-title-TXS-line-height);
  text-transform: uppercase;
  width: 200px;
}

.member__role {
  font-family: var(--XS-small-text-ST-l-medium-font-family);
  font-size: var(--XL-small-text-ST-l-bold-font-size);
  font-style: var(--XS-small-text-ST-l-medium-font-style);
  font-weight: var(--XS-small-text-ST-l-medium-font-weight);
  letter-spacing: var(--XS-small-text-ST-l-medium-letter-spacing);
  line-height: var(--XS-small-text-ST-l-medium-line-height);
}

.member__date {
  color: var(--gray);
  font-family: var(--XL-small-text-ST-l-med-font-family);
  font-size: var(--XL-small-text-ST-l-med-font-size);
  font-style: var(--XL-small-text-ST-l-med-font-style);
  font-weight: var(--XL-small-text-ST-l-med-font-weight);
  line-height: var(--XL-small-text-ST-l-med-line-height);
}

.linkedin_icon {
  margin-right: 3px;
}

@media (max-width: 1024px) {
  .team__name_title {
    font-size: var(--XS-title-TL-font-size);
  }

  .addplus_icon {
    width: 30px;
    height: 30px;
  }

  .team__name_wrapper {
    min-height: 42px;
  }

  .team__name_list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 16px;
    color: var(--white);
    margin-top: 24px;
    margin-bottom: 24px;
  }

  .member__photo {
    width: 156px;
    height: 208px;
  }

  .member__name {
    font-size: var(--XS-small-text-ST-l-bold-font-size);
    width: 100%;
  }

  .member__role,
  .member__date {
    font-size: var(--XS-small-text-ST-l-medium-font-size);
  }

  .linkedin_icon {
    width: 16px;
    height: 16px;
  }
}

.member__link_no-cursor {
  cursor: default;
}
